import React, { Component } from 'react';
import {Alert, Badge, Button, Col, Container, FormFeedback, Input, Label, NavLink, Row} from 'reactstrap';
import {withRouter, Link} from "react-router-dom";
import { Grid, _ } from 'gridjs-react';

import { DeleteCampaign, Loading } from "custom";
import { CampaignAPI } from "api";
const moment = require("moment");


class ListCampaign extends Component {

    constructor(props) {
        super(props);
        this.state = {
            campaigns: [],
            loading: true,
            error: false
        };
    }

    componentDidMount() {
        this.getCampaigns();
    }

    getCampaigns() {
        let filter = {
            contract: this.props.id_contract_campaign
        }
        CampaignAPI.list(filter).then((campaigns) => {
            this.setState({loading: false, error: false, campaigns: campaigns});
        }).catch(() => {
            this.setState({error: true});
        })
    }

    printStatus(status) {
        switch(status) {
            case "ACTIVE":
                return <Badge color="success" >ATTIVA</Badge>
            case "WAITING":
                return <Badge color="warning" >IN ATTESA</Badge>
            case "SUSPENDED":
                return <Badge color="warning" >SOSPESA</Badge>
            case "CANCELED":
                return <Badge color="danger" >CANCELLATA</Badge>
            default:
                return null;
        }
    }

    render() {

        const self = this;

        if (this.state.error) {
            return <React.Fragment>
                <Alert color="danger" >Si è verificato un errore durante il recupero della lista campagne.</Alert>
            </React.Fragment>
        }

        return (
            <React.Fragment>
                {(this.state.loading) ? <Loading /> : <div>
                    {(typeof this.props.id_contract_campaign !== "undefined") ? <div className="text-center" style={{margin: 10}}>
                        <Link to={"/campaign/add/" + this.props.id_contract_campaign}>
                            <button
                                type="button"
                                onClick={() => global.onSetModal({isOpen: false, content: "", loading: false})}
                                className="btn btn-success">
                                <i className="ri-add-line align-bottom me-1"></i> CREA CAMPAGNA
                            </button>
                        </Link>
                    </div> : null}
                    <div>
                        <Grid
                            data={this.state.campaigns}
                            columns={[
                                {
                                    id: 'id',
                                    name: '#',
                                    formatter: (cell, row) => _(
                                        <span className="text-nowrap">{cell}</span>
                                    ),
                                    width: "50px"
                                },
                                {
                                    id: 'client',
                                    name: "Cliente",
                                    formatter: (cell, row) => _(
                                        <span className="text-nowrap"><a href="javascript:void(0)" onClick={() => self.props.history.push("/client/" + cell.id)}><b>{cell.name}</b></a></span>
                                    ),
                                    search: {
                                        selector: (cell) => cell.name
                                    }
                                },
                                {
                                    id: 'name',
                                    formatter: (cell, row) => _(
                                        <span className="text-nowrap"><b>{cell}</b></span>
                                    ),
                                    name: "Nome"
                                },
                                {
                                    id: 'status',
                                    name: 'Stato',
                                    sort: false,
                                    formatter: (cell, row) => _(<div className="text-nowrap text-center">
                                        {this.printStatus(cell)}
                                    </div>)
                                },
                                {
                                    id: 'date_start',
                                    name: "Data inizio",
                                    formatter: (cell, row) => _(
                                        <div className="text-nowrap">{(cell === null) ? "N/D" : moment(cell).format("DD/MM/YYYY")}</div>
                                    ),
                                },
                                {
                                    id: 'date_end',
                                    name: "Data fine",
                                    formatter: (cell, row) => _(
                                        <div className="text-nowrap">{(cell === null) ? "N/D" : moment(cell).format("DD/MM/YYYY")}</div>
                                    ),
                                },
                                {
                                    id: 'id',
                                    name: '',
                                    sort: false,
                                    formatter: (cell, row, i) => _(<ul
                                        className="list-inline hstack gap-2 mb-0">
                                        <li className="list-inline-item cursor-pointer" onClick={() => {self.props.history.push("/campaign/" + row.cells[0].data);global.onSetModal({isOpen: false, content: ""})}}>
                                            <i className="ri-pencil-fill fs-20 text-info"></i>
                                        </li>
                                        <DeleteCampaign campaign={{id: row.cells[0].data}} onResolve={() => {
                                            this.getCampaigns();
                                        }}/>
                                    </ul>)
                                }
                            ]}
                            style={{table: {'white-space': 'nowrap'}}}
                            resizable={true}
                            // search={{
                            //     selector: (cell, rowIndex, cellIndex) => cellIndex === 6 ? JSON.stringify(cell) : cell
                            // }}
                            sort={true}
                            pagination={{ enabled: true, limit: 10}}
                            language={{
                                'search': {
                                    'placeholder': '🔍 Cerca...'
                                },
                                'pagination': {
                                    'previous': 'Indietro',
                                    'next': 'Avanti',
                                    'showing': 'Stai visualizzando da',
                                    'results': 'Campagne',
                                    'to': 'a',
                                    'of': 'di',
                                },
                                'noRecordsFound': 'Nessuna campagna trovata',
                                'error': 'Si è verificato un errore non previsto',
                            }}
                        />
                    </div>
                </div>}
            </React.Fragment>
        );
    }
}

export default withRouter(ListCampaign);
