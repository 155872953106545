import React, { Component } from 'react';
import {Alert, Badge, Button, Col, Container, FormFeedback, Input, Label, NavLink, Row} from 'reactstrap';
import {withRouter, Link} from "react-router-dom";
import { Grid, _ } from 'gridjs-react';

import { GestReport, DeleteReport, Loading, Utils } from "custom";
import { ReportAPI } from "api";
const moment = require("moment");


class ListReport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            reports: [],
            loading: true,
            error: false
        };
    }

    componentDidMount() {
        this.getReport();
    }

    getReport() {
        ReportAPI.list().then((reports) => {
            this.setState({loading: false, error: false, reports: reports});
        }).catch(() => {
            this.setState({error: true});
        })
    }

    render() {

        const self = this;

        if (this.state.error) {
            return <React.Fragment>
                <Alert color="danger" >Si è verificato un errore durante il recupero della lista delle segnalazioni.</Alert>
            </React.Fragment>
        }

        return (
            <React.Fragment>
                {(this.state.loading) ? <Loading /> : <div>
                    {(Utils.getUserData().role === "ADMIN") ? <div style={{margin: 10}}>
                        <GestReport onResolve={this.getReport.bind(this)}/>
                    </div> : null}
                    <div>
                        <Grid
                            data={this.state.reports}
                            columns={[
                                {
                                    id: 'id',
                                    name: '#',
                                    formatter: (cell, row) => _(
                                        <span className="text-nowrap">{cell}</span>
                                    ),
                                    width: "50px"
                                },
                                {
                                    id: 'monitor',
                                    name: "Segnalatore",
                                    formatter: (cell, row) => _(<div className="text-nowrap">
                                        <span className="text-nowrap">{(cell !== null) ? <a href="javascript:void(0)" onClick={() => this.props.history.push("/monitor/" + cell.id)}><b>{cell.name}</b></a> : <small>Pubbliartist</small>}</span>
                                    </div>)
                                },
                                ((Utils.getUserData().role === "ADMIN") ? {
                                    id: 'agent',
                                    name: "Agente",
                                    formatter: (cell, row) => _(<div className="text-nowrap">
                                        <span className="text-nowrap">{(cell !== null) ? <a href="javascript:void(0)" onClick={() => this.props.history.push("/agent/" + cell.id)}><b>{cell.firstname} {cell.lastname}</b></a> : <small>NON ASSEGNATO</small>}</span>
                                    </div>)
                                } : undefined),
                                {
                                    id: 'name',
                                    name: 'Nome',
                                    formatter: (cell, row) => _(<div className="text-nowrap">
                                        <b>{cell}</b>
                                    </div>)
                                },
                                {
                                    id: 'phone',
                                    name: "Telefono",
                                    formatter: (cell, row) => _(<div className="text-nowrap">
                                        <a href={"tel:" + cell} >{cell}</a>
                                    </div>)
                                },
                                {
                                    id: 'company',
                                    name: 'Azienda',
                                    formatter: (cell, row) => _(<div className="text-nowrap">
                                        {cell}
                                    </div>)
                                },
                                {
                                    id: 'converted',
                                    name: 'Completato',
                                    formatter: (cell, row) => _(<div className="text-nowrap text-center">
                                        {(cell) ? <i className="ri-check-fill fs-20 text-success"></i> : <i className="ri-close-fill fs-20 text-danger"></i>}
                                    </div>)
                                },
                                ((Utils.getUserData().role === "ADMIN") ? {
                                    id: 'id',
                                    name: '',
                                    sort: false,
                                    formatter: (cell, row, i) => _(<ul
                                        className="list-inline hstack gap-2 mb-0">
                                        <GestReport
                                            preset={{
                                                id: row.cells[0].data,
                                                id_agent: (row.cells[2].data !== null) ? row.cells[2].data.id : null,
                                                name: row.cells[3].data,
                                                phone: row.cells[4].data,
                                                company: row.cells[5].data
                                            }}
                                            onResolve={this.getReport.bind(this)}
                                        />
                                        <DeleteReport report={{id: row.cells[0].data}} onResolve={() => {
                                            this.getReport();
                                        }}/>
                                    </ul>)
                                } : undefined)
                            ]}
                            style={{table: {'white-space': 'nowrap'}}}
                            resizable={true}
                            // search={{
                            //     selector: (cell, rowIndex, cellIndex) => cellIndex === 6 ? JSON.stringify(cell) : cell
                            // }}
                            sort={true}
                            pagination={{ enabled: true, limit: 10}}
                            language={{
                                'search': {
                                    'placeholder': '🔍 Cerca...'
                                },
                                'pagination': {
                                    'previous': 'Indietro',
                                    'next': 'Avanti',
                                    'showing': 'Stai visualizzando da',
                                    'results': 'Segnalazioni',
                                    'to': 'a',
                                    'of': 'di',
                                },
                                'noRecordsFound': 'Nessuna segnalazione trovata',
                                'error': 'Si è verificato un errore non previsto',
                            }}
                        />
                    </div>
                </div>}
            </React.Fragment>
        );
    }
}

export default withRouter(ListReport);
