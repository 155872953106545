import React, { Component } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert, Spinner, FormText } from 'reactstrap';

import { Link } from "react-router-dom";
import {AuthAPI, ContractAPI} from "api";

import {Utils, Loading} from "custom";

import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import "react-pdf/dist/esm/Page/AnnotationLayer.css"

import SignatureCanvas from 'react-signature-canvas'

import DeviceOrientation, { Orientation } from 'react-screen-orientation'

const moment = require("moment");

class RedirectSignDraft extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: false,
            mode: "preview",
            loading: false
        };

        this.sigPad = {};

        console.log(this.props.token);
    }

    sendSign() {
        let url = this.sigPad.getTrimmedCanvas().toDataURL('image/png');

        this.setState({loading: true});
        ContractAPI.signDraft(url, this.props.token.id_draft, this.props.token.string).then((result) => {
            this.setState({loading: false});
            global.SweetAlert.fire({
                title: 'Contratto firmato correttamente!',
                text: '',
                icon: 'success',
                allowOutsideClick: false,
                customClass: {
                    confirmButton: 'btn btn-primary'
                },
                confirmButtonText: 'Vai a login',
                buttonsStyling: false
            }).then(function (result) {
                if (result.value) {
                    window.location.replace('/login');
                }
            });
        }).catch((err) => {
            console.error(err);
            global.SweetAlert.fire({
                icon: 'danger',
                title: 'Si è verificato un errore',
                text: '',
                customClass: {
                    confirmButton: 'btn btn-success'
                }
            });
        })

    }

    render() {
        document.title = "Verifica Contratto | Pubbli Seller";

        return <React.Fragment>
            {(this.state.loading) ? <Loading /> : <DeviceOrientation lockOrientation={'landscape'}>
                <Orientation orientation='landscape' alwaysRender={false}>
                    {(this.state.mode === "preview") ? <div>
                        <div className="text-center mt-2">
                            <h4 className="text-primary">Verifica bozza contratto</h4>
                        </div>

                        <div style={{marginLeft: "-103px"}}>
                            <Document file={this.props.token.url_draft} loading={<Loading />} error={<Alert color="danger">Si è verificato un errore durante il rendering del contratto</Alert>}>
                                <Page style={{section: { color: 'white', textAlign: 'center', margin: 30}}} pageNumber={1} scale={1} renderTextLayer={false}/>
                                <Page style={{section: { color: 'white', textAlign: 'center', margin: 30}}} pageNumber={2} scale={1} renderTextLayer={false}/>
                            </Document>
                        </div>
                        <button
                            style={{
                                position: "fixed",
                                bottom: 10,
                                left: 0,
                                right: 0,
                                margin: "0 auto"
                        }}
                            onClick={() => this.setState({mode: "sign"})}
                            type="button"
                            className="btn bg-success text-white m-1">
                            FIRMA CONTRATTO
                        </button>

                    </div> : <div>
                        <div className="text-center mt-2">
                            <h4 className="text-primary">Firma contratto</h4>
                        </div>
                        <div className="p-2 mt-4" style={{marginLeft: "-103px"}}>
                            <div style={{border: "solid 1px", backgroundColor: "#ffffff", width: "603px"}}>
                                <SignatureCanvas ref={(ref) => { this.sigPad = ref }} backgroundColor={"#ffffff00"} penColor='black' canvasProps={{width: 600, height: 250, className: 'sigCanvas'}} />
                            </div>
                            <br />
                        </div>
                        <div className="text-center mt-2">
                            <p className="text-muted">Assicurati di firmare all'interno del riquadro</p>
                        </div>
                        <div className="text-center flex-row">
                            <button
                                onClick={() => this.sendSign()}
                                type="button"
                                className="btn bg-success text-white m-1">
                                CONFERMA
                            </button>
                        </div>
                        <br />
                        <div className="text-center flex-row">
                            <a onClick={() => this.setState({mode: "preview"})}>visualizza contratto</a>
                        </div>
                    </div>}
                </Orientation>
                <Orientation orientation='portrait' alwaysRender={false}>
                    <div className="text-center mt-2">
                        <h4 className="text-primary">Per favore ruota il tuo dispositivo</h4>
                        <br />
                        <i className="mdi mdi-phone-rotate-landscape fs-48 text-primary"></i>
                    </div>
                </Orientation>
            </DeviceOrientation>}
        </React.Fragment>
    }
}

export default RedirectSignDraft;
