import React, { Component } from 'react';
import {Alert, Badge, Card, CardHeader, CardBody, Col, Button, Container, Row, Nav, NavItem, NavLink, TabPane, TabContent, Table} from 'reactstrap';
import {withRouter, Link} from "react-router-dom";

import {GestSlot, ListSlot} from "custom";

const moment = require("moment");

class TabSlot extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: []
        }
    }

    componentDidMount() {

    }

    selectSlot(slot) {
        let selected = this.state.selected;
        let check = false;
        for (let i = 0; i < selected.length; i++) {
            if (slot.id === selected[i]) {
                check = true;
                selected.splice(i, 1);
            }
        }

        if (!check)
            selected.push(slot.id)

        this.setState({selected: selected});
    }

    editSelected() {
        global.onSetModal({
            title: "MODIFICA SLOT",
            content: <GestSlot selected={this.state.selected} onResolve={this.props.onReload.bind(this)} />,
            isOpen: true,
            toggle: false,
            loading: false,
            size: 'lg',
            type: 'info',
            buttons: [
                {
                    label: 'ANNULLA',
                    color: 'danger',
                    onClick: () => global.onSetModal({content: "", isOpen: false, loading: false})
                },
            ]
        });
    }


    render() {
        const monitor = this.props.monitor;

        return (
            <React.Fragment>
                <Row>
                    <Col xxl={12}>
                        <Card>
                            <CardHeader>
                                <h5 className="card-title mb-5 d-md-none">Palinsesto</h5>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col>
                                        <div className="text-end">
                                            <button
                                                disabled={(this.state.selected.length === 0)}
                                                type="button"
                                                className="btn btn-info"
                                                onClick={() => this.editSelected()}>
                                                <i className="ri-pencil-fill align-bottom"></i> Modifica
                                            </button>
                                        </div>
                                        <ListSlot id_monitor_slot={monitor.id} selected={this.state.selected} getVariable onResolve={(slot) => this.selectSlot(slot)}/>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default withRouter(TabSlot);
