import React, { Component } from 'react';
import {Alert, Badge, Card, CardHeader, CardBody, Col, Container, Row, Nav, NavItem, NavLink, TabPane, TabContent, Table, Button} from 'reactstrap';
import {withRouter, Redirect, Link} from "react-router-dom";

import {MonitorAPI, UserAPI} from "api";

import {Loading, GoogleMap, GestMonitor, Utils} from "custom";

import profileBg from '../../assets/images/monitor-bg.jpg';
import avatarMonitor from '../../assets/images/avatar/monitor.jpg';

import TabPosition from "./TabsGest/TabPosition";
import TabSlot from "./TabsGest/TabSlot";
import TabLink from "./TabsGest/TabLink";
import TabTicket from "./TabsGest/TabTicket";

const moment = require("moment");


class MonitorGest extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: false,
            monitor: null,
            tabActive: 1
        };
        document.title ="Gestione punto di diffusione | Pubbli Seller";
    }

    componentDidMount() {
        this.getDetailMonitor();
    }

    getDetailMonitor() {
        this.setState({loading: true})
        MonitorAPI.detail(this.props.match.params.id).then((monitor) => {
            this.setState({monitor: monitor, loading: false})
        }).catch((err) => {
            console.error(err);
            this.setState({error: true})
        })
    }

    deleteMonitor() {
        const self = this;
        global.SweetAlert.fire({
            title: "Elimina punto di diffusione",
            text: 'Attenzione, stai eliminando un punto di diffusione, questa azione eliminerà anche tutti i dati associati ad esso. Vuoi procedere??',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, elimina',
            cancelButtonText: 'Annulla',
            customClass: {
                confirmButton: 'btn btn-danger',
                cancelButton: 'btn btn-outline-danger ms-1'
            },
            buttonsStyling: false,
            showLoaderOnConfirm: true,
            preConfirm: function() {
                return new Promise(function(resolve, reject) {
                    MonitorAPI.remove(self.state.monitor.id).then(() => {
                        resolve();
                    }).catch(() => {
                        global.SweetAlert.fire({
                            icon: 'danger',
                            title: 'Si è verificato un errore :(',
                            text: 'Purtroppo qualcosa è andato storto e non è stato possibile eliminare il punto di diffusione. Riprova più tardi.',
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        });
                        reject()
                    })
                });
            },
        }).then(function (result) {
            if (result.value) {
                global.SweetAlert.fire({
                    icon: 'success',
                    title: 'Punto di diffusione eliminato!',
                    text: 'Punto di diffusione eliminato correttamente.',
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                });
                window.location.replace("/monitor");
            }
        })
    }


    render() {

        if (this.state.error) {
            return <React.Fragment>
                <div className="page-content">
                    <Alert color="danger" >Si è verificato un errore durante il recupero dei dettagli del punto di diffusione.</Alert>
                </div>
            </React.Fragment>
        }

        const monitor = this.state.monitor;
        const user = Utils.getUserData();

        return (
            <React.Fragment>
                {(this.state.loading) ? <Loading /> : <div className="page-content">
                    <Container fluid>
                        <div className="profile-foreground position-relative mx-n4 mt-n4">
                            <div className="profile-wid-bg">
                                <img src={profileBg} alt="" className="profile-wid-img" />
                            </div>
                        </div>
                        <div className="pt-4 mb-4 mb-lg-3 pb-lg-4">
                            <Row className="g-4">
                                <div className="col-auto">
                                    <div className="avatar-lg">
                                        <img src={avatarMonitor} alt="user-img"
                                             className="img-thumbnail rounded-circle" />
                                    </div>
                                </div>
                                <Col>
                                    <Button onClick={() => this.props.history.goBack()} className="btn btn-sm btn-info" ><i className="ri-arrow-left-circle-line fs-5 text-white-75 align-middle" /> Torna alla lista</Button>
                                    <div className="p-2">
                                        <h3 className="text-white mb-1">{monitor.name}</h3>
                                        <p className="text-white-75">Punto di diffusione</p>
                                        <div className="hstack text-white-50 gap-1">
                                            <div className="me-2">
                                                <i className="ri-map-pin-user-line me-1 text-white-75 fs-16 align-middle"></i>{monitor.marker.full_address}
                                            </div>
                                        </div>
                                        <div className="hstack text-white-50 gap-1">
                                            <div className="me-2">
                                                <i className="ri-calendar-2-fill me-1 text-white-75 fs-16 align-middle"></i>Attivo da: {moment(monitor.createdAt).format("DD/MM/YYYY")}
                                            </div>
                                        </div>
                                        <div className="hstack text-white-50 gap-1">
                                            <div className="me-2">
                                                <i className="ri-bookmark-2-fill me-1 text-white-75 fs-16 align-middle"></i>Disponibilità: <span className={(monitor.available_slots < 10) ? "text-danger" : undefined}>{monitor.available_slots}</span> / 32
                                            </div>
                                        </div>
                                    </div>
                                </Col>

                                <Col xs={12} className="col-lg-auto order-last order-lg-0">
                                    <Row className="text text-white-50 text-center">
                                        <Col lg={4} xs={3}>
                                            <div className="p-2">
                                                <h5 className="text-white mb-1">Stato</h5>
                                                {(monitor.status === "FULL") ? <Badge color="danger" pill>Pieno</Badge> : ((monitor.status === "LOW") ? <Badge color="warning" pill>In esaurimento</Badge> : <Badge color="success" pill>Libero</Badge>)}
                                            </div>
                                        </Col>
                                        <Col lg={4} xs={3}>
                                            <div className="p-2">
                                                <h5 className="text-white mb-1">Ingressi medi</h5>
                                                <p className="fs-16 mb-0">{monitor.medium_lobby}</p>
                                            </div>
                                        </Col>
                                        <Col lg={4} xs={3}>
                                            <div className="p-2">
                                                <h5 className="text-white mb-1">ID</h5>
                                                <p className="fs-16 mb-0">{monitor.id}</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>

                        <Row>
                            <Col lg={12}>
                                <div>
                                    <div className="d-flex">
                                        <Nav pills className="animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1"
                                             role="tablist">
                                            <NavItem>
                                                <NavLink
                                                    className={ "cursor-pointer " + ((this.state.tabActive === 1) ? "fs-16 bg-white text-dark" : null)}
                                                    onClick={() => this.setState({tabActive: 1}) }
                                                >
                                                    <i className="ri-map-2-fill d-inline-block d-md-none"></i> <span
                                                    className="d-none d-md-inline-block">Posizione</span>
                                                </NavLink>
                                            </NavItem>
                                            {(user.role !== "AGENT") ? <>
                                                <NavItem>
                                                    <NavLink
                                                        className={ "cursor-pointer " + ((this.state.tabActive === 2) ? "fs-16 bg-white text-dark" : null)}
                                                        onClick={() => this.setState({tabActive: 2}) }
                                                    >
                                                        <i className="ri-list-unordered d-inline-block d-md-none"></i> <span
                                                        className="d-none d-md-inline-block">Palinsesto</span>
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={ "cursor-pointer " + ((this.state.tabActive === 3) ? "fs-16 bg-white text-dark" : null)}
                                                        onClick={() => this.setState({tabActive: 3}) }
                                                    >
                                                        <i className="ri-image-fill d-inline-block d-md-none"></i> <span
                                                        className="d-none d-md-inline-block">Link</span>
                                                    </NavLink>
                                                </NavItem>
                                            </>: null}
                                            {/*<NavItem>*/}
                                            {/*    <NavLink*/}
                                            {/*        className={ "cursor-pointer " + ((this.state.tabActive === 5) ? "fs-16 bg-white text-dark" : null)}*/}
                                            {/*        onClick={() => this.setState({tabActive: 5}) }*/}
                                            {/*    >*/}
                                            {/*        <i className="ri-settings-2-fill d-inline-block d-md-none"></i> <span*/}
                                            {/*        className="d-none d-md-inline-block">Ticket</span>*/}
                                            {/*    </NavLink>*/}
                                            {/*</NavItem>*/}
                                        </Nav>
                                        <div className="flex-shrink-0">
                                            {(Utils.getUserData().role === "ADMIN") ? <GestMonitor preset={monitor} onResolve={this.getDetailMonitor.bind(this)}/> : null}
                                        </div>
                                    </div>

                                    <TabContent activeTab={String(this.state.tabActive)} className="pt-4">
                                        <TabPane tabId="1">
                                            <TabPosition monitor={monitor} onReload={this.getDetailMonitor.bind(this)}/>
                                        </TabPane>
                                        {(user.role !== "AGENT") ? <>
                                            <TabPane tabId="2">
                                                <TabSlot monitor={monitor} onReload={this.getDetailMonitor.bind(this)}/>
                                            </TabPane>
                                            <TabPane tabId="3">
                                                <TabLink monitor={monitor} onReload={this.getDetailMonitor.bind(this)}/>
                                            </TabPane>
                                        </>: null}
                                    </TabContent>

                                    {(Utils.getUserData().role === "ADMIN") ? <div className="text-center" style={{margin: 10}}>
                                        <button
                                            type="button"
                                            className="btn btn-danger"
                                            onClick={() => this.deleteMonitor()}>
                                            <i className="ri-delete-bin-2-fill align-bottom"></i> Elimina punto di diffusione
                                        </button>
                                    </div> : null}
                                </div>
                            </Col>
                        </Row>

                    </Container>
                </div>}
            </React.Fragment>
        );
    }
}

export default withRouter(MonitorGest);
