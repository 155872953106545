import React, {Fragment} from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert, Row, Col, Label, FormFeedback, Input} from 'reactstrap';

import {Utils} from "custom";
import { MonitorAPI, ReferentAPI } from "api";

class GestLinkMonitor extends React.Component {
    constructor(props) {
        super(props);
        let preset = this.props.preset;

        this.state = {
            id_link_monitor: preset.id_link_monitor ?? null,
            id: preset.id ?? null,
            form: {
                name: preset.name ?? "",
                path: preset.path ?? "",
                show: preset.show ?? false
            },
            loading: false,
            errors: false
        };
    }


    resetForm() {
        let preset = this.props.preset;
        this.setState({
            id_link_monitor: preset.id_link_monitor ?? null,
            id: preset.id ?? null,
            form: {
                name: preset.name ?? "",
                path: preset.path ?? "",
                show: preset.show ?? false
            }
        });
        global.onSetModal({isOpen: false, content: ""});
    }

    changeForm(key, text) {
        const form = this.state.form;
        form[key] = text;
        this.setState({form: form}, () => this.showModal());
    }

    showModal() {
        let form = this.state.form;
        let errors = this.state.errors;

        global.onSetModal({
            title: (this.state.id !== null) ? "MODIFICA LINK" : "CREA LINK",
            content: <div>
                <Row>
                    <Col md="12">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Nome*
                            </Label>
                            <Input type="text" defaultValue={form.name}
                                   invalid={Boolean(errors.name)}
                                   onChange={(e) => this.changeForm('name', e.target.value)}/>
                            <FormFeedback>{errors.name}</FormFeedback>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                URL*
                            </Label>
                            <Input type="text" defaultValue={form.path}
                                   invalid={Boolean(errors.path)}
                                   onChange={(e) => this.changeForm('path', e.target.value)}/>
                            <FormFeedback>{errors.path}</FormFeedback>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <div className='mb-2'>
                            <div className="form-check form-check-outline form-check-primary" >
                                <Input className="form-check-input" type="checkbox" checked={form.show} onChange={(e) => this.changeForm("show", e.target.checked)}/>
                                <Label className="form-check-label">
                                    Visibile in Galleria ?
                                </Label>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>,
            isOpen: true,
            toggle: false,
            size: 'lg',
            type: 'info',
            buttons: [
                {label: 'SALVA', color: 'success', onClick: () => this.saveLink()},
                {
                    label: 'ANNULLA',
                    color: 'danger',
                    onClick: () => this.setState({errors: false}, () => this.resetForm())
                },
            ]
        })
    }


    saveLink() {
        const self = this;
        let form = this.state.form;
        let error = {};

        if (form.name === '')
            error.name = "Il campo nome è obbligatorio";

        if (form.path !== '' && !Utils.isValidUrl(form.path))
            error.path = "L\'url inserito non è corretto";

        if (form.path === '')
            error.path = "Il campo url è obbligatorio";

        this.setState({errors: error}, () => this.showModal());

        if (Object.keys(error).length === 0) {
            global.onSetModal({loading: true});

            let add = (this.state.id === null);
            let api = (add) ? MonitorAPI.addLink : MonitorAPI.editLink;

            form["id"] = this.state.id;
            form["id_link_monitor"] = this.state.id_link_monitor;

            api(form).then(() => {
                global.onSetModal({loading: false});
                global.SweetAlert.fire({
                    title: (add) ? 'Link creato' : 'Link modificato',
                    text: (add) ? 'Il link è stato creato correttamente.' : 'Il link è stato modificato correttamente.',
                    icon: 'success',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    },
                    confirmButtonText: 'Chiudi',
                    buttonsStyling: false
                }).then(function (result) {
                    if (result.value) {
                        self.props.onResolve();
                        self.resetForm();
                    }
                })
            }).catch(() => {
                global.onSetModal({loading: false});
                global.SweetAlert.fire({
                    title: 'Errore',
                    text: (add) ? 'Si è verificato un errore durante la creazione del link' : 'Si è verificato un errore durante la modifica del link',
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    }
                });
            })
        }
    }


    render() {
        return (
            <React.Fragment>
                {(this.state.id !== null) ? <li className="list-inline-item cursor-pointer"
                    onClick={() => this.showModal()}>
                    <i className="ri-pencil-fill fs-20 text-info"></i>
                </li> : <button
                    type="button"
                    className="btn btn-success add-btn"
                    id="create-btn"
                    onClick={() => this.showModal()}>
                    <i className="ri-add-line align-bottom me-1"></i> CREA LINK
                </button>}
            </React.Fragment>
        )
    }
}

export default GestLinkMonitor;
