import React, {Component} from 'react';
import {Alert, Card, CardBody, CardHeader, Col, Container, FormFeedback, Input, Label, Row, Badge} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import {withRouter, Link} from "react-router-dom";

import {AgentAPI, MarkerAPI} from "api";
import {Loading, Utils, ListDraft, GestAppointment} from "custom";

import MapContainer from "./MapContainer";
import contractIcon from "../../assets/icons/marker_contract_ads.png";
import contactIcon from "../../assets/icons/marker_contact.png";

import 'moment/locale/it';
import contractExpIcon from "../../assets/icons/marker_contract_expired.png";
import Select from "react-select";
import dealIcon from "../../assets/icons/marker_contact_deal.png";
import contractAdsIcon from "../../assets/icons/marker_contract_ads.png";
import monitorIcon from "../../assets/icons/marker_monitor.png";

const moment = require("moment");

class Map extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            markers: [],
            monitors: [],
            appointments: [],
            covers: [],
            typeMap: "cover",
            agents: [],
            coords: {
                lat: Number(process.env.REACT_APP_GOOGLE_SERVICES_DEFAULT_LAT),
                lon: Number(process.env.REACT_APP_GOOGLE_SERVICES_DEFAULT_LON)
            },
            searchCoords: false,
            filters: {
                monitor: true,
                monitorFull: true,
                monitorCover: false,
                contract: true,
                contractExpired: false,
                appointment: true,
                deal: true,
                owner: false,
                agent: {label: "TUTTI", value: null},
                mapStandard: false
            },
            error: false,
            appointmentsNow: [],
            searchMarkers: []
        }

        document.title = "Mappa | Pubbli Seller";
    }

    componentDidMount() {
        this.getMarkers();
    }

    getMarkers() {
        MarkerAPI.list().then((markers) => {
            let monitors = markers.monitors;
            let clients = markers.clients;
            let appointments = markers.appointments;
            let covers = [];
            let maxLobby = Math.max.apply(Math, monitors.map(function (o) {
                return o.monitor.medium_lobby;
            }));
            for (let i = 0; i < monitors.length; i++) {
                let weight = monitors[i].monitor.medium_lobby / maxLobby;
                if (weight < 0.50)
                    weight = 0.50
                covers.push({
                    lat: monitors[i].lat,
                    lng: monitors[i].lon,
                    weight: weight,
                    medium_lobby: monitors[i].monitor.medium_lobby
                });
            }
            this.monitorsHolder = monitors;
            this.clientsHolder = clients;
            this.appointmentsHolder = appointments;
            this.coversHolder = covers;

            if (Utils.getUserData().role === "ADMIN") {
                AgentAPI.list().then((agents) => {
                    this.setState({
                        agents: [{label: "TUTTI", value: null}].concat(agents.map((agent) => {
                            return {label: agent.firstname + " " + agent.lastname, value: agent.id}
                        }))
                    });
                });
            }

            this.applyFilter();
        }).catch((e) => {
            console.error(e)
            this.setState({error: true});
        })
    }

    async applyFilter() {


        function distance(lat1, lng1, lat2, lng2) {
            const degToRad = (deg) => deg * (Math.PI / 180);

            const earthRadius = 6371; // Raggio medio della Terra in chilometri

            const dLat = degToRad(lat2 - lat1);
            const dLng = degToRad(lng2 - lng1);

            const a =
                Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos(degToRad(lat1)) * Math.cos(degToRad(lat2)) * Math.sin(dLng / 2) * Math.sin(dLng / 2);
            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

            const distance = earthRadius * c;

            return distance;
        }

        function sortByDistanceFromCurrentLocation(currentLocation, array) {
            const sortedArray = array.sort((a, b) => {
                const distanceA = distance(
                    currentLocation.lat,
                    currentLocation.lng,
                    a.value.lat,
                    a.value.lng
                );
                const distanceB = distance(
                    currentLocation.lat,
                    currentLocation.lng,
                    b.value.lat,
                    b.value.lng
                );
                return distanceA - distanceB;
            });

            return sortedArray;
        }

        const contractAdsIcon = require("../../assets/icons/marker_contract_ads.png");
        const contactIcon = require("../../assets/icons/marker_contact.png");
        const dealIcon = require("../../assets/icons/marker_contact_deal.png");
        const monitorIcon = require("../../assets/icons/marker_monitor.png");

        let covers = [];
        let monitors = [];
        let clients = [];
        let appointments = [];

        let searchMarkers = [];

        const self = this;

        let filters = this.state.filters;

        if (filters.monitor) {
            for (let i = 0; i < this.monitorsHolder.length; i++) {
                try {
                    searchMarkers.push({img: monitorIcon, label: this.monitorsHolder[i].monitor.name + " (MONITOR)", value: {lat: this.monitorsHolder[i].lat, lng: this.monitorsHolder[i].lon}});
                } catch (e) {
                    console.error(e)
                }
                if (this.monitorsHolder[i].monitor.available_slots > 0)
                    monitors.push(this.monitorsHolder[i]);

                if (this.monitorsHolder[i].monitor.available_slots === 0)
                    monitors.push(this.monitorsHolder[i]);
            }
        }

        if (filters.monitorFull) {
            for (let i = 0; i < this.monitorsHolder.length; i++) {
                if (this.monitorsHolder[i].monitor.available_slots === 0)
                    monitors.push(this.monitorsHolder[i]);
            }
        }

        if (filters.monitorCover)
            covers = this.coversHolder;

        if (filters.contract) {
            for (let i = 0; i < this.clientsHolder.length; i++) {
                try {
                    searchMarkers.push({img: contractAdsIcon, label: this.clientsHolder[i].client.name + " (CONTRATTO)", value: {lat: this.clientsHolder[i].lat, lng: this.clientsHolder[i].lon}});
                } catch (e) {
                    console.error(e)
                }
                for (let d = 0; d < this.clientsHolder[i].client.contracts.length; d++) {
                    if (moment().isBefore(this.clientsHolder[i].client.contracts[d].date_expire))
                        clients.push(this.clientsHolder[i]);
                }
            }
        }

        if (filters.appointment) {
            for (let i = 0; i < this.appointmentsHolder.length; i++) {
                if (this.appointmentsHolder[i].appointment.status === "APPOINTMENT") {
                    try {
                        searchMarkers.push({img: contactIcon, label: this.appointmentsHolder[i].appointment.name + " (APPUNTAMENTO)", value: {lat: this.appointmentsHolder[i].lat, lng: this.appointmentsHolder[i].lon}});
                    } catch (e) {
                        console.error(e)
                    }
                    appointments.push(this.appointmentsHolder[i]);
                }
            }
        }

        if (filters.deal) {
            for (let i = 0; i < this.appointmentsHolder.length; i++) {
                if (this.appointmentsHolder[i].appointment.status === "DEAL") {
                    try {
                        searchMarkers.push({img: dealIcon, label: this.appointmentsHolder[i].appointment.name + " (TRATTATIVA)", value: {lat: this.appointmentsHolder[i].lat, lng: this.appointmentsHolder[i].lon}});
                    } catch (e) {
                        console.error(e)
                    }
                    appointments.push(this.appointmentsHolder[i]);
                }
            }
        }

        if (filters.contractExpired) {
            for (let i = 0; i < this.clientsHolder.length; i++) {
                for (let d = 0; d < this.clientsHolder[i].client.contracts.length; d++) {
                    if (moment().isAfter(this.clientsHolder[i].client.contracts[d].date_expire))
                        clients.push(this.clientsHolder[i]);
                }
            }
        }


        if (filters.owner && Utils.getUserData().role === "AGENT") {
            clients = [];
            for (let i = 0; i < this.clientsHolder.length; i++) {
                for (let d = 0; d < this.clientsHolder[i].client.contracts.length; d++) {
                    if (this.clientsHolder[i].client.contracts[d].agent !== null)
                        if (this.clientsHolder[i].client.contracts[d].agent.id === Utils.getUserData().id)
                            clients.push(this.clientsHolder[i]);
                }
            }
        }

        if (filters.agent.value !== null && Utils.getUserData().role === "ADMIN") {
            clients = [];
            for (let i = 0; i < this.clientsHolder.length; i++) {
                for (let d = 0; d < this.clientsHolder[i].client.contracts.length; d++) {
                    if (this.clientsHolder[i].client.contracts[d].agent !== null)
                        if (this.clientsHolder[i].client.contracts[d].agent.id === filters.agent.value)
                            clients.push(this.clientsHolder[i]);
                }
            }
        }

        global.onSetModal({isOpen: false, content: ""});

        let appointmentsNow = [];

        let appointmentsHolder = this.appointmentsHolder;

        for (let i = 0; i < appointmentsHolder.length; i++) {
            if (appointmentsHolder[i].appointment.date_time !== null) {
                if (moment(appointmentsHolder[i].appointment.date_time).format("DD-MM-YYYY") === moment().format("DD-MM-YYYY")) {
                    appointmentsNow.push(appointmentsHolder[i]);
                }
            }
        }

        let posGrant = false;

        try {
            const permission = await navigator.permissions.query({name: 'geolocation'});

            if (permission.state !== 'prompt' && permission.state !== 'denied') {
                posGrant = true
            }
        } catch (error) {
            console.error('Error requesting permission:', error);
        }

        console.log("searchMarkers", searchMarkers)



        navigator.geolocation.getCurrentPosition(function (position) {

            let positionCoords = self.state.coords;

            if (posGrant)
                positionCoords = {lat: position.coords.latitude, lon: position.coords.longitude};

            const sortedArray = sortByDistanceFromCurrentLocation({lat: position.coords.latitude, lng: position.coords.longitude}, searchMarkers);

            console.log(position)
            self.setState({
                coords: positionCoords,
                loading: false,
                error: false,
                monitors: monitors,
                clients: clients,
                appointments: appointments,
                covers: covers,
                appointmentsNow: appointmentsNow,
                searchMarkers: sortedArray
            });
        }, () => {

            const sortedArray = sortByDistanceFromCurrentLocation({lat: self.state.coords.latitude, lng: self.state.coords.longitude}, searchMarkers);

            console.log("sortedArray", sortedArray)

            self.setState({
                coords: self.state.coords,
                loading: false,
                error: false,
                monitors: monitors,
                clients: clients,
                appointments: appointments,
                covers: covers,
                appointmentsNow: appointmentsNow,
                searchMarkers: sortedArray
            });
        })
    }

    setFilter(key, value) {
        let filters = this.state.filters;
        filters[key] = value;
        this.setState({filters: filters}, () => this.showModalFilter())
    }

    showModalFilter() {
        let filters = this.state.filters;

        const monitorIcon = require("../../assets/icons/marker_monitor.png");
        const monitorFullIcon = require("../../assets/icons/marker_monitor_full.png");
        const contactIcon = require("../../assets/icons/marker_contact.png");
        const dealIcon = require("../../assets/icons/marker_contact_deal.png");
        const contractIcon = require("../../assets/icons/marker_contract_ads.png");
        const contractExpIcon = require("../../assets/icons/marker_contract_expired.png");

        global.onSetModal({
            title: "FILTRI MAPPA",
            content: <div>
                <div className='mb-2'>
                    <div className="form-check form-check-outline form-check-primary mb-3"
                         onClick={(e) => this.setFilter('mapStandard', !(filters.mapStandard))}>
                        <Input className="form-check-input" type="checkbox" checked={filters.mapStandard}/>
                        <Label className="form-check-label">
                            Mappa Standard (Google Maps)
                        </Label>
                    </div>
                    <hr/>
                    <div className="form-check form-check-outline form-check-primary mb-3"
                         onClick={(e) => this.setFilter('monitor', !(filters.monitor))}>
                        <Input className="form-check-input" type="checkbox" checked={filters.monitor}/>
                        <Label className="form-check-label">
                            <img src={monitorIcon} width={"15px"}/>&nbsp;&nbsp;Monitor disponibili
                        </Label>
                    </div>
                    <div className="form-check form-check-outline form-check-primary mb-3"
                         onClick={(e) => this.setFilter('monitorFull', !(filters.monitorFull))}>
                        <Input className="form-check-input" type="checkbox" checked={filters.monitorFull}/>
                        <Label className="form-check-label">
                            <img src={monitorFullIcon} width={"15px"}/>&nbsp;&nbsp;Monitor pieni
                        </Label>
                    </div>
                    <div className="form-check form-check-outline form-check-primary mb-3"
                         onClick={(e) => this.setFilter('monitorCover', !(filters.monitorCover))}>
                        <Input className="form-check-input" type="checkbox" checked={filters.monitorCover}/>
                        <Label className="form-check-label">
                            Copertura Monitor
                        </Label>
                    </div>
                    <hr/>
                    {/*<div className="form-check form-check-outline form-check-primary mb-3">*/}
                    {/*    <Input className="form-check-input" type="checkbox" checked={filters.contract} onChange={(e) => this.setFilter('contract', e.target.checked)}/>*/}
                    {/*    <Label className="form-check-label">*/}
                    {/*        I miei clienti*/}
                    {/*    </Label>*/}
                    {/*</div>*/}
                    <div className="form-check form-check-outline form-check-primary mb-3"
                         onClick={(e) => this.setFilter('appointment', !(filters.appointment))}>
                        <Input className="form-check-input" type="checkbox" checked={filters.appointment}/>
                        <Label className="form-check-label">
                            <img src={contactIcon} width={"15px"}/>&nbsp;&nbsp;Appuntamenti
                        </Label>
                    </div>
                    <div className="form-check form-check-outline form-check-primary mb-3"
                         onClick={(e) => this.setFilter('deal', !(filters.deal))}>
                        <Input className="form-check-input" type="checkbox" checked={filters.deal}/>
                        <Label className="form-check-label">
                            <img src={dealIcon} width={"15px"}/>&nbsp;&nbsp;trattative
                        </Label>
                    </div>
                    <div className="form-check form-check-outline form-check-primary mb-3"
                         onClick={(e) => this.setFilter('contract', !(filters.contract))}>
                        <Input className="form-check-input" type="checkbox" checked={filters.contract}/>
                        <Label className="form-check-label">
                            <img src={contractIcon} width={"15px"}/>&nbsp;&nbsp;Contratti attivi
                        </Label>
                    </div>
                    <div className="form-check form-check-outline form-check-primary mb-3"
                         onClick={(e) => this.setFilter('contractExpired', !(filters.contractExpired))}>
                        <Input className="form-check-input" type="checkbox" checked={filters.contractExpired}/>
                        <Label className="form-check-label">
                            <img src={contractExpIcon} width={"15px"}/>&nbsp;&nbsp;Contratti scaduti (da meno di 6 mesi)
                        </Label>
                    </div>
                    <hr/>
                    {(Utils.getUserData().role === "ADMIN") ? <div className="mb-3">
                        <Label className='form-label'>
                            Filtro Agente
                        </Label>
                        <Select
                            value={filters.agent}
                            onChange={(selectedOption, triggeredAction) => {
                                this.setFilter('agent', selectedOption)
                            }}
                            options={this.state.agents}
                        />
                    </div> : null}
                    {(Utils.getUserData().role === "AGENT") ?
                        <div className="form-check form-check-outline form-check-primary mb-3"
                             onClick={(e) => this.setFilter('owner', !(filters.owner))}>
                            <Input className="form-check-input" type="checkbox" checked={filters.owner}/>
                            <Label className="form-check-label">
                                Mostra solo i miei clienti
                            </Label>
                        </div> : null}
                </div>
            </div>,
            isOpen: true,
            toggle: false,
            size: 'md',
            type: 'info',
            buttons: [
                {label: 'APPLICA', color: 'success', onClick: () => this.applyFilter()}
            ]
        })
    }

    render() {
        const self = this;

        return (
            <React.Fragment>
                <div className="page-content">
                    <BreadCrumb title="Mappa" pageTitle="Pubbli Seller"/>
                    <ListDraft/>
                    {(Utils.getUserData().role === "AGENT") ? this.state.appointmentsNow.map((object, i) => {
                        console.log(object)
                        if (object.appointment.agent.id === Utils.getUserData().id) {
                            return <Alert key={i} color="primary">
                                <button
                                    type="button"
                                    className="btn btn-success btn-sm"
                                    onClick={() => this.props.history.replace("/contract/build/campaign?type=appointment&id=" + object.appointment.id)}>
                                    <span style={{display: "flex", flexDirection: "row"}}><i className="mdi mdi-check"/>&nbsp;<span className="d-none d-md-block d-lg-block">CONVERTI</span></span>
                                </button>
                                &nbsp;
                                <GestAppointment preset={object.appointment} onResolve={this.getMarkers.bind(this)}/>
                                &nbsp;&nbsp;APPUNTAMENTO
                                - <strong>{object.appointment.name}</strong> {(object.appointment.date_time !== null) ? " - oggi alle ore " + moment(object.appointment.date_time).format("HH:mm") : null}
                            </Alert>
                        } else {
                            return null
                        }
                    }) : null}
                    <Card style={{
                        height: '80vh',
                        margin: 0
                    }}>
                        <CardHeader>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center"
                            }}>
                                <div className='mb-2' style={{width: "80%"}}>
                                    <Select
                                        // value={(form.typology === "ADVERTISING") ? {value: "ADVERTISING", label: "PUBBLICITÀ"} : {value: "RENTAL", label: "NOLEGGIO"}}
                                        placeholder={"Cerca..."}
                                        onChange={(selectedOption, triggeredAction) => {
                                            self.setState({searchCoords: {lat: selectedOption.value.lat, lon: selectedOption.value.lng}})
                                        }}
                                        options={this.state.searchMarkers}
                                        formatOptionLabel={country => (
                                            <div className="country-option">
                                                <img src={country.img} width="15px" />
                                                <span>&nbsp;&nbsp;{country.label}</span>
                                            </div>
                                        )}
                                    />
                                </div>
                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}>
                                    <button
                                        type="button"
                                        className="btn btn-primary btn-sm"
                                        onClick={() => this.showModalFilter()}>
                                        <i className="ri-filter-2-fill align-bottom me-1"></i> FILTRI
                                    </button>
                                    &nbsp;&nbsp;
                                    {(Utils.getUserData().role === "AGENT") ? <Link to={"/contract/build"}>
                                        <button
                                            type="button"
                                            className="btn btn-success btn-sm">
                                            <i className="ri-add-box-fill align-bottom me-1"></i> CREA CONTRATTO
                                        </button>
                                    </Link> : null}
                                    &nbsp;&nbsp;
                                    {(Utils.getUserData().role === "AGENT") ? <Link to={"/appointment"}>
                                        <button
                                            type="button"
                                            className="btn btn-warning btn-sm">
                                            <i className="ri-calendar-2-fill align-bottom me-1"></i> CREA APPUNTAMENTO
                                        </button>
                                    </Link> : null}
                                    <a href={"https://www.google.com/maps/d/edit?mid=1S_nnH0l5HjxF7ngnaUzW78R5kqkd97Q&usp=drive_link"} target={"_blank"}>
                                        <button
                                            type="button"
                                            className="btn btn-info btn-sm">
                                            <i className="ri-map-2-fill align-bottom me-1"></i> MAPPA GOOGLE
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </CardHeader>
                        <CardBody style={{padding: 0}}>
                            {(this.state.loading) ? <Loading/> : (!this.state.error) ?
                                <MapContainer coords={this.state.coords} monitors={this.state.monitors}
                                              appointments={this.state.appointments} clients={this.state.clients}
                                              covers={this.state.covers} filters={this.state.filters} searchCoords={this.state.searchCoords}/> :
                                <Alert color="danger">Si è verificato un errore durante il recupero della
                                    mappa.</Alert>}
                        </CardBody>
                    </Card>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(Map);
