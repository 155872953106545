import React, {Component} from 'react';
import {
    Alert,
    Card,
    CardHeader,
    CardBody,
    Col,
    Container,
    Row,
    Table,
    Input, Label, FormFeedback, Button, Accordion, AccordionItem, Collapse, Badge
} from 'reactstrap';
import {withRouter, Redirect, Link,} from "react-router-dom";

import {ContractAPI, CampaignAPI, MonitorAPI, CampaignCodeAPI} from "api";

import {Loading, ListSlot} from "custom";
import BreadCrumb from "../../Components/Common/BreadCrumb";

import {Utils} from "custom";
import Select from "react-select";
import {debounce} from "lodash";

import { Player, BigPlayButton } from 'video-react';

const moment = require("moment")

class CampaignGest extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            monitorSearch: [],
            loadingSearch: false,
            campaignCodes: [],
            contract: null,
            errors: false,
            error: false,
            form: null,
            mode: "edit",
            monitors: [],
            accordionOpen: null
        };
        document.title = "Gestione Campagna | Pubbli Seller";
    }

    componentDidMount() {
        if (typeof this.props.match.params.id !== "undefined") {
            this.getDetailCampaign();
        } else {
            ContractAPI.detail(this.props.match.params.id_contract).then(contract => {

                CampaignCodeAPI.list().then(campaignCodes => {
                    this.setState({
                        contract: contract,
                        campaignCodes: campaignCodes.map((item) => {return {value: item.code, label: item.code}}),
                        form: {
                            id: null,
                            id_contract_campaign: contract.id,
                            name: "",
                            status: null,
                            date_start: "",
                            date_end: "",
                            path: "",
                            note: "",
                            slots: [],
                        }, loading: false, mode: "add", monitors: []
                    });
                }).catch(err => {
                    this.setState({error: true})
                    console.error(err)
                })


            }).catch(err => {
                this.setState({error: true})
                console.error(err)
            })
        }
    }

    getDetailCampaign() {
        CampaignAPI.list({campaign: this.props.match.params.id}).then((campaign) => {
            console.log(campaign);

            let monitors = [];
            let slots = [];

            for (let i = 0; i < campaign.slots.length; i++) {
                let monitorCheck = false;
                slots.push(campaign.slots[i].id)
                for (let d = 0; d < monitors.length; d++) {
                    if (monitors[d].value === campaign.slots[i].monitor.id)
                        monitorCheck = true;
                }
                if (!monitorCheck)
                    monitors.push({value: campaign.slots[i].monitor.id, label:campaign.slots[i].monitor.name + " - " + campaign.slots[i].monitor.full_address});
            }

            campaign.contract.client = campaign.client;

            CampaignCodeAPI.list().then(campaignCodes => {
                this.setState({
                    contract: campaign.contract,
                    campaignCodes: campaignCodes.map(item => ({value: item.code, label: item.code + " (" + item.n_monitors + " monitors - " + Utils.getValueMoneyLabel(item.deposit) + " + " + Utils.getValueMoneyLabel(item.price_month) + " X " + item.duration + " mesi)"})),
                    form: {
                        id: campaign.id,
                        id_contract_campaign: null,
                        name: campaign.name,
                        status: campaign.status,
                        date_start: campaign.date_start,
                        date_end: campaign.date_end,
                        path: campaign.link,
                        slots: slots,
                        note: campaign.note
                    }, loading: false, mode: "edit", monitors: monitors
                })
            }).catch(err => {
                this.setState({error: true})
                console.error(err)
            })


        }).catch((err) => {
            console.error(err);
            this.setState({error: true})
        })
    }

    changeForm(key, text) {
        const form = this.state.form;
        form[key] = text;
        this.setState({form: form});
    }

    saveCampaign() {
        const self = this;
        let form = this.state.form;

        let error = {};

        if (form.name === '')
            error.name = "Il campo nome è obbligatorio";

        this.setState({errors: error});

        if (Object.keys(error).length === 0) {

            this.setState({loading: true});

            let add = (form.id === null);

            let api = (add) ? CampaignAPI.add : CampaignAPI.edit;

            api(form).then((resAPI) => {
                console.log(resAPI)
                this.setState({loading: false});
                global.SweetAlert.fire({
                    title: (add) ? 'Campagna creata' : 'Campagna modificata',
                    text: (add) ? 'La campagna è stata creata correttamente.' : 'La campagna è stata modificata correttamente.',
                    icon: 'success',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    },
                    confirmButtonText: 'Chiudi',
                    buttonsStyling: false
                }).then(function (result) {
                    if (result.value) {
                        if (add)
                            window.location.replace("/campaign/" + resAPI.id);
                    }
                })
            }).catch(() => {
                this.setState({loading: false});
                global.SweetAlert.fire({
                    title: 'Errore',
                    text: (add) ? 'Si è verificato un errore durante la creazione della campagna' : 'Si è verificato un errore durante la modifica della campagna',
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    }
                });
            })
        }
    }

    getStatusCampaign(status, list) {
        if (!list)
            switch (status) {
                case "ACTIVE":
                    return {
                        value: "ACTIVE",
                        label: <span><i className="ri-checkbox-blank-fill text-success me-1"/> ATTIVA</span>
                    };
                case "WAITING":
                    return {
                        value: "WAITING",
                        label: <span><i className="ri-checkbox-blank-fill text-warning me-1"/> IN ATTESA</span>
                    };
                case "SUSPENDED":
                    return {
                        value: "SUSPENDED",
                        label: <span><i className="ri-checkbox-blank-fill text-danger me-1"/> SOSPESA</span>
                    };
                case "CANCELED":
                    return {
                        value: "CANCELED",
                        label: <span><i className="ri-checkbox-blank-fill text-dark me-1"/> CANCELLATA</span>
                    };
            }

        return [
            {value: "ACTIVE", label: <span><i className="ri-checkbox-blank-fill text-success me-1"/> ATTIVA</span>},
            {value: "WAITING", label: <span><i className="ri-checkbox-blank-fill text-warning me-1"/> IN ATTESA</span>},
            {value: "SUSPENDED", label: <span><i className="ri-checkbox-blank-fill text-danger me-1"/> SOSPESA</span>},
            {value: "CANCELED", label: <span><i className="ri-checkbox-blank-fill text-dark me-1"/> CANCELLATA</span>}
        ]
    }

    selectSlot(slot) {
        if (slot.reserved)
            if (slot.campaign.id !== this.state.form.id)
                return;

        let form = this.state.form;
        let check = false;
        for (let i = 0; i < form.slots.length; i++) {
            if (slot.id === form.slots[i]) {
                check = true;
                form.slots.splice(i, 1);
            }
        }

        if (!check)
            form.slots.push(slot.id)

        this.setState({form: form});
    }

    deleteMonitorSlot(index) {
        let monitors = this.state.monitors;
        monitors.splice(index, 1);
        this.setState({monitors: monitors, accordionOpen: null});
    }

    render() {

        require("video-react/dist/video-react.css");

        const self = this;

        const onSearchMonitor = debounce((search) => {
            if (search === "")
                return;
            this.setState({loadingSearch: true});
            MonitorAPI.list({search: search}).then((list) => {
                let options = [];
                for (let i = 0; i < list.length; i++) {
                    let check = false;
                    for (let d = 0; d < self.state.monitors.length; d++) {
                        if (self.state.monitors[d].id === list[i].id)
                            check = true
                    }
                    if (!check)
                        options.push({value: list[i].id, label: list[i].name + " - " + list[i].full_address})
                }
                self.setState({loadingSearch: false, monitorSearch: options});
            }).catch(() => {
                self.setState({loadingSearch: false});
            });
        }, 500);


        if (this.state.error) {
            return <React.Fragment>
                <div className="page-content">
                    <Alert color="danger">Si è verificato un errore durante il recupero dei dettagli della
                        campagna.</Alert>
                </div>
            </React.Fragment>
        }

        const form = this.state.form;
        const errors = this.state.errors;
        const contract = this.state.contract;

        function getStatusContract(status) {
            switch(status) {
                case "ACTIVE":
                    return <Input className="text-success fw-bolder" value="ATTIVO" disabled />;
                case "WAITING":
                    return <Input className="text-warning fw-bolder" value="IN ATTESA" disabled />;
                case "EXPIRED":
                    return <Input className="text-danger fw-bolder" value="SCADUTO" disabled />;
                default:
                    return null;
            }
        }

        return (
            <React.Fragment>
                {(this.state.loading) ? <Loading/> : <div className="page-content">
                    <Container fluid>
                        <BreadCrumb title={(this.state.mode === "edit") ? "MODIFICA CAMPAGNA" : "CREA CAMPAGNA"}
                                    pageTitle="Pubbli Seller"/>
                        <Button className="btn btn-sm btn-primary m-1" onClick={() => this.props.history.goBack()}><i
                            className="ri-arrow-left-circle-line fs-5 text-white-75 align-middle"/> Torna
                            indietro</Button>
                        <Card>
                            <CardHeader>
                                <h5><i className="ri-file-2-fill align-bottom me-1" style={{margin: 10}}></i> CONTRATTO
                                </h5>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md="6" >
                                        <div className='mb-2'>
                                            <Label className='form-label'>
                                                Cliente
                                            </Label>
                                            <Link to={"/client/" + contract.client.id}><Input type="text" value={contract.client.name} disabled style={{cursor: "pointer"}} /></Link>
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className='mb-2'>
                                            <Label className='form-label'>
                                                Stato
                                            </Label>
                                            {getStatusContract(contract.status)}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6">
                                        <div className='mb-2'>
                                            <Label className='form-label'>
                                                Data firma
                                            </Label>
                                            <Input type="date" value={moment(contract.date_start).format("YYYY-MM-DD")} disabled/>
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className='mb-2'>
                                            <Label className='form-label'>
                                                Data scadenza
                                            </Label>
                                            <Input type="date" value={moment(contract.date_expire).format("YYYY-MM-DD")} disabled/>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardHeader>
                                <h5><i className="ri-advertisement-fill align-bottom me-1"
                                       style={{margin: 10}}></i> CAMPAGNA</h5>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md="6">
                                        <div className='mb-2'>
                                            <Label className='form-label'>
                                                Stato
                                            </Label>
                                            <Select
                                                value={(this.state.form.status !== null) ? this.getStatusCampaign(form.status) : undefined}
                                                placeholder={"Seleziona lo stato della campagna..."}
                                                onChange={(selectedOption, triggeredAction) => {
                                                    self.changeForm("status", selectedOption.value)
                                                }}
                                                options={this.getStatusCampaign(form.status, true)}
                                            />
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className='mb-2'>
                                            <Label className='form-label'>
                                                Codice Campagna
                                            </Label>
                                            <Input type="text" value={this.state.form.name} onChange={(e) => this.changeForm('name', e.target.value)}/>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6">
                                        <div className='mb-2'>
                                            <Label className='form-label'>
                                                Data inizio
                                            </Label>
                                            <Input type="date" value={form.date_start}
                                                   invalid={Boolean(errors.date_start)}
                                                   onChange={(e) => this.changeForm('date_start', e.target.value)}/>
                                            <FormFeedback>{errors.date_start}</FormFeedback>
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className='mb-2'>
                                            <Label className='form-label'>
                                                Data fine
                                            </Label>
                                            <Input type="date" value={form.date_end}
                                                   invalid={Boolean(errors.date_end)}
                                                   onChange={(e) => this.changeForm('date_end', e.target.value)}/>
                                            <FormFeedback>{errors.date_end}</FormFeedback>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardHeader>
                                <h5><i className="ri-tv-2-fill align-bottom me-1"></i> MONITOR</h5>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col className="m-3">
                                        <Select
                                            placeholder={"Cerca monitor..."}
                                            value={null}
                                            onChange={(selectedOption, triggeredAction) => {
                                                let monitors = self.state.monitors;
                                                monitors.push(selectedOption);
                                                self.setState({
                                                    monitors: monitors,
                                                    accordionOpen: monitors.length
                                                });
                                            }}
                                            onInputChange={(search) => onSearchMonitor(search)}
                                            options={this.state.monitorSearch}
                                            isLoading={this.state.loadingSearch}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Accordion open={this.state.accordionOpen}>
                                            {this.state.monitors.map((monitor, i) => {
                                                return <AccordionItem key={i}>
                                                    <h2 className="accordion-header">
                                                        <button
                                                            className={"accordion-button"} type="button"
                                                            onClick={() => this.setState({accordionOpen: (this.state.accordionOpen === i) ? null : i})}
                                                            style={{cursor: "pointer"}}>
                                                            <i className="mdi mdi-trash-can text-danger"
                                                               style={{fontSize: "20px"}}
                                                               onClick={() => this.deleteMonitorSlot(i)}/>&nbsp;&nbsp;{monitor.label}
                                                        </button>
                                                    </h2>

                                                    <Collapse isOpen={(this.state.accordionOpen === i)}
                                                              className="accordion-collapse">
                                                        <div className="accordion-body">
                                                            <ListSlot id_monitor_slot={monitor.value}
                                                                      selected={form.slots} getVariable
                                                                      onResolve={(slot) => self.selectSlot(slot)}/>
                                                        </div>
                                                    </Collapse>
                                                </AccordionItem>
                                            })}
                                        </Accordion>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardHeader>
                                <h5><i className="ri-video-fill align-bottom me-1"></i> MATERIALE</h5>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md="12">
                                        <div className='mb-2'>
                                            <Label className='form-label'>
                                                Note
                                            </Label>
                                            <Input type="textarea" rows={5} value={form.note} onChange={(e) => this.changeForm('note', e.target.value)} />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <div className='mb-2'>
                                            <Label className='form-label'>
                                                URL video
                                            </Label>
                                            <Input type="text" value={form.path}
                                                   invalid={Boolean(errors.path)}
                                                   onChange={(e) => this.changeForm('path', e.target.value)}/>
                                            <FormFeedback>{errors.path}</FormFeedback>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <div className='mb-2'>
                                            <Label className='form-label'>
                                                Anteprima
                                            </Label>
                                            {(form.path !== "" && form.path !== null) ? <Player autoPlay src={form.path}>
                                                <BigPlayButton position="center" hide={form.path === undefined} />
                                            </Player> : null}
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <button
                                    type="button"
                                    className="btn btn-success float-end"
                                    id="create-btn" onClick={() => this.saveCampaign()}>
                                    <i className="ri-save-2-fill align-bottom me-1"></i> SALVA
                                </button>
                            </CardBody>
                        </Card>
                    </Container>
                </div>}
            </React.Fragment>
        );
    }
}

export default withRouter(CampaignGest);
