import React, { Component } from 'react';
import {
    Card,
    CardHeader,
    CardBody,
    Col,
    Row,
    Table,
    Input
} from 'reactstrap';
import {withRouter, Link} from "react-router-dom";

import { GoogleMap, GestUserMonitor, DeleteUserMonitor, Utils } from "custom";

import QRCode from 'qrcode.react';
import domtoimage from 'dom-to-image';


class TabPosition extends Component {

    handleDownloadQR = () => {
        const node = document.getElementById('qrCode');
        node.style.width = '300px';
        node.style.height = '300px';
        domtoimage.toPng(node)
            .then((dataUrl) => {
                const link = document.createElement('a');
                link.download = this.props.monitor.name + ' referral-qr.png';
                link.href = dataUrl;
                link.click();
            })
            .catch((error) => {
                console.error('oops, something went wrong!', error);
            });
    }

    render() {

        const monitor = this.props.monitor;
        const user = Utils.getUserData();
        const referralLink = `${process.env.REACT_APP_FRONTEND_URL}/new-voucher?m=${monitor.id}`;


        return (
            <React.Fragment>
                <Row>
                    <Col xxl={12}>
                        <Card>
                            <CardHeader>
                                <h5 className="card-title mb-5 d-md-none">Posizione</h5>
                            </CardHeader>
                            <CardBody>
                                <div className="table-responsive" style={{padding: 20}}>
                                    <Table className="table-borderless mb-0">
                                        <tbody>
                                        {(monitor.user === null && user.role === "ADMIN") ? <GestUserMonitor id_monitor={monitor.id} onReload={this.props.onReload.bind(this)}/> : ((monitor.user !== null) ? <>
                                            <tr>
                                                <th className="ps-0" scope="row">Referente:</th>
                                                <td>{(user.role === "ADMIN") ? <DeleteUserMonitor id_monitor={monitor.id} onReload={this.props.onReload.bind(this)}/> : null}<strong>{monitor.user.firstname} {monitor.user.lastname}</strong></td>
                                            </tr>
                                            <tr>
                                                <th className="ps-0" scope="row">Telefono:</th>
                                                <td><a href={"tel:" + monitor.user.cell}>{monitor.user.cell}</a></td>
                                            </tr>
                                            <tr>
                                                <th className="ps-0" scope="row">Email:</th>
                                                <td><a href={"tel:" + monitor.user.email}>{monitor.user.email}</a></td>
                                            </tr>
                                        </> : null)}
                                        {(user.role === "ADMIN") ? <tr>
                                            <th className="ps-0" scope="row"></th>
                                            <td className="text-center">
                                                Link referral:<Input type="text" value={referralLink} disabled/><br />
                                                <div id="qrCode">
                                                    <QRCode value={referralLink} size={300}/>
                                                </div>
                                                <button className="btn btn-info" onClick={this.handleDownloadQR}>Download</button>
                                            </td>
                                        </tr> : null}
                                        </tbody>
                                    </Table>
                                </div>
                                <GoogleMap option={{defaultLat: monitor.marker.lat, defaultLon: monitor.marker.lon}} markers={[{lat: monitor.marker.lat, lon: monitor.marker.lon}]}/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default withRouter(TabPosition);
