import React, {Fragment} from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Alert,
    Row,
    Col,
    Label,
    FormFeedback,
    Input,
    FormText
} from 'reactstrap';

import { Utils } from "custom";
import { CampaignCodeAPI } from "api";

const moment = require("moment");

class GestCampaignCode extends React.Component {
    constructor(props) {
        super(props);
        let preset = this.props.preset;

        this.state = {
            form: {
                id: preset?.id || null,
                code: preset?.code || '',
                duration: preset?.duration || '',
                n_monitors: preset?.n_monitors || '',
                ads_frequency: preset?.ads_frequency || '',
                price_month: preset?.price_month || '',
                deposit: preset?.deposit || ''
            },
            loading: false,
            errors: false
        };
    }

    changeForm(key, text) {
        const form = this.state.form;

        if (key === 'duration' || key === 'n_monitors' || key === 'ads_frequency' || key === 'deposit' || key === 'price_month') {
            if (isNaN(text))
                return;
        }

        form[key] = text;
        this.setState({form: form}, () => this.showModal());
    }

    showModal() {
        const self = this;
        let form = this.state.form;
        let errors = this.state.errors;

        global.onSetModal({
            title: (this.props.preset) ? "MODIFICA CONTRATTO" : "CREA CONTRATTO",
            content: <div>
                <Row>
                    <Col md="6">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Codice*
                            </Label>
                            <Input type="text" value={form.code}
                                   invalid={Boolean(errors.code)}
                                   onChange={(e) => this.changeForm('code', e.target.value)}/>
                            <FormFeedback>{errors.code}</FormFeedback>
                        </div>
                    </Col>
                    <Col md="6">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Durata (mesi)*
                            </Label>
                            <Input type="text" value={form.duration}
                                   invalid={Boolean(errors.duration)}
                                   onChange={(e) => this.changeForm('duration', e.target.value)}/>
                            <FormFeedback>{errors.duration}</FormFeedback>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md="6">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Numero monitor*
                            </Label>
                            <Input type="text" value={form.n_monitors}
                                   invalid={Boolean(errors.n_monitors)}
                                   onChange={(e) => this.changeForm('n_monitors', e.target.value)}/>
                            <FormFeedback>{errors.n_monitors}</FormFeedback>
                        </div>
                    </Col>
                    <Col md="6">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Passaggio spot (minuti)*
                            </Label>
                            <Input type="text" value={form.ads_frequency}
                                   invalid={Boolean(errors.ads_frequency)}
                                   onChange={(e) => this.changeForm('ads_frequency', e.target.value)}/>
                            <FormFeedback>{errors.ads_frequency}</FormFeedback>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md="6">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Anticipo (€)*
                            </Label>
                            <Input type="text" value={form.deposit}
                                   invalid={Boolean(errors.deposit)}
                                   onChange={(e) => this.changeForm('deposit', e.target.value)}/>
                            <FormFeedback>{errors.deposit}</FormFeedback>
                        </div>
                    </Col>
                    <Col md="6">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Rata mensile (€)*
                            </Label>
                            <Input type="text" value={form.price_month}
                                   invalid={Boolean(errors.price_month)}
                                   onChange={(e) => this.changeForm('price_month', e.target.value)}/>
                            <FormFeedback>{errors.price_month}</FormFeedback>
                        </div>
                    </Col>
                </Row>
            </div>,
            isOpen: true,
            toggle: false,
            loading: false,
            size: 'lg',
            type: 'info',
            buttons: [
                {label: 'SALVA', color: 'success', onClick: () => this.saveCampaignCode()},
                {
                    label: 'CHIUDI',
                    color: 'danger',
                    onClick: () => this.resetForm()
                }
            ]
        })
    }

    resetForm() {
        let preset = this.props.preset;
        this.setState({
            form: {
                id: preset?.id || null,
                code: preset?.code || '',
                duration: preset?.duration || '',
                n_monitors: preset?.n_monitors || '',
                ads_frequency: preset?.ads_frequency || '',
                price_month: preset?.price_month || '',
                deposit: preset?.deposit || ''
            },
            errors: false
        });
        global.onSetModal({isOpen: false, loading: false, content: ""})
    }

    saveCampaignCode() {
        const self = this;
        let form = this.state.form;

        let error = {};

        if (form.code === '')
            error.code = "Il campo codice è obbligatorio";

        if (form.duration === '')
            error.duration = "Il campo durata è obbligatorio";

        if (form.n_monitors === '')
            error.n_monitors = "Il campo monitor è obbligatorio";

        if (form.ads_frequency === '')
            error.ads_frequency = "Il campo frequenza passaggio è obbligatorio";

        if (form.price_month === '')
            error.price_month = "Il campo rata mensile è obbligatorio";

        if (form.deposit === '')
            error.deposit = "Il campo anticipo è obbligatorio";

        this.setState({errors: error}, () => this.showModal());

        if (Object.keys(error).length === 0) {
            global.onSetModal({loading: true});

            let add = (form.id === null);
            let api = (add) ? CampaignCodeAPI.add : CampaignCodeAPI.edit;

            console.log(form)

            api(form).then(() => {
                global.onSetModal({loading: false});
                global.SweetAlert.fire({
                    title: (add) ? 'Codice Campagna creato' : 'Codice Campagna modificato',
                    text: (add) ? 'Il codice campagna è stato creato correttamente.' : 'Il codice campagna è stato modificato correttamente.',
                    icon: 'success',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    },
                    confirmButtonText: 'Chiudi',
                    buttonsStyling: false
                }).then(function (result) {
                    if (result.value) {
                        self.resetForm();
                        self.props.onResolve();
                    }
                })
            }).catch(() => {
                global.onSetModal({loading: false});
                global.SweetAlert.fire({
                    title: 'Errore',
                    text: (add) ? 'Si è verificato un errore durante la creazione del codice campagna' : 'Si è verificato un errore durante la modifica del codice campagna',
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    }
                });
            })
        }
    }

    render() {
        return (
            <React.Fragment>
                {(this.props.preset) ? <li className="list-inline-item cursor-pointer" onClick={() => this.showModal()}>
                    <i className="ri-pencil-fill fs-20 text-info"></i>
                </li> : <button
                    type="button"
                    className="btn btn-success add-btn"
                    id="create-btn"
                    onClick={() => this.showModal()}>
                    <i className="ri-add-line align-bottom me-1"></i> CREA CODICE CAMPAGNA
                </button>}
            </React.Fragment>
        )
    }
}

export default GestCampaignCode;
