import React, {Fragment} from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Alert,
    Row,
    Col,
    Label,
    FormFeedback,
    Input,
    FormText
} from 'reactstrap';

import { Utils } from "custom";
import { AdminAPI } from "api";

class GestAdmin extends React.Component {
    constructor(props) {
        super(props);
        let preset = this.props.preset;

        this.state = {
            form: {
                id: (preset) ? preset.id : null,
                active: (preset) ? preset.active : null,
                firstname: (preset) ? preset.firstname : "",
                lastname: (preset) ? preset.lastname : "",
                email: (preset) ? preset.email : "",
                cell: (preset) ? preset.cell : "",
                permissions_admin: (preset) ? preset.permissions_admin : []
            },
            loading: false,
            errors: false
        };
    }

    changeForm(key, text) {
        const form = this.state.form;

        if (key==="permissions_admin") {
            if (text.value) {
                form.permissions_admin.push(text.key);
            } else {
                for (let i = 0; i < form.permissions_admin.length; i++) {
                    if (form.permissions_admin[i] === text.key)
                        form.permissions_admin.splice(i, 1);
                }
            }
        } else if (key === 'phone' || key === 'cell') {
            if (isNaN(text) && text[0] !== "+")
                return;

            if (text.length >= 3 && text.indexOf("+") === -1)
                text = "+39" + text;
        }

        form[key] = text;

        this.setState({form: form}, () => this.showModal());
    }

    showModal() {
        let form = this.state.form;
        let errors = this.state.errors;

        global.onSetModal({
            title: (this.props.preset) ? "MODIFICA AMMINISTRATORE" : "CREA AMMINISTRATORE",
            content: <div>
                <Row hidden={(form.id === null)}>
                    <Col md="12">
                        <div className="form-check form-switch form-switch-custom form-switch-primary mb-3">
                            <Input className="form-check-input" disabled={!this.props.isRegistered} type="checkbox" role="switch"  checked={form.active}
                                   onChange={(e) => this.changeForm('active', e.target.checked)}/>
                            <Label className="form-check-label">Attiva / Disattiva Utente</Label>
                        </div>
                        {(!this.props.isRegistered) ? <Alert color="warning" >L'utente deve ancora completare la registrazione</Alert> : null}
                    </Col>
                </Row>
                <Row>
                    <Col md="6">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Nome*
                            </Label>
                            <Input type="text" value={form.firstname}
                                   invalid={Boolean(errors.firstname)}
                                   onChange={(e) => this.changeForm('firstname', e.target.value)}/>
                            <FormFeedback>{errors.firstname}</FormFeedback>
                        </div>
                    </Col>
                    <Col md="6">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Cognome*
                            </Label>
                            <Input type="text" value={form.lastname}
                                   invalid={Boolean(errors.lastname)}
                                   onChange={(e) => this.changeForm('lastname', e.target.value)}/>
                            <FormFeedback>{errors.lastname}</FormFeedback>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Email*
                            </Label>
                            <Input type="email" value={form.email}
                                   invalid={Boolean(errors.email)}
                                   onChange={(e) => this.changeForm('email', e.target.value)}/>
                            <FormFeedback>{errors.email}</FormFeedback>
                        </div>
                    </Col>
                    <Col md="12">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Cellulare*
                            </Label>
                            <Input type="tel" value={form.cell}
                                   invalid={Boolean(errors.cell)}
                                   onChange={(e) => this.changeForm('cell', e.target.value)}/>
                            <FormFeedback>{errors.cell}</FormFeedback>
                            <FormText>
                                Assicurati di digitare correttamente il <u>numero di cellulare</u>, è necessario inserire anche il prefisso internazionale (+39 per Italia)
                            </FormText>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <h5>Permessi</h5>
                        <div className='mb-2'>
                            {Utils.getAlloweedPermissions().map((item, i) => {
                                return <div className="form-check form-check-outline form-check-primary mb-3" key={i}>
                                    <Input className="form-check-input" type="checkbox" checked={this.state.form.permissions_admin.includes(item.key)} onChange={(e) => this.changeForm("permissions_admin", {key: item.key, value: e.target.checked})}/>
                                    <Label className="form-check-label">
                                        {item.label}
                                    </Label>
                                </div>;
                            })}
                        </div>
                    </Col>
                </Row>
            </div>,
            isOpen: true,
            toggle: false,
            size: 'lg',
            type: 'info',
            buttons: [
                {label: 'SALVA', color: 'success', onClick: () => this.saveAdmin()},
                {
                    label: 'CHIUDI',
                    color: 'danger',
                    onClick: () => this.resetForm()
                }
            ]
        })
    }

    resetForm() {
        let preset = this.props.preset;
        this.setState({
            form: {
                id: (preset) ? preset.id : null,
                active: (preset) ? preset.active : null,
                firstname: (preset) ? preset.firstname : "",
                lastname: (preset) ? preset.lastname : "",
                email: (preset) ? preset.email : "",
                cell: (preset) ? preset.cell : "",
                permissions_admin: (preset) ? preset.permissions_admin : []
            }
        });
        global.onSetModal({isOpen: false, loading: false, content: ""})
    }

    saveAdmin() {
        const self = this;
        let form = this.state.form;
        let error = {};

        if (form.firstname === '')
            error.firstname = "Il campo nome è obbligatorio";

        if (form.lastname === '')
            error.lastname = "Il campo cognome è obbligatorio";

        if (form.cell !== '' && !Utils.isValidCell(form.cell))
            error.cell = "Il cellulare inserito non è corretto";

        if (form.cell === '')
            error.cell = "Il campo cellulare è obbligatorio";

        if (!Utils.isValidEmail(form.email))
            error.email = "La mail inserita non è corretta";

        if (form.email === '')
            error.email = "Il campo email è obbligatorio";

        this.setState({errors: error}, () => this.showModal());

        if (Object.keys(error).length === 0) {
            global.onSetModal({loading: true});

            let add = (form.id === null);
            let api = (add) ? AdminAPI.add : AdminAPI.edit;

            api(form).then(() => {
                global.onSetModal({loading: false});
                global.SweetAlert.fire({
                    title: (add) ? 'Amministratore creato' : 'Amministratore modificato',
                    text: (add) ? 'L\'utente è stato creato correttamente.' : 'L\'utente è stato modificato correttamente.',
                    icon: 'success',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    },
                    confirmButtonText: 'Chiudi',
                    buttonsStyling: false
                }).then(function (result) {
                    if (result.value) {
                        self.resetForm();
                        self.props.onResolve();
                    }
                })
            }).catch((err) => {
                global.onSetModal({loading: false});
                if(err.status === "USER_EXIST") {
                    error.email = "La mail risulta già registrata";
                    this.setState({errors: error}, () => this.showModal())
                    return;
                }
                global.SweetAlert.fire({
                    title: 'Errore',
                    text: (add) ? 'Si è verificato un errore durante la creazione dell\'utente' : 'Si è verificato un errore durante la modifica dell\'utente',
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    }
                });
            })
        }
    }


    render() {
        return (
            <React.Fragment>
                {(this.props.preset) ? ((this.props.preset.id !== Utils.getUserData().id) ? <li className="list-inline-item cursor-pointer" onClick={() => this.showModal()}>
                    <i className="ri-pencil-fill fs-20 text-info"></i>
                </li> : null) : <button
                    type="button"
                    className="btn btn-success add-btn"
                    id="create-btn"
                    onClick={() => this.showModal()}>
                    <i className="ri-add-line align-bottom me-1"></i> CREA AMMINISTRATORE
                </button>}
            </React.Fragment>
        )
    }
}

export default GestAdmin;
