import React, {Fragment} from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Alert,
    Row,
    Col,
    Label,
    FormFeedback,
    Input,
    FormText
} from 'reactstrap';

import { Utils } from "custom";
import Select from "react-select";
import {AgentAPI, ContractAPI} from "api";

const moment = require("moment");

class GestContract extends React.Component {
    constructor(props) {
        super(props);
        let preset = this.props.preset;

        this.state = {
            form: {
                id: preset?.id || null,
                id_client_contract: this.props.id_client_contract,
                id_agent_contract: preset?.id_agent_contract || null,
                value: preset?.value || 0,
                date_start: preset?.date_start ? moment(preset.date_start).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
                date_expire: preset?.date_expire ? moment(preset.date_expire).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
                typology: preset?.typology || "ADVERTISING",
                path: preset?.path || null
            },
            agents: [],
            loading: false,
            errors: false
        };
    }

    changeForm(key, text) {
        const form = this.state.form;

        if (key === 'value') {
            if (isNaN(text))
                return;
        }


        form[key] = text;

        this.setState({form: form}, () => this.showModal());
    }

    getAgents() {

        global.onSetModal({
            content: "",
            loading: true,
            isOpen: true
        })

        AgentAPI.list().then((agents) => {
            let res = [];
            for (let d = 0; d < agents.length; d++) {
                res.push({value: agents[d].id, label: <span>{agents[d].firstname} {agents[d].lastname} {(!agents[d].active) ? <small className="text-black-50">(NON ATTIVO)</small> : null}</span>})
            }

            this.setState({agents: res}, () => this.showModal());
        }).catch(() => {
            this.setState({agents: []}, () => this.showModal());
        })
    }

    showModal() {
        const self = this;
        let form = this.state.form;
        let errors = this.state.errors;

        global.onSetModal({
            title: (this.props.preset) ? "MODIFICA CONTRATTO" : "CREA CONTRATTO",
            content: <div>
                <Row>
                    <Col md="12">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Agente di Vendita
                            </Label>
                            <Select
                                value={this.state.agents[this.state.agents.findIndex((agent) => form.id_agent_contract === agent.value)]}
                                placeholder={"Seleziona l'agente..."}
                                onChange={(selectedOption, triggeredAction) => {
                                    self.changeForm("id_agent_contract", selectedOption.value)
                                }}
                                options={this.state.agents}
                            />
                            {(errors.id_agent_contract) ? <span className="text-danger">La selezione dell'agente è obbligatoria</span> : null}
                        </div>
                    </Col>
                </Row>
                <br />
                <br />
                <Row>
                    <Col md="6">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Tipologia
                            </Label>
                            <Select
                                isDisabled={(this.props.preset)}
                                value={(form.typology === "ADVERTISING") ? {value: "ADVERTISING", label: "PUBBLICITÀ"} : {value: "RENTAL", label: "NOLEGGIO"}}
                                placeholder={"Seleziona la tipologia contratto..."}
                                onChange={(selectedOption, triggeredAction) => {
                                    self.changeForm("typology", selectedOption.value)
                                }}
                                options={[
                                    {value: "ADVERTISING", label: "PUBBLICITÀ"},
                                    {value: "RENTAL", label: "NOLEGGIO"}
                                ]}
                            />
                            {(errors.typology) ? <span className="text-danger">La selezione del contratto è obbligatoria</span> : null}
                        </div>
                    </Col>
                    <Col md="6">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Valore (€)
                            </Label>
                            <Input type="text" value={form.value}
                                   invalid={Boolean(errors.value)}
                                   onChange={(e) => this.changeForm('value', e.target.value)}/>
                            <FormFeedback>{errors.value}</FormFeedback>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md="6">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Data firma
                            </Label>
                            <Input type="date" value={form.date_start}
                                   invalid={Boolean(errors.date_start)}
                                   onChange={(e) => this.changeForm('date_start', e.target.value)}/>
                            <FormFeedback>{errors.date_start}</FormFeedback>
                        </div>
                    </Col>
                    <Col md="6">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Data scadenza
                            </Label>
                            <Input type="date" value={form.date_expire}
                                   invalid={Boolean(errors.date_expire)}
                                   onChange={(e) => this.changeForm('date_expire', e.target.value)}/>
                            <FormFeedback>{errors.date_expire}</FormFeedback>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                URL contratto
                            </Label>
                            <Input type="text" value={form.path}
                                   invalid={Boolean(errors.path)}
                                   onChange={(e) => this.changeForm('path', e.target.value)}/>
                            <FormFeedback>{errors.path}</FormFeedback>
                        </div>
                    </Col>
                </Row>
            </div>,
            isOpen: true,
            toggle: false,
            loading: false,
            size: 'lg',
            type: 'info',
            buttons: [
                {label: 'SALVA', color: 'success', onClick: () => this.saveContract()},
                {
                    label: 'CHIUDI',
                    color: 'danger',
                    onClick: () => this.resetForm()
                }
            ]
        })
    }

    resetForm() {
        let preset = this.props.preset;
        this.setState({
            form: {
                id: preset?.id || null,
                id_client_contract: this.props.id_client_contract,
                id_agent_contract: preset?.id_agent_contract || null,
                value: preset?.value || 0,
                date_start: preset?.date_start ? moment(preset.date_start).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
                date_expire: preset?.date_expire ? moment(preset.date_expire).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
                typology: preset?.typology || "ADVERTISING",
                path: preset?.path || null
            },
            errors: false
        });
        global.onSetModal({isOpen: false, loading: false, content: ""})
    }

    saveContract() {
        const self = this;
        let form = this.state.form;

        let error = {};

        if (form.value === '')
            error.value = "Il campo valore è obbligatorio";

        if (form.date_start === '')
            error.date_start = "Il campo data firma è obbligatoria";

        if (form.date_expire === '')
            error.date_expire = "Il campo data scadenza è obbligatoria";

        if (form.typology === null)
            error.typology = "Il campo tipologia è obbligatorio";

        if (form.path !== null && form.path !== "" && !Utils.isValidUrl(form.path))
            error.path = "Il campo url non è valido";

        if (form.date_start !== '' && form.date_expire !== '' && moment(form.date_start).isAfter(form.date_expire))
            error.date_start = "La data firma non può essere futura alla data di scadenza";

        this.setState({errors: error}, () => this.showModal());

        if (Object.keys(error).length === 0) {
            global.onSetModal({loading: true});

            let add = (form.id === null);
            let api = (add) ? ContractAPI.add : ContractAPI.edit;

            console.log(form)

            api(form).then(() => {
                global.onSetModal({loading: false});
                global.SweetAlert.fire({
                    title: (add) ? 'Contratto creato' : 'Contratto modificato',
                    text: (add) ? 'Il contratto è stato creato correttamente.' : 'Il contratto è stato modificato correttamente.',
                    icon: 'success',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    },
                    confirmButtonText: 'Chiudi',
                    buttonsStyling: false
                }).then(function (result) {
                    if (result.value) {
                        self.resetForm();
                        self.props.onResolve();
                    }
                })
            }).catch(() => {
                global.onSetModal({loading: false});
                global.SweetAlert.fire({
                    title: 'Errore',
                    text: (add) ? 'Si è verificato un errore durante la creazione del contratto' : 'Si è verificato un errore durante la modifica del contratto',
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    }
                });
            })
        }
    }

    render() {
        return (
            <React.Fragment>
                {(this.props.preset) ? <li className="list-inline-item cursor-pointer" onClick={() => this.getAgents()}>
                    <i className="ri-pencil-fill fs-20 text-info"></i>
                </li> : <button
                    type="button"
                    className="btn btn-success add-btn"
                    id="create-btn"
                    onClick={() => this.getAgents()}>
                    <i className="ri-add-line align-bottom me-1"></i> CREA CONTRATTO
                </button>}
            </React.Fragment>
        )
    }
}

export default GestContract;
