import React, { Component } from 'react';
import {Alert, Badge, Col, Container, Row} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import {withRouter, Link} from "react-router-dom";
import { Grid, _ } from 'gridjs-react';

import {GestAgent, DeleteAgent, Loading, Utils} from "custom";

import { AgentAPI, UserAPI } from "api";

const moment = require("moment");

class AgentTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            agents: [],
            loading: true,
            error: false
        };
    }

    componentDidMount() {
        this.getAgents();
    }

    getAgents() {
        AgentAPI.list().then((agents) => {
            for (let d = 0; d < agents.length; d++) {
                let sales = 0;
                for (let c = 0; c < agents[d].contracts.length; c++) {
                    if (agents[d].contracts[c].value !== null && moment(agents[d].contracts[c].date_start).format("YYYY") === moment().format("YYYY"))
                        sales = sales + agents[d].contracts[c].value;
                }
                agents[d].sales = sales;
            }
            this.setState({loading: false, error: false, agents: agents});
        }).catch((e) => {
            console.error(e)
            this.setState({error: true});
        })
    }

    resendEmailSignup(id) {
        const self = this;
        global.SweetAlert.fire({
            title: 'Vuoi reinviare la mail di registrazione?',
            text: "Stai per reinviare una mail all'utente con il link per la registrazione, vuoi procedere?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, conferma',
            cancelButtonText: 'Annulla',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ms-1'
            },
            buttonsStyling: false,
            showLoaderOnConfirm: true,
            preConfirm: function() {
                return new Promise(function(resolve, reject) {
                    UserAPI.resendSignup(id).then(() => {
                        resolve();
                    }).catch(() => {
                        global.SweetAlert.fire({
                            icon: 'error',
                            title: 'Si è verificato un errore :(',
                            text: 'Purtroppo qualcosa è andato storto e non è stato possibile inviare nuovamente il codice di registrazione. Riprova più tardi.',
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        });
                        reject()
                    })
                });
            },
        }).then(function (result) {
            if (result.value) {
                global.SweetAlert.fire({
                    icon: 'success',
                    title: 'Email registrazione inviata!',
                    text: 'È stata inviata la mail di registrazione all\'utente.',
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                });
                self.getAdministrators();
            }
        })
    }


    render() {
        if (this.state.error) {
            return <React.Fragment>
                <Alert color="danger" >Si è verificato un errore durante il recupero della lista agenti.</Alert>
            </React.Fragment>
        }

        return (
            <React.Fragment>
                <div className="flex-shrink-0" style={{margin: 10}}>
                    <GestAgent onResolve={this.getAgents.bind(this)}/>
                </div>
                {(this.state.loading) ? <Loading /> : <Grid
                    data={this.state.agents}
                    columns={[
                        {
                            id: 'id',
                            hidden: true
                        },
                        {
                            id: 'vat_id',
                            hidden: true
                        },
                        {
                            id: 'firstname',
                            hidden: true
                        },
                        {
                            id: 'lastname',
                            name: 'Nominativo',
                            formatter: (cell, row) => _(<span className="text-nowrap"><a href="javascript:void(0)" onClick={() => this.props.history.push("/agent/" + row.cells[0].data)}><b>{cell} {row.cells[2].data}</b></a></span>)
                        },
                        {
                            id: 'email',
                            name: 'Email',
                            formatter: (cell, row) => _(<span className="text-nowrap"><a href={"mailto:" + cell}>{cell}</a></span>)
                        },
                        {
                            id: 'cell',
                            name: 'Telefono',
                            sort: false,
                            formatter: (cell, row) => _(<span className="text-nowrap"><a href={"tel:" + cell}>{cell}</a></span>)
                        },
                        {
                            id: 'isRegistered',
                            hidden: true
                        },
                        {
                            id: 'active',
                            name: 'Stato',
                            formatter: (cell, row) => _(<span className="text-nowrap">
                                {(cell) ? <Badge color="success" >Attivo</Badge> : ((row.cells[6].data) ? <Badge color="danger" >Non Attivo</Badge> : <Badge color="warning" className="cursor-pointer" onClick={() => this.resendEmailSignup(row.cells[0].data)}>In attesa registrazione</Badge>)}
                            </span>)
                        },
                        {
                            id: 'bank_iban',
                            hidden: true
                        },
                        {
                            id: 'contracts',
                            name: 'Contratti',
                            formatter: (cell, row) => _(<span className="text-nowrap">
                                {cell.length}
                            </span>)
                        },
                        {
                            id: 'sales',
                            name: ('Fatturato ' + moment().format("YYYY")),
                            formatter: (cell, row) => _(<span className="text-nowrap">
                                {Utils.getValueMoneyLabel(cell)}
                            </span>)
                        },
                        {
                            id: 'id',
                            name: '',
                            sort: false,
                            formatter: (cell, row, i) => _(<ul
                                className="list-inline hstack gap-2 mb-0">
                                <GestAgent isRegistered={row.cells[6].data} onResolve={this.getAgents.bind(this)} preset={{id: row.cells[0].data, vat_id: row.cells[1].data, firstname: row.cells[2].data, lastname: row.cells[3].data, email: row.cells[4].data, cell: row.cells[5].data, active: row.cells[7].data, bank_iban: row.cells[8].data}} />
                                <DeleteAgent user={{id: row.cells[0].data}} onResolve={this.getAgents.bind(this)} />
                            </ul>)
                        }
                    ]}
                    style={{table: {'white-space': 'nowrap'}}}
                    resizable={true}
                    search={true}
                    sort={true}
                    pagination={{ enabled: true, limit: 10}}
                    language={{
                        'search': {
                            'placeholder': '🔍 Cerca...'
                        },
                        'pagination': {
                            'previous': 'Indietro',
                            'next': 'Avanti',
                            'showing': 'Stai visualizzando da',
                            'results': 'Agenti',
                            'to': 'a',
                            'of': 'di',
                        },
                        'noRecordsFound': 'Nessun agente trovato',
                        'error': 'Si è verificato un errore non previsto',
                    }}
                />}
            </React.Fragment>
        );
    }
}

export default withRouter(AgentTable);
