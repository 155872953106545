import React, { Component } from 'react';
import {Alert, Badge, Card, CardHeader, CardBody, Col, Container, Row, Nav, NavItem, NavLink, TabPane, TabContent, Table} from 'reactstrap';
import {withRouter, Link} from "react-router-dom";

import {ListContract} from "custom";


class TabContract extends Component {

    render() {

        const client = this.props.client;

        return (
            <React.Fragment>
                <Row>
                    <Col xxl={12}>
                        <Card>
                            <CardHeader>
                                <h5 className="card-title mb-5 d-md-none">Contratti</h5>
                            </CardHeader>
                            <CardBody>
                                <ListContract id_client_contract={client.id} history={this.props.history}/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default withRouter(TabContract);
