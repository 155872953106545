let list = function() {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.campaignsCodes, {
            headers: {'x-access-token': hash},
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('campaign code list', responseJson);
                if (responseJson.status === "LIST_CAMPAIGN_CODE_OK") {
                    resolve(responseJson.list);
                } else {
                    reject(responseJson);
                }
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};


let add = function(campaignsCodes) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.campaignsCodes, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'PUT',
            body: JSON.stringify({
                code: campaignsCodes.code,
                duration: campaignsCodes.duration,
                n_monitors: campaignsCodes.n_monitors,
                ads_frequency: campaignsCodes.ads_frequency,
                price_month: campaignsCodes.price_month,
                deposit: campaignsCodes.deposit
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('campaign code add', responseJson);
                if (responseJson.status === "CAMPAIGN_CODE_CREATED")
                    resolve(responseJson.campaignCode);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let edit = function(campaignsCodes) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.campaignsCodes, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                id: campaignsCodes.id,
                code: campaignsCodes.code,
                duration: campaignsCodes.duration,
                n_monitors: campaignsCodes.n_monitors,
                ads_frequency: campaignsCodes.ads_frequency,
                price_month: campaignsCodes.price_month,
                deposit: campaignsCodes.deposit
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                 global.checkAuth(responseJson.status);
                console.log('campaign edit', responseJson);
                if (responseJson.status === "CAMPAIGN_CODE_EDITED")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let remove = function(id) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.campaignsCodes, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'DELETE',
            body: JSON.stringify({
                id: id
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('campaign code delete', responseJson);
                if (responseJson.status === "CAMPAIGN_CODE_DELETED")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

const CampaignCodeAPI = {
    list: list,
    add: add,
    edit: edit,
    remove: remove
};

module.exports = CampaignCodeAPI;
