import React, { Component } from 'react';
import {Alert, Badge, Card, CardHeader, CardBody, Col, Container, Row, Nav, NavItem, NavLink, TabPane, TabContent, Table} from 'reactstrap';
import {withRouter, Link} from "react-router-dom";

import { ListLinkMonitor, GestLinkMonitor } from "custom";


class TabLink extends Component {

    render() {

        const monitor = this.props.monitor;

        return (
            <React.Fragment>
                <Row>
                    <Col xxl={12}>
                        <Card>
                            <CardHeader>
                                <h5 className="card-title mb-5 d-md-none">Link</h5>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col>
                                        <ListLinkMonitor id_link_monitor={monitor.id} links={monitor.links} onResolve={this.props.onReload.bind(this)}/>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default withRouter(TabLink);
