import React, {Component} from 'react';
import {Alert, Badge, Col, Container, Row, Table} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import {withRouter, Link} from "react-router-dom";

import {Loading, Utils, GestAppointment, DeleteAppointment} from "custom";

import {AppointmentAPI} from "api";

import 'moment/locale/it';

const moment = require("moment");


class AppointmentTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            appointments: [],
            appointmentsHolder: [],
            loading: true,
            error: false
        };
    }

    componentDidMount() {
        this.getAppointments();
    }

    getAppointments() {
        AppointmentAPI.list().then((appointments) => {
            const appointmentsByDay = [];
            appointments.forEach((appointment) => {
                const date = new Date(appointment.date_time).toISOString().slice(0, 10);
                const dayIndex = appointmentsByDay.findIndex((day) => day.date === date);
                if (dayIndex === -1) {
                    appointmentsByDay.push({date, appointments: [appointment]});
                } else {
                    appointmentsByDay[dayIndex].appointments.push(appointment);
                }
            });

            let result = [];
            for (let i = 0; i < appointmentsByDay.length; i++) {
                if (moment(appointmentsByDay[i].date, "YYYY-MM-DD").isAfter(moment().subtract(1, "days")))
                    result.push(appointmentsByDay[i]);
            }

            this.setState({loading: false, error: false, appointments: result, appointmentsHolder: appointmentsByDay});
        }).catch(() => {
            this.setState({error: true});
        })
    }

    getPastAppointments() {
        let appointmentsHolder = this.state.appointmentsHolder;
        let appointments = this.state.appointments;

        let elementsPast = 5;
        let firstElement = appointments[0];

        for (let i = appointmentsHolder.length - 1; i >= 0; i--) {
            if (moment(appointmentsHolder[i].date, "YYYY-MM-DD").isBefore(moment(firstElement.date, "YYYY-MM-DD")) && elementsPast !== 0) {
                appointments.unshift(appointmentsHolder[i]);
                elementsPast--
            }
        }

        this.setState({appointments: appointments});
    }

    render() {

        const self = this;

        if (this.state.error) {
            return <React.Fragment>
                <Alert color="danger">Si è verificato un errore durante il recupero della lista appuntamenti.</Alert>
            </React.Fragment>
        }

        return (
            <React.Fragment>
                <div className="flex-shrink-0" style={{margin: 10}}>
                    {(Utils.getUserData().role === "AGENT") ?
                        <GestAppointment onResolve={this.getAppointments.bind(this)}/> : null}
                </div>
                {(this.state.loading) ? <Loading/> : <div>
                    <br/>
                    {(this.state.appointments.length > 0) ?
                        <h6 hidden={this.state.appointments.length === this.state.appointmentsHolder.length}
                            className="text-primary text-secondary text-center cursor-pointer"
                            onClick={() => this.getPastAppointments()}>Visualizza appuntamenti passati</h6> :
                        <h6 className="text-dark text-center">Nessun appuntamento trovato</h6>}
                    {this.state.appointments.map((day, i) => {
                        return <div key={i} style={{marginTop: 50}}>
                            <h4 className="text-primary">{moment(day.date, "YYYY-MM-DD").format("dddd D MMMM YYYY").toUpperCase()}</h4>
                            <Table className="table-responsive">
                                <thead>
                                <tr>
                                    <th>Azioni</th>
                                    <th>Ora</th>
                                    <th>Cliente</th>
                                    <th>Contatti</th>
                                </tr>
                                </thead>
                                <tbody>
                                {day.appointments.map((appointment, d) => {
                                    return <>
                                        <tr key={d}>
                                            <td>
                                                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                                                    <button
                                                        hidden={(appointment.status === "CONVERTED")}
                                                        type="button"
                                                        className="btn btn-success btn-sm"
                                                        onClick={() => this.props.history.replace("/contract/build/campaign?type=appointment&id=" + appointment.id)}>
                                                        <span style={{display: "flex", flexDirection: "row"}}><i className="mdi mdi-check"/>&nbsp;<span className="d-none d-md-block d-lg-block">CONVERTI</span></span>
                                                    </button>
                                                    <br />
                                                    <GestAppointment preset={appointment} onResolve={this.getAppointments.bind(this)}/>
                                                    <br />
                                                    <DeleteAppointment appointment={appointment} onResolve={this.getAppointments.bind(this)}/>
                                                </div>
                                            </td>
                                            <th style={{borderBottomWidth: "0px"}}><h5>{moment(appointment.date_time).format("HH:mm")}</h5></th>
                                            <th style={{borderBottomWidth: "0px"}}><h5>{appointment.name}</h5></th>
                                            <td style={{borderBottomWidth: "0px"}} className="text-nowrap"><a
                                                href={"tel:" + appointment.phone}>{appointment.phone}</a><br/><br/><a
                                                href={"mailto:" + appointment.email}>{appointment.email}</a></td>
                                        </tr>
                                        <tr style={{marginBottom: 100}}>
                                            <td colSpan={4} style={{borderBottomWidth: "0px"}}>
                                                <a href={"https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=" + appointment.Marker.lat + "," + appointment.Marker.lon}
                                                   target={"_blank"}>{appointment.Marker.full_address}</a>
                                                <span><br/>{(appointment.status === "DEAL") ? <Badge color="warning">In
                                                    trattativa</Badge> : ((appointment.status === "CONVERTED") ?
                                                    <Badge color="success">Completato</Badge> : null)}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={4}></td>
                                        </tr>
                                    </>
                                })}
                                </tbody>
                            </Table>
                        </div>;
                    })}
                </div>}
            </React.Fragment>
        );
    }
}

export default withRouter(AppointmentTable);
