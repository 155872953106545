import React, { Component } from 'react';
import {Card, CardBody, CardHeader, Col, Container, Row} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import {withRouter, Link} from "react-router-dom";

import {ListContract, ListDraft} from "custom";

class ContractList extends Component {

    constructor(props) {
        super(props);
        document.title ="Lista Contratti | Pubbli Seller";
    }

    render() {

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <BreadCrumb title="Contratti" pageTitle="Pubbli Seller"/>
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <ListDraft />
                                        <ListContract history={this.props.history}/>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(ContractList);
