import React, {Component} from 'react';
import {
    Alert,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    Input,
    Label, Table
} from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import {withRouter, Link} from "react-router-dom";

import GoogleMap from 'google-map-react';

import {Loading} from "custom";
import PreloadImage from 'react-preload-image'
import loading from '../../../assets/icons/loading.gif';

import {MarkerAPI} from "api";

import moment from 'moment';
import 'moment/locale/it';

import style from "../../Map/styles";


const InfoWindowMonitor = (props) => {
    const {place, marker, self} = props;
    const infoWindowStyle = {
        position: 'relative',
        bottom: 150,
        left: '-45px',
        width: 250,
        backgroundColor: 'white',
        boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
        padding: 10,
        fontSize: 14,
        zIndex: 1,
    };

    let links = [];

    for (let i = 0; i < place.links.length; i++) {
        let idUrl = place.links[i];
        links.push("https://drive.google.com/uc?export=view&id=" + idUrl);
    }

    return (
        <div style={infoWindowStyle}>
            <Row>
                <Col onClick={() => self.setState({show: false})}><i className="float-end mdi mdi-close-thick" style={{fontSize: "22px", marginBottom: "20px"}}/></Col>
            </Row>
            <Row>
                {(links.length > 0) ? <Col sm={12} md={12} lg={12}>
                    <PreloadImage
                        style={{
                            position: 'relative',
                            width: '100%',
                            height: '200px',
                            // backgroundColor: '#222222',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundImage: `url(${loading})`
                        }}
                        alt=""
                        src={links[0]}
                    />
                </Col> : null}
                <Col sm={12} md={12} lg={12}>
                    <br/><br/>
                    <div style={{fontSize: 16}} className="text-primary">
                        <strong>{place.name}</strong>
                    </div>
                    <div style={{fontSize: 15}}>
                        Visitatori giornalieri medi: <strong>{place.medium_lobby}</strong>
                    </div>
                    <div style={{fontSize: 14}}>
                        Disponibilità: <span
                        className={(place.available_slots < 10) ? "text-danger" : "text-success"}>{place.available_slots}</span> /
                        32
                    </div>
                    <div style={{fontSize: 13, color: 'grey'}}>
                        {marker.full_address}
                    </div>
                    <br/>
                    {(place.available_slots > 0) ? <div className="form-check">
                        <Input className="form-check-input" type="checkbox"
                               checked={(self.state.monitorsSelected.includes(place.id))}
                               disabled={((self.state.monitorsSelected.length === self.state.monitorSelectable && !self.state.monitorsSelected.includes(place.id)) || place.available_slots < self.props.minSlot)}
                               onChange={(e) => {self.props.onSelectMonitor(place.id); self.setState({show: false})}}/>
                        <Label className="form-check-label">
                            Seleziona Monitor
                        </Label>
                    </div> : <p className="text-danger">MONITOR PIENO</p>}
                </Col>
            </Row>
        </div>
    );
};

const MarkerPosition = () => {
    const positionIcon = require("../../../assets/icons/marker_position.png");
    return <div style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        width: "1px",
        height: "1px",
        transform: "translate(-50%, -50%)",
        cursor: "pointer",
        zIndex: 100,
    }}>
        <img alt="" style={{transform: "translate(-50%, -50%)"}} src={positionIcon} width={"20px"}/>
    </div>
};

const MarkerMonitor = ({monitor, marker, markerSize, self}) => {

    const monitorIcon = require("../../../assets/icons/marker_monitor.png");
    const monitorSelected = require("../../../assets/icons/marker_contract_expired.png");
    const monitorFullIcon = require("../../../assets/icons/marker_monitor_full.png");

    return <div style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        width: "1px",
        height: "1px",
        transform: "translate(-50%, -50%)",
        cursor: "pointer",
        zIndex: (self.state.show === monitor.id) ? 100 : 15,
    }}>
        <img alt="" onClick={() => self.setState({show: monitor.id})} style={{transform: "translate(-50%, -100%)"}}
             src={(monitor.available_slots === 0) ? monitorFullIcon : ((self.state.monitorsSelected.includes(monitor.id)) ? monitorSelected : monitorIcon)}
             width={markerSize + "px"}/>
        {(self.state.show === monitor.id) ? <InfoWindowMonitor place={monitor} marker={marker} self={self}/> : null}
    </div>
};


class SelectMonitor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            zoom: 18,
            mode: "map",
            monitorSelectable: this.props.monitorSelectable,
            monitorsSelected: this.props.monitorsSelected,
            markers: [],
            error: false,
            show: false
        }
    }

    componentDidMount() {
        let coords = this.props.coords;

        MarkerAPI.listBuildContract(coords).then(markers => {
            console.log(markers);
            this.setState({markers: markers});
        }).catch(err => {
            console.log(err);
            this.setState({error: true});
        })
    }


    render() {

        if (this.state.error) {
            return <React.Fragment>
                <div className="page-content">
                    <Alert color="danger">Si è verificato un errore durante il recupero dei dati.</Alert>
                </div>
            </React.Fragment>
        }

        let remainingMonitors = this.state.monitorSelectable - this.state.monitorsSelected.length;

        return (
            <React.Fragment>

                {(remainingMonitors > 0) ? <Alert color="primary">Seleziona <b>{remainingMonitors}</b> monitor</Alert> :
                    <Alert color="success"><b>Ottimo! Hai selezionato tutti i monitor</b><span className="float-end">
                        <button
                            type="button"
                            className="btn btn-sm btn-success"
                            onClick={() => this.props.onNext()}>
                            <i className="ri-check-fill"></i> Avanti
                        </button>
                    </span></Alert>}

                <Nav pills className="nav nav-pills nav-custom nav-custom-light mb-3">
                    <NavItem>
                        <NavLink style={{cursor: "pointer"}} className={((this.state.mode === "map") ? "active" : null)}
                                 onClick={() => this.setState({mode: "map"})}>
                            Mappa
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink style={{cursor: "pointer"}}
                                 className={((this.state.mode === "list") ? "active" : null)}
                                 onClick={() => this.setState({mode: "list"})}>
                            Elenco
                        </NavLink>
                    </NavItem>
                </Nav>

                <TabContent activeTab={this.state.mode} className="pt-4">
                    <TabPane tabId="map">
                        <div style={{
                            height: '70vh',
                            margin: 0
                        }}>
                            <GoogleMap
                                defaultZoom={16}
                                options={{
                                    panControl: true,
                                    gestureHandling: 'greedy',
                                    keyboardShortcuts: false,
                                    // getClickableIcons: false,
                                    fullscreenControl: true,
                                    styles: style.map
                                }}
                                gestureHandling="greedy"
                                defaultCenter={[Number(this.props.coords.lat), Number(this.props.coords.lon)]}
                                onChange={(e) => this.setState({zoom: e.zoom})}
                                bootstrapURLKeys={{
                                    key: process.env.REACT_APP_GOOGLE_SERVICES_KEY,
                                    libraries: ['visualization'],
                                }}
                            >

                                <MarkerPosition
                                    lat={this.props.coords.lat}
                                    lng={this.props.coords.lon}
                                />

                                {this.state.markers.map((place, i) => (
                                    <MarkerMonitor
                                        key={i}
                                        lat={place.lat}
                                        lng={place.lon}
                                        monitor={place.monitor}
                                        marker={place}
                                        markerSize={this.state.zoom * 5 / 3}
                                        self={this}
                                    />
                                ))}
                            </GoogleMap>
                        </div>

                    </TabPane>
                    <TabPane tabId="list">
                        <Table>
                            <thead>
                            {(this.state.markers.length > 0) ? <tr>
                                <th></th>
                                <th>Nome</th>
                                <th className="text-center">Disponibilità</th>
                                <th className="text-center">Distanza</th>
                            </tr> : <tr>
                                <th>Nessun monitor trovato</th>
                            </tr>}
                            </thead>
                            <tbody>
                            {this.state.markers.map((marker, i) => {
                                return <tr key={i} style={{backgroundColor: (this.state.monitorsSelected.includes(marker.monitor.id)) ? '#b2ccff' : undefined}}>
                                    <td>{(marker.monitor.available_slots > 0) ? <Input className="form-check-input cursor-pointer" disabled={((this.state.monitorsSelected.length === this.state.monitorSelectable && !this.state.monitorsSelected.includes(marker.monitor.id)) || marker.monitor.available_slots < this.props.minSlot)} type="checkbox" checked={(this.state.monitorsSelected.includes(marker.monitor.id))} onChange={(e) => this.props.onSelectMonitor(marker.monitor.id)}/> : null}</td>
                                    <th><span className="text-primary">{marker.monitor.name}</span><br /><small>Visitatori medi giornalieri: {marker.monitor.medium_lobby}</small><br /><small className="text-muted">{marker.full_address}</small></th>
                                    <th className="text-center text-nowrap"><span className={(marker.monitor.available_slots < 10) ? "text-danger" : "text-success"}>{marker.monitor.available_slots}</span> / 32</th>
                                    <td>{marker.distance}</td>
                                </tr>
                            })}
                            </tbody>
                        </Table>
                    </TabPane>
                </TabContent>
            </React.Fragment>
        );
    }
}

export default SelectMonitor
