import React, { Component } from 'react';
import {Alert, Badge, Card, CardHeader, CardBody, Col, Container, Row, Nav, NavItem, NavLink, TabPane, TabContent, Table} from 'reactstrap';
import {withRouter, Link} from "react-router-dom";

import {GoogleMap} from "custom";


class TabPosition extends Component {

    render() {

        const marker = this.props.client.Marker;

        return (
            <React.Fragment>
                <Row>
                    <Col xxl={12}>
                        <Card>
                            <CardHeader>
                                <h5 className="card-title mb-5 d-md-none">Posizione</h5>
                            </CardHeader>
                            <CardBody>
                                <div className="table-responsive" style={{padding: 10}}>
                                    <Table className="table-borderless mb-0">
                                        <tbody>
                                        <tr>
                                            <th className="ps-0" scope="row">Regione:</th>
                                            <td className="text-muted">{marker.region}</td>
                                        </tr>
                                        <tr>
                                            <th className="ps-0" scope="row">Provincia:</th>
                                            <td className="text-muted">{marker.province}</td>
                                        </tr>
                                        <tr>
                                            <th className="ps-0" scope="row">Città:</th>
                                            <td className="text-muted">{marker.city}</td>
                                        </tr>
                                        <tr>
                                            <th className="ps-0" scope="row">CAP:</th>
                                            <td className="text-muted">{marker.cap}</td>
                                        </tr>
                                        <tr>
                                            <th className="ps-0" scope="row">Indirizzo:</th>
                                            <td className="text-muted">{marker.address}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="ps-0" scope="row">Lat / Lon</th>
                                            <td className="text-muted">{marker.lat}, {marker.lon}</td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                </div>
                                <GoogleMap option={{defaultLat: marker.lat, defaultLon: marker.lon}} markers={[{lat: marker.lat, lon: marker.lon}]}/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default withRouter(TabPosition);
