import React, {Component} from 'react';

//import Scss
import './assets/scss/themes.scss';

//imoprt Route
import Route from './Routes';

import {AuthAPI} from "api";

import {Loading, Utils, CustomModal} from "custom";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import {checkVers} from "./CheckBuildService";


global.checkAuth = function (status) {
    if (status === "NO_PERMISSION") {
        window.location.replace('/unauthorized');
    }

    if (status === "UNAUTHORIZED") {
        sessionStorage.removeItem("userData");
        localStorage.removeItem('hash');
        window.location.assign('/login');
    }
};

global.SweetAlert = withReactContent(Swal);

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
    }

    componentDidMount() {
        checkVers().then(() => {
            Utils.checkConnection().then(() => {
                if (["/redirect", "/new-voucher"].includes(window.location.pathname)) {
                    this.setState({loading: false});
                    return;
                }

                AuthAPI.permission().then(() => {
                    this.setState({loading: false});
                }).catch(() => {
                    this.setState({loading: false});
                    if (window.location.pathname !== '/login')
                        window.location.replace('/login');
                });
            }).catch(() => {
                this.setState({loading: false});
                if (window.location.pathname !== '/offline')
                    window.location.replace('/offline');
            })
        }).catch((err) => {
            console.error(err)
        })
    }

    render() {
        return (
            <React.Fragment>
                {(this.state.loading) ? <Loading/> : <Route/>}
                <CustomModal/>
            </React.Fragment>
        );
    }
}

export default App;
