import React, {Fragment} from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert, Row, Col, Label, FormFeedback, Input} from 'reactstrap';

import {DeleteAdmin, DeleteReport, GestAdmin, GestReport, Utils} from "custom";
import {AdminAPI, ContractAPI, ReferentAPI} from "api";
import {_, Grid} from "gridjs-react";

import 'moment/locale/it'
const moment = require("moment")

class ListDraft extends React.Component {
    constructor(props) {
        super(props);
    }


    deleteDraft(id) {
        ContractAPI.removeDraft(id).then(() => {
            global.SweetAlert.fire({
                icon: 'success',
                title: 'Bozza eliminata!',
                customClass: {
                    confirmButton: 'btn btn-success'
                }
            });
            setTimeout(() => {
                window.location.reload();
            }, 1500)
        }).catch(() => {
            global.SweetAlert.fire({
                icon: 'error',
                title: 'Si è verificato un errore :(',
                text: 'Purtroppo qualcosa è andato storto e non è stato possibile eliminare la bozza. Riprova più tardi.',
                customClass: {
                    confirmButton: 'btn btn-success'
                }
            });
        })
    }

    showModal()  {
        const self = this;

        global.onSetModal({
            title: "BOZZE NON COMPLETATE",
            content: <div>
                <Grid
                    data={Utils.getUserData().drafts}
                    columns={[
                        {
                            id: 'id_draft',
                            name: '',
                            sort: false,
                            formatter: (cell, row, i) => _(<ul
                                className="list-inline hstack gap-2 mb-0">
                                <span className="text-nowrap">
                                    <a href={"/draft/" + cell} >
                                        <li className="list-inline-item cursor-pointer">
                                            <i className="ri-draft-fill fs-20 text-primary"></i>
                                        </li>
                                    </a>
                                </span>
                            </ul>)
                        },
                        {
                            id: 'client',
                            name: "Cliente",
                            sort: false,
                            formatter: (cell, row) => _(<div className="text-nowrap">
                                <span className="text-nowrap">{cell}</span>
                            </div>)
                        },
                        {
                            id: 'signed',
                            name: 'Firmato',
                            sort: false,
                            formatter: (cell, row) => _(<div className="text-nowrap">
                                {(cell) ? <i className="ri-check-fill fs-20 text-success"></i> : <i className="ri-close-fill fs-20 text-danger"></i>}
                            </div>)
                        },
                        {
                            id: 'updatedAt',
                            name: "Ultimo aggiornamento",
                            sort: false,
                            formatter: (cell, row) => _(<div className="text-nowrap">
                                <small>{moment(cell).fromNow()}</small>
                            </div>)
                        },
                        {
                            id: 'id',
                            name: '',
                            sort: false,
                            formatter: (cell, row, i) => _(<ul
                                className="list-inline hstack gap-2 mb-0">
                                <li className="list-inline-item cursor-pointer"
                                    onClick={() => this.deleteDraft(cell)}>
                                    <i className="ri-delete-bin-5-fill fs-20 text-danger"></i>
                                </li>
                            </ul>)
                        }
                    ]}
                    style={{table: {'white-space': 'nowrap'}}}
                    resizable={true}
                    sort={false}
                    pagination={{ enabled: true, limit: 10}}
                    language={{
                        'pagination': {
                            'previous': 'Indietro',
                            'next': 'Avanti',
                            'showing': 'Stai visualizzando da',
                            'results': 'Bozze',
                            'to': 'a',
                            'of': 'di',
                        },
                        'noRecordsFound': 'Nessuna bozza trovata',
                        'error': 'Si è verificato un errore non previsto',
                    }}
                />
            </div>,
            isOpen: true,
            toggle: true,
            size: 'xl',
            type: 'info',
            buttons: [
                {
                    label: 'CHIUDI',
                    color: 'primary',
                    onClick: () => global.onSetModal({isOpen: false, content: ""})
                },
            ]
        })
    }


    render() {
        let draftsLength = (Utils.getUserData().role === "AGENT") ? ((typeof Utils.getUserData().drafts !== "undefined") ? Utils.getUserData().drafts.length : 0) : 0;
        return (
            <React.Fragment>
                {(draftsLength > 0) ? <Alert color="warning">Ci sono alcune bozze non completate <button
                    type="button"
                    className="btn btn-warning btn-sm"
                    onClick={() => this.showModal()}>
                    VISUALIZZA BOZZE
                </button>
                </Alert>: null}
            </React.Fragment>
        )
    }
}

export default ListDraft;
