import React, {Fragment} from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Alert,
    Row,
    Col,
    Label,
    FormFeedback,
    Input,
    FormText
} from 'reactstrap';

import { Utils } from "custom";
import {AgentAPI, ReportAPI} from "api";
import Select from "react-select";

const moment = require("moment");

class GestReport extends React.Component {
    constructor(props) {
        super(props);
        let preset = this.props.preset;

        this.state = {
            form: {
                id: preset?.id || null,
                id_agent: preset?.id_agent ||null,
                name: preset?.name || '',
                phone: preset?.phone || '',
                company: preset?.company || ''
            },
            agents: [],
            loading: false,
            errors: false
        };
    }

    componentDidMount() {
        AgentAPI.list().then((agents) => {
            let res = [];
            res.push({value: null, label: "NON ASSEGNATO"});
            for (let d = 0; d < agents.length; d++) {
                res.push({value: agents[d].id, label: <span>{agents[d].firstname} {agents[d].lastname} {(!agents[d].active) ? <small className="text-black-50">(NON ATTIVO)</small> : null}</span>})
            }

            this.setState({agents: res});
        }).catch((err) => {
            console.error(err)
        })
    }

    changeForm(key, text) {
        const form = this.state.form;

        if (key === "phone" && isNaN(text))
            return;

        form[key] = text;

        this.setState({form: form}, () => this.showModal());
    }

    showModal() {
        const self = this;
        let form = this.state.form;
        let errors = this.state.errors;

        let agents = this.state.agents;

        global.onSetModal({
            title: (this.props.preset) ? "MODIFICA SEGNALAZIONE" : "CREA SEGNALAZIONE",
            content: <div>
                <Row>
                    <Col md="12">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Agente di Vendita*
                            </Label>
                            <Select
                                value={agents[agents.findIndex((agent) => form.id_agent === agent.value)]}
                                placeholder={"Seleziona l'agente..."}
                                onChange={(selectedOption, triggeredAction) => {
                                    self.changeForm("id_agent", selectedOption.value)
                                }}
                                options={agents}
                            />
                            {/*{(errors.id_agent_contract) ? <span className="text-danger">La selezione dell'agente è obbligatoria</span> : null}*/}
                        </div>
                    </Col>
                </Row>
                <br />
                <br />
                <Row>
                    <Col md="6">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Nome*
                            </Label>
                            <Input type="text" value={form.name}
                                   invalid={Boolean(errors.name)}
                                   onChange={(e) => this.changeForm('name', e.target.value)}/>
                            <FormFeedback>{errors.name}</FormFeedback>
                        </div>
                    </Col>
                    <Col md="6">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Telefono*
                            </Label>
                            <Input type="tel" value={form.phone}
                                   invalid={Boolean(errors.phone)}
                                   onChange={(e) => this.changeForm('phone', e.target.value)}/>
                            <FormFeedback>{errors.phone}</FormFeedback>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Azienda
                            </Label>
                            <Input type="text" value={form.company}
                                   invalid={Boolean(errors.company)}
                                   onChange={(e) => this.changeForm('company', e.target.value)}/>
                            <FormFeedback>{errors.company}</FormFeedback>
                        </div>
                    </Col>
                </Row>
            </div>,
            isOpen: true,
            toggle: false,
            loading: false,
            size: 'lg',
            type: 'info',
            buttons: [
                {label: 'SALVA', color: 'success', onClick: () => this.saveReport()},
                {
                    label: 'CHIUDI',
                    color: 'danger',
                    onClick: () => this.resetForm()
                }
            ]
        })
    }

    resetForm() {
        let preset = this.props.preset;
        this.setState({
            form: {
                id: preset?.id || null,
                id_agent: preset?.id_agent ||null,
                name: preset?.name || '',
                phone: preset?.phone || '',
                company: preset?.company || ''
            },
            errors: false
        });
        global.onSetModal({isOpen: false, loading: false, content: ""})
    }

    saveReport() {
        const self = this;
        let form = this.state.form;

        let error = {};

        if (form.name === '')
            error.name = "Il campo nome è obbligatorio";

        if (form.phone === '')
            error.phone = "Il campo telefono è obbligatorio";


        this.setState({errors: error}, () => this.showModal());

        if (Object.keys(error).length === 0) {
            global.onSetModal({loading: true});

            let add = (form.id === null);
            let api = (add) ? ReportAPI.add : ReportAPI.edit;

            api(form).then(() => {
                global.onSetModal({loading: false});
                global.SweetAlert.fire({
                    title: (add) ? 'Segnalazione creata' : 'Segnalazione modificata',
                    text: (add) ? 'La segnalazione è stata creata correttamente.' : '\'La segnalazione è stata modificata correttamente.',
                    icon: 'success',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    },
                    confirmButtonText: 'Chiudi',
                    buttonsStyling: false
                }).then(function (result) {
                    if (result.value) {
                        self.resetForm();
                        self.props.onResolve();
                    }
                })
            }).catch(() => {
                global.onSetModal({loading: false});
                global.SweetAlert.fire({
                    title: 'Errore',
                    text: (add) ? 'Si è verificato un errore durante la creazione della segnalazione' : 'Si è verificato un errore durante la modifica della segnalazione',
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    }
                });
            })
        }
    }

    render() {
        return (
            <React.Fragment>
                {(this.props.preset) ? <li className="list-inline-item cursor-pointer" onClick={() => this.showModal()}>
                    <i className="ri-pencil-fill fs-20 text-info"></i>
                </li> : <button
                    type="button"
                    className="btn btn-success add-btn"
                    id="create-btn"
                    onClick={() => this.showModal()}>
                    <i className="ri-add-line align-bottom me-1"></i> CREA SEGNALAZIONE
                </button>}
            </React.Fragment>
        )
    }
}

export default GestReport;
