import React, { Component } from 'react';
import {
    Alert,
    Badge,
    Card,
    CardHeader,
    CardBody,
    Col,
    Container,
    Row,
    Nav,
    NavItem,
    NavLink,
    TabPane,
    TabContent,
    Table,
    Input, Label, FormFeedback, CardFooter, Button
} from 'reactstrap';
import {withRouter, Redirect, Link,} from "react-router-dom";

import {AdminAPI, ClientAPI, ReferentAPI} from "api";

import {Loading} from "custom";
import BreadCrumb from "../../Components/Common/BreadCrumb";

import {GeoForm, GestReferent, Utils} from "custom";
import Select from "react-select";

class ClientGest extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            errors: false,
            form: null,
            mode: "edit",
            referents: []
        };
        document.title ="Gestione Cliente | Pubbli Seller";
    }

    componentDidMount() {
        if (typeof this.props.match.params.id !== "undefined") {
            this.getDetailClient()
        } else {
            this.setState({form: {
                id: null,
                business_name: "",
                name: "",
                vat_id: "",
                phone: "",
                email: "",
                pec: "",
                bank_iban: "",
                fiscal_code: "",
                unique_code: "",
                category: ""
            }, loading: false, marker: null, referents: [], mode: "add"});
        }
    }

    getDetailClient() {
        ClientAPI.detail(this.props.match.params.id).then((client) => {
            this.setState({form: client, marker: client.Marker, loading: false, mode: "edit"})
        }).catch((err) => {
            console.error(err);
            this.setState({error: true})
        })
    }

    changeForm(key, text) {

        if (key === 'phone' || key === 'cell')
            if (isNaN(text) && text[0] !== "+")
                return;

        if (key === 'vat_id')
            if (text.length > 11 || isNaN(text))
                return;

        const form = this.state.form;
        form[key] = text;
        this.setState({form: form});
    }

    addReferents(referent) {
        if (typeof referent === "undefined") {
            global.onSetModal({isOpen: false});
            return;
        }

        let referents = this.state.referents;
        referents.push(referent);
        this.setState({referents: referents}, () => global.onSetModal({isOpen: false}));
    }

    deleteReferent(i) {
        let referents = this.state.referents;
        referents.splice(i, 1);
        this.setState({referents: referents});
    }

    saveClient() {
        const self = this;
        let form = this.state.form;
        let error = {};

        if (form.business_name === '')
            error.business_name = "Il campo ragione sociale è obbligatorio";

        if (form.name === '')
            error.name = "Il campo nome è obbligatorio";

        if (form.email !== '' && form.email !== null && !Utils.isValidEmail(form.email))
            error.email = "La mail inserita non è corretta";

        if (form.pec !== '' && form.pec !== null && !Utils.isValidEmail(form.pec))
            error.pec = "La pec inserita non è corretta";

        if (form.phone !== '' && !Utils.isValidPhone(form.phone))
            error.phone = "Il telefono inserito non è corretto";

        if (form.bank_iban !== '' && form.bank_iban !== null && !Utils.isValidIban(form.bank_iban))
            error.bank_iban = "Il codice iban inserito non è corretto";

        if (form.fiscal_code !== '' && !Utils.isValidFiscalCode(form.fiscal_code))
            error.fiscal_code = "Il codice fiscale non è corretto";

        if (this.state.marker === null)
            error.marker = "Devi specificare prima la posizione";

        this.setState({errors: error});

        if (Object.keys(error).length === 0) {

            this.setState({loading: true});

            form['referents'] = this.state.referents;
            form.marker = this.state.marker;

            let add = (form.id === null);
            let api = (add) ? ClientAPI.add : ClientAPI.edit;

            api(form).then(() => {
                global.onSetModal({loading: false});
                global.SweetAlert.fire({
                    title: (add) ? 'Cliente creato' : 'Cliente modificato',
                    text: (add) ? 'Il cliente è stato creato correttamente.' : 'Il cliente è stato modificato correttamente.',
                    icon: 'success',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    },
                    confirmButtonText: 'Chiudi',
                    buttonsStyling: false
                }).then(function (result) {
                    if (result.value) {
                        (add) ? window.location.replace("/client") : window.location.replace("/client/" + form.id);
                    }
                })
            }).catch(() => {
                this.setState({loading: false});
                global.SweetAlert.fire({
                    title: 'Errore',
                    text: (add) ? 'Si è verificato un errore durante la creazione del cliente' : 'Si è verificato un errore durante la modifica del cliente',
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    }
                });
            })
        }
    }

    render() {

        if (this.state.error) {
            return <React.Fragment>
                <div className="page-content">
                    <Alert color="danger" >Si è verificato un errore durante il recupero dei dettagli del cliente.</Alert>
                </div>
            </React.Fragment>
        }

        const form = this.state.form;
        const errors = this.state.errors;

        const categories = Utils.getCategoriesClient();

        return (
            <React.Fragment>
                {(this.state.loading) ? <Loading /> : <div className="page-content">
                    <Container fluid>
                        <BreadCrumb title={(this.state.mode === "edit") ? "MODIFICA CLIENTE" : "CREA CLIENTE"} pageTitle="Pubbli Seller"/>
                        <Button className="btn btn-sm btn-primary m-1" onClick={() => window.history.go(-1)}><i className="ri-arrow-left-circle-line fs-5 text-white-75 align-middle" /> Torna indietro</Button>
                        <Card>
                            <CardHeader>
                                <h5><i className="ri-user-2-fill align-bottom me-1" style={{margin: 10}}></i> ANAGRAFICA</h5>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md="6">
                                        <div className='mb-2'>
                                            <Label className='form-label'>
                                                Nome*
                                            </Label>
                                            <Input type="text" value={form.name}
                                                   invalid={Boolean(errors.name)}
                                                   onChange={(e) => this.changeForm('name', e.target.value)}/>
                                            <FormFeedback>{errors.name}</FormFeedback>
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className='mb-2'>
                                            <Label className='form-label'>
                                                Ragione sociale*
                                            </Label>
                                            <Input type="text" value={form.business_name}
                                                   invalid={Boolean(errors.business_name)}
                                                   onChange={(e) => this.changeForm('business_name', e.target.value)}/>
                                            <FormFeedback>{errors.business_name}</FormFeedback>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6">
                                        <div className='mb-2'>
                                            <Label className='form-label'>
                                                Telefono
                                            </Label>
                                            <Input type="tel" value={form.phone}
                                                   invalid={Boolean(errors.phone)}
                                                   onChange={(e) => this.changeForm('phone', e.target.value)}/>
                                            <FormFeedback>{errors.phone}</FormFeedback>
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className='mb-2'>
                                            <Label className='form-label'>
                                                Email
                                            </Label>
                                            <Input type="email" value={form.email}
                                                   invalid={Boolean(errors.email)}
                                                   onChange={(e) => this.changeForm('email', e.target.value)}/>
                                            <FormFeedback>{errors.email}</FormFeedback>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6">
                                        <div className='mb-2'>
                                            <Label className='form-label'>
                                                Partita IVA
                                            </Label>
                                            <Input type="number" value={form.vat_id}
                                                   invalid={Boolean(errors.vat_id)}
                                                   onChange={(e) => this.changeForm('vat_id', e.target.value)}/>
                                            <FormFeedback>{errors.vat_id}</FormFeedback>
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className='mb-2'>
                                            <Label className='form-label'>
                                                PEC
                                            </Label>
                                            <Input type="email" value={form.pec}
                                                   invalid={Boolean(errors.pec)}
                                                   onChange={(e) => this.changeForm('pec', e.target.value)}/>
                                            <FormFeedback>{errors.pec}</FormFeedback>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6">
                                        <div className='mb-2'>
                                            <Label className='form-label'>
                                                Codice Fiscale
                                            </Label>
                                            <Input type="text" value={form.fiscal_code}
                                                   invalid={Boolean(errors.fiscal_code)}
                                                   onChange={(e) => this.changeForm('fiscal_code', e.target.value)}/>
                                            <FormFeedback>{errors.fiscal_code}</FormFeedback>
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className='mb-2'>
                                            <Label className='form-label'>
                                                Codice Iban
                                            </Label>
                                            <Input type="text" value={form.bank_iban}
                                                   invalid={Boolean(errors.bank_iban)}
                                                   onChange={(e) => this.changeForm('bank_iban', e.target.value)}/>
                                            <FormFeedback>{errors.bank_iban}</FormFeedback>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6">
                                        <div className='mb-2'>
                                            <Label className='form-label'>
                                                Codice Univoco
                                            </Label>
                                            <Input type="text" value={form.unique_code}
                                                   invalid={Boolean(errors.unique_code)}
                                                   onChange={(e) => this.changeForm('unique_code', e.target.value)}/>
                                            <FormFeedback>{errors.unique_code}</FormFeedback>
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className='mb-2'>
                                            <Label className='form-label'>
                                                Categoria
                                            </Label>
                                            <Select
                                                value={categories[categories.findIndex((category) => form.category === category.value)]}
                                                placeholder={"Seleziona la categoria..."}
                                                onChange={(selectedOption, triggeredAction) => {
                                                    this.changeForm("category", selectedOption.value)
                                                }}
                                                options={categories}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardHeader>
                                <h5><i className="ri-map-2-fill align-bottom me-1"></i> POSIZIONE</h5>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    {(this.state.errors.marker) ? <Alert color="danger">{this.state.errors.marker}</Alert> : null}
                                </Row>
                                <Row>
                                    <Col>
                                        <GeoForm preset={(this.state.mode === "edit") ? this.state.marker : undefined} onResolve={(marker) => this.setState({marker: marker})} errors={errors}/>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        {(this.state.mode === "add") ? <Card>
                            <CardHeader>
                                <h5><i className="ri-phone-fill align-bottom me-1"></i> REFERENTI</h5>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col>
                                        <div className="table-responsive">
                                            <Table className="align-middle table-nowrap mb-0">
                                                <thead>
                                                <tr>
                                                    <th scope="col">Nome</th>
                                                    <th scope="col">Telefono</th>
                                                    <th scope="col">Cellulare</th>
                                                    <th scope="col">Email</th>
                                                    <th scope="col">Ruolo</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {(this.state.referents.length === 0) ? <tr>
                                                    <th colSpan="6" className="text-center">Non sono presenti referenti
                                                    </th>
                                                </tr> : this.state.referents.map((referent, i) => {
                                                    return <tr key={i}>
                                                        <td><Link className="link-danger" onClick={() => this.deleteReferent(i)}><i className="ri-delete-bin-2-fill align-middle"></i></Link> {referent.name}</td>
                                                        <td>{referent.phone}</td>
                                                        <td>{referent.cell}</td>
                                                        <td>{referent.email}</td>
                                                        <td>{referent.role}</td>
                                                    </tr>
                                                })}
                                                </tbody>
                                            </Table>
                                            <div className="text-center" style={{margin: 10}}><GestReferent giveVariable onResolve={(referent) => this.addReferents(referent)} /></div>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card> : null}
                        <Card>
                            <CardBody>
                                <button
                                    type="button"
                                    className="btn btn-success float-end"
                                    id="create-btn" onClick={() => this.saveClient()}>
                                    <i className="ri-save-2-fill align-bottom me-1"></i> SALVA
                                </button>
                            </CardBody>
                        </Card>
                    </Container>
                </div>}
            </React.Fragment>
        );
    }
}

export default withRouter(ClientGest);
