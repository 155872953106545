import React, {Component} from 'react';
import {Card, CardBody, CardHeader, Col, Container, Row, Table, Button} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import {withRouter, Link} from "react-router-dom";

import {ListContract} from "custom";
import {ClientAPI, ReportAPI, AppointmentAPI} from "api";

import Select from "react-select";
import {debounce} from "lodash";

const moment = require("moment");

class ContractBuildPreselect extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            selected: null,
            clients: [],
            reports: [],
            deals: [],
            appointments: []
        }
        document.title = "Nuovo Contratto | Pubbli Seller";
    }

    componentDidMount() {
        ReportAPI.list().then((list) => {
            let reports = [];
            for (let i = 0; i < list.length; i++) {
                if (!list[i].converted)
                    reports.push({value: list[i].id, label: list[i].name + " " + list[i].phone})
            }

            AppointmentAPI.list().then((listA) => {
                let appointments = [];
                let deals = [];

                for (let i = 0; i < listA.length; i++) {
                    if (moment(listA[i].date_time).format("DD/MM/YYYY") === moment().format("DD/MM/YYYY")) {
                        appointments.push(listA[i])
                    }
                }
                for (let i = 0; i < listA.length; i++) {
                    if (listA[i].status === "DEAL") {
                        deals.push({
                            value: listA[i].id,
                            label: listA[i].name + " (IN TRATTATIVA)"
                        });
                    }
                }

                this.setState({loading: false, appointments: appointments, deals: deals, reports: reports});
            }).catch(() => {
                this.setState({loading: false});
            });
        }).catch(() => {
            this.setState({loading: false});
        });
    }

    render() {

        const self = this;

        const onSearchClient = debounce((search) => {
            if (search === "")
                return;
            this.setState({loading: true});
            ClientAPI.list({search: search}).then((list) => {
                let options = [];
                for (let i = 0; i < list.length; i++) {
                    options.push({value: list[i].id, label: list[i].name + " - " + list[i].business_name})
                }
                self.setState({loading: false, clients: options});
            }).catch(() => {
                self.setState({loading: false});
            });
        }, 500);

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <BreadCrumb title="Nuovo Contratto" pageTitle="Pubbli Seller"/>
                        <Row hidden={this.state.appointments.length === 0}>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <h2>Appuntamenti</h2>
                                        <Table>
                                            <tbody>
                                                {this.state.appointments.map((appointment, i) => {
                                                    return <tr key={i}>
                                                        <th><h5>{moment(appointment.date_time).format("HH:mm")}</h5></th>
                                                        <td>{appointment.name}</td>
                                                        <td>
                                                            <Link to={"/contract/build/campaign?type=appointment&id=" + appointment.id}>
                                                                <Button
                                                                    size="sm"
                                                                    type="button"
                                                                    className="btn btn-success">
                                                                    <i className="ri-check-fill align-bottom"></i>
                                                                </Button>
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </Table>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <h2>Già cliente?</h2>
                                        <Row>
                                            <Col className="m-3">
                                                <Select
                                                    placeholder={"Cerca cliente..."}
                                                    onChange={(selectedOption, triggeredAction) => {
                                                        self.setState({
                                                            selected: {
                                                                type: "client",
                                                                id: selectedOption.value,
                                                                name: selectedOption.label
                                                            }
                                                        })
                                                    }}
                                                    value={(self.state.selected !== null) ? ((self.state.selected.type === "client") ? {
                                                        value: self.state.selected.id,
                                                        label: self.state.selected.name
                                                    } : null) : null}
                                                    onInputChange={(search) => onSearchClient(search)}
                                                    options={self.state.clients}
                                                    isLoading={self.state.loading}
                                                />
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <h2>Segnalazione</h2>
                                        <Row>
                                            <Col className="m-3">
                                                <Select
                                                    placeholder={"Seleziona segnalazione..."}
                                                    value={(self.state.selected !== null) ? ((self.state.selected.type === "report") ? {
                                                        value: self.state.selected.id,
                                                        label: self.state.selected.name
                                                    } : null) : null}
                                                    onChange={(selectedOption, triggeredAction) => {
                                                        self.setState({
                                                            selected: {
                                                                type: "report",
                                                                id: selectedOption.value,
                                                                name: selectedOption.label
                                                            }
                                                        })
                                                    }}
                                                    options={self.state.reports}
                                                />
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row hidden={this.state.deals.length === 0}>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <h2>Trattative</h2>
                                        <Row>
                                            <Col className="m-3">
                                                <Select
                                                    placeholder={"Seleziona appuntamento..."}
                                                    value={(self.state.selected !== null) ? ((self.state.selected.type === "appointment") ? {
                                                        value: self.state.selected.id,
                                                        label: self.state.selected.name
                                                    } : null) : null}
                                                    onChange={(selectedOption, triggeredAction) => {
                                                        self.setState({
                                                            selected: {
                                                                type: "appointment",
                                                                id: selectedOption.value,
                                                                name: selectedOption.label
                                                            }
                                                        })
                                                    }}
                                                    options={self.state.deals}
                                                />
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        {(this.state.selected !== null) ? <Row>
                            <Col lg={12}>
                                <div className="text-center flex-row">
                                    <Link to={"/contract/build/campaign?type=" + this.state.selected.type + "&id=" + this.state.selected.id}>
                                        <button
                                            type="button"
                                            className="btn bg-success text-white m-1">
                                            CONFERMA
                                        </button>
                                    </Link>
                                </div>
                            </Col>
                        </Row> : null}
                        <br/>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center flex-row">
                                    <Link to={"/contract/build/campaign"}>
                                        <button
                                            type="button"
                                            className="btn bg-info text-white m-1">
                                            CREA NUOVO CLIENTE
                                        </button>
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(ContractBuildPreselect);
