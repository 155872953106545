import React, {Component} from 'react';
import {Alert, Card, CardBody, CardHeader, Col, Container, FormFeedback, FormText, Input, Label, Row} from 'reactstrap';
import {withRouter, Link} from "react-router-dom";

import {Utils, GeoForm, Loading} from "custom";
import {CampaignCodeAPI, ClientAPI, ReportAPI} from "api";

import Select from "react-select";

class FormClient extends Component {

    constructor(props) {
        super(props);

        let referent = this.props.referent;

        this.state = {
            referent: {
                id: (typeof referent.id !== "undefined") ? referent.id : null,
                name: referent.name,
                email: referent.email,
                phone: referent.phone,
                cell: referent.cell,
                role: referent.role
            },
            errors: false
        };
        document.title = "Nuovo Contratto | Pubbli Seller";
    }


    changeForm(key, text) {
        const referent = this.state.referent;

        if (key === 'phone' || key === 'cell') {
            if (isNaN(text) && text[0] !== "+")
                return;

            if (text.length >= 3 && text.indexOf("+") === -1)
                text = "+39" + text;
        }

        referent[key] = text;
        this.setState({referent: referent});
    }

    next() {
        const referent = this.state.referent;

        let error = {};

        if (referent.name === '')
            error.name = "Il campo nome è obbligatorio";

        if (referent.cell === '')
            error.cell = "Il campo cellulare è obbligatorio";

        if (referent.email !== '' && referent.email !== null && !Utils.isValidEmail(referent.email))
            error.email = "La mail inserita non è corretta";

        if (referent.phone !== '' && referent.phone !== null && !Utils.isValidCell(referent.phone))
            error.phone = "Il cellulare inserito non è corretto";

        if (referent.cell !== '' && !Utils.isValidCell(referent.cell))
            error.cell = "Il cellulare inserito non è corretto";

        this.setState({errors: error});

        if (Object.keys(error).length === 0) {
            this.props.onNext(referent)
        }
    }


    render() {
        let errors = this.state.errors;
        let referent = this.state.referent;

        return (
            <React.Fragment>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                <h2>Referente</h2>
                                <div>
                                    <Row>
                                        <Col md="6">
                                            <div className='mb-2'>
                                                <Label className='form-label'>
                                                    Nome e Cognome*
                                                </Label>
                                                <Input type="text" value={referent.name}
                                                       invalid={Boolean(errors.name)}
                                                       onChange={(e) => this.changeForm('name', e.target.value)}/>
                                                <FormFeedback>{errors.name}</FormFeedback>
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <div className='mb-2'>
                                                <Label className='form-label'>
                                                    Ruolo
                                                </Label>
                                                <Input type="text" value={referent.role}
                                                       invalid={Boolean(errors.role)}
                                                       onChange={(e) => this.changeForm('role', e.target.value)}/>
                                                <FormFeedback>{errors.role}</FormFeedback>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="4">
                                            <div className='mb-2'>
                                                <Label className='form-label'>
                                                    Email
                                                </Label>
                                                <Input type="email" value={referent.email}
                                                       invalid={Boolean(errors.email)}
                                                       onChange={(e) => this.changeForm('email', e.target.value)}/>
                                                <FormFeedback>{errors.email}</FormFeedback>
                                            </div>
                                        </Col>
                                        <Col md="4">
                                            <div className='mb-2'>
                                                <Label className='form-label'>
                                                    Telefono
                                                </Label>
                                                <Input type="tel" value={referent.phone}
                                                       invalid={Boolean(errors.phone)}
                                                       onChange={(e) => this.changeForm('phone', e.target.value)}/>
                                                <FormText>
                                                    Inserire il prefisso internazionale
                                                </FormText>
                                                <FormFeedback>{errors.phone}</FormFeedback>
                                            </div>
                                        </Col>
                                        <Col md="4">
                                            <div className='mb-2'>
                                                <Label className='form-label'>
                                                    Cellulare*
                                                </Label>
                                                <Input type="tel" value={referent.cell}
                                                       invalid={Boolean(errors.cell)}
                                                       onChange={(e) => this.changeForm('cell', e.target.value)}/>
                                                <FormText>
                                                    È necessario il prefisso internazionale (+39 per Italia)
                                                </FormText>
                                                <FormFeedback>{errors.cell}</FormFeedback>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <div className="text-center flex-row">
                            <button type="button" className="btn bg-primary text-white m-1" onClick={() => this.next()}>
                                PROSEGUI
                            </button>
                        </div>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default withRouter(FormClient);
