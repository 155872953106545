let list = function(filter) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');
        let route = "";

        if (typeof filter !== "undefined") {
            route = "?";

            if (typeof filter.search !== "undefined")
                route = route + "search=" + filter.search + "&"

            if (typeof filter.contract !== "undefined")
                route = route + "contract=" + filter.contract + "&"

            if (typeof filter.campaign !== "undefined")
                route = route + "campaign=" + filter.campaign + "&"
        }

        fetch(global.route.campaign + route, {
            headers: {'x-access-token': hash},
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('campaign list/detail', responseJson);
                if (responseJson.status === "LIST_CAMPAIGNS_OK") {
                    resolve(responseJson.list);
                } else if (responseJson.status === "DETAIL_CAMPAIGN_OK") {
                    resolve(responseJson.campaign);
                } else {
                    reject(responseJson);
                }
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};


let add = function(campaign) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.campaign, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'PUT',
            body: JSON.stringify({
                id_contract_campaign: campaign.id_contract_campaign,
                name: campaign.name,
                status: campaign.status,
                date_start: campaign.date_start,
                date_end: campaign.date_end,
                slots: campaign.slots,
                note: campaign.note,
                path: campaign.path
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('campaign add', responseJson);
                if (responseJson.status === "CAMPAIGN_CREATED")
                    resolve(responseJson.campaign);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let edit = function(campaign) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.campaign, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                id: campaign.id,
                name: campaign.name,
                status: campaign.status,
                date_start: campaign.date_start,
                date_end: campaign.date_end,
                slots: campaign.slots,
                note: campaign.note,
                path: campaign.path
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                 global.checkAuth(responseJson.status);
                console.log('campaign edit', responseJson);
                if (responseJson.status === "CAMPAIGN_EDITED")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let remove = function(id) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.campaign, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'DELETE',
            body: JSON.stringify({
                id: id
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('campaign delete', responseJson);
                if (responseJson.status === "CAMPAIGN_DELETED")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

const CampaignAPI = {
    list: list,
    add: add,
    edit: edit,
    remove: remove
};

module.exports = CampaignAPI;
