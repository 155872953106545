import React, { Component } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert } from 'reactstrap';


import {withRouter, Link, Redirect} from "react-router-dom";

import ImageGallery from 'react-image-gallery';


import {Utils, Loading} from "custom";

class Gallery extends Component {

    constructor(props) {
        super(props);
        this.state = {
            photos: [],
            preLoading: false
        };
    }

    componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);
        let photos = [];
        let counter = 1;
        let tag = "photo" + counter;
        let photo = query.get(tag);

        while(photo !== null) {
            photos.push({
                original: "https://drive.google.com/uc?export=view&id=" + photo,
                thumbnail: "https://drive.google.com/uc?export=view&id=" + photo
            });
            counter = counter + 1;
            tag = "photo" + counter;
            photo = query.get(tag);
        }

        // const token = query.get('token')
        console.log(photos)

        this.setState({preLoading: false, photos: photos})
    }


    render() {
        require("react-image-gallery/styles/scss/image-gallery.scss");


        document.title = "Foto | Pubbli Seller";

        return <React.Fragment>
            {(this.state.preLoading) ? <Loading /> : (
                <div style={{backgroundColor: "#000000"}}>
                    <Row className="text-center">
                        <Col md={12} lg={12} xl={12}>
                            <button
                                type="button"
                                className="btn btn-sm btn-info"
                                onClick={() => this.props.history.goBack()} style={{margin: 10}}>
                                <i className="ri-arrow-left-fill me-1"></i> TORNA INDIETRO
                            </button>
                        </Col>
                    </Row>
                    <Row className="text-center">
                        <Col md={12} lg={12} xl={12}>
                            <ImageGallery items={this.state.photos} showPlayButton={false}/>
                        </Col>
                    </Row>
                </div>)}
        </React.Fragment>
    }
}

export default withRouter(Gallery);
