let permission = function () {
    return new Promise(function (resolve, reject) {
        const hash = localStorage.getItem('hash');

        if (hash === null) {
            global.userData = undefined;
            reject();
            return;
        }

        fetch(global.route.permission, {
            headers: {'x-access-token': hash},
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('API permission', responseJson);
                if (typeof responseJson.error !== "undefined") {
                    localStorage.removeItem("hash");
                    sessionStorage.removeItem("userData");
                    reject(responseJson);
                } else {
                    sessionStorage.setItem("userData", JSON.stringify(responseJson));
                    resolve(responseJson);
                }
            })
            .catch((e) => {
                console.error(e.message);
                reject()
            });
    });
};


let login = function (credential) {
    return new Promise(function (resolve, reject) {
        fetch(global.route.login, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({email: credential.email, password: credential.password})
        })
            .then((response) => (response.status !== 500) ? response.json() :reject())
            .then((responseJson) => {
                console.log('API login', responseJson);
                if (responseJson.status === 'LOGIN_OK') {
                    sessionStorage.setItem("userData", JSON.stringify(responseJson.user));
                    localStorage.setItem('hash', responseJson.accessToken);
                    resolve(responseJson.user);
                } else {
                    if (responseJson.status === 'OTP_SENT') {
                        window.location.assign('/redirect?token=' + responseJson.token);
                    } else {
                        localStorage.removeItem('hash');
                        sessionStorage.removeItem("userData");
                        reject(responseJson);
                    }
                }
            })
            .catch((e) => {
                console.error(e.message);
                reject()
            });
    });
};

let changePassword = function (credential) {
    return new Promise(function (resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.credentialEdit, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': hash
            },
            body: JSON.stringify({
                old_password: credential.old_password,
                new_password: credential.new_password
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log("changePassword", responseJson);
                if (responseJson.status === "PASSWORD_CHANGE_OK") {
                    localStorage.setItem('hash', responseJson.accessToken);
                    resolve(responseJson.user);
                } else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject()
            });
    });
};

let checkToken = function (token) {
    return new Promise(function (resolve, reject) {

        fetch(global.route.checkToken + '?token=' + token, {
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                console.log("checkToken", responseJson);
                if (responseJson.status === "TOKEN_OK")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject()
            });
    });
};

let resetPassword = function (email) {
    return new Promise(function (resolve, reject) {
        fetch(global.route.resetPassword, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({email: email})
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                console.log("resetPassword", responseJson);
                if (responseJson.status === "RESET_PASSWORD_SENT") {
                    resolve(responseJson);
                } else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject()
            });
    });
};

let completeResetPassword = function (password, email, token) {
    return new Promise(function (resolve, reject) {

        fetch(global.route.completeResetPassword + '?token=' + token, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email,
                new_password: password
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                console.log("completeResetPassword", responseJson);
                if (responseJson.status === "PASSWORD_RESET_OK") {
                    resolve(responseJson);
                } else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject()
            });
    });
};

let verifyOTP = function (otp, token) {
    return new Promise(function (resolve, reject) {
        fetch(global.route.verifyOTP + "?token=" + token, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({otp: otp})
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                console.log('API verifyOTP', responseJson);
                if (responseJson.status === 'LOGIN_OK') {
                    sessionStorage.setItem("userData", JSON.stringify(responseJson.user));
                    localStorage.setItem('hash', responseJson.accessToken);
                    resolve(responseJson.user);
                } else {
                    localStorage.removeItem('hash');
                    sessionStorage.removeItem("userData");
                    global.userData = undefined;
                    reject(responseJson);
                }
            })
            .catch((e) => {
                console.error(e.message);
                reject()
            });
    });
};

let resendOTP = function (token) {
    return new Promise(function (resolve, reject) {
        fetch(global.route.verifyOTP + "?token=" + token, {
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                console.log('API resendOTP', responseJson);
                if (responseJson.status === 'OTP_SENT')
                    resolve(responseJson);
                else
                    reject();
            })
            .catch((e) => {
                console.error(e.message);
                reject()
            });
    });
};


const AuthAPI = {
    permission: permission,
    login: login,
    changePassword: changePassword,
    checkToken: checkToken,
    resetPassword: resetPassword,
    completeResetPassword: completeResetPassword,
    verifyOTP: verifyOTP,
    resendOTP: resendOTP
};

module.exports = AuthAPI;
