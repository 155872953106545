import React, { Component } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert, Spinner, FormText } from 'reactstrap';

import { Link } from "react-router-dom";
import { UserAPI } from "api";

import {Utils, Loading} from "custom";

const md5 = require("md5");

class RedirectSignupForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            form: {
                firstname: this.props.token.user.firstname,
                lastname: this.props.token.user.lastname,
                email: this.props.token.user.email,
                cell: this.props.token.user.cell,
                vat_id: this.props.token.user.vat_id,
                bank_iban: this.props.token.user.bank_iban,
                password: "",
                rePassword: ""
            },
            role: this.props.token.user.role,
            errors: false,
            loading: false
        };
    }

    onSignupPress() {
        let form = this.state.form;
        let error = {};

        if (form.cell !== "" && !Utils.isValidPhone(form.cell))
            error.cell = "Il numero di telefono non è valido";

        if (form.password.trim() !== "" && !Utils.isValidPassword(form.password))
            error.password = "La password deve essere almeno di 6 caratteri con almeno una lettera maiuscola, una lettera minuscola e un numero";

        if (form.password.trim() === "")
            error.password = "La password non può essere vuota";

        if (form.password !== form.rePassword)
            error.rePassword = "Le password inserite non coincidono";

        if (form.cell === "")
            error.cell = "Il numero di telefono è obbligatorio";

        if (form.firstname === '')
            error.firstname = "Il campo nome è obbligatorio";

        if (form.lastname === '')
            error.lastname = "Il campo cognome è obbligatorio";

        if (this.state.role === "AGENT") {
            if (form.vat_id !== "" && !Utils.isPiva(form.vat_id))
                error.vat_id = "Il numero di partita iva non è valido";

            if (form.bank_iban !== "" && !Utils.isValidIban(form.bank_iban))
                error.bank_iban = "Il codice iban non è valido";
        }

        this.setState({errors: (Object.keys(error).length > 0) ? error : false});

        if (Object.keys(error).length === 0) {
            let body = {
                firstname: form.firstname,
                lastname: form.lastname,
                cell: form.cell,
                email: form.email,
                password: md5(form.password),
                vat_id: (form.role === "AGENT") ? form.vat_id : undefined,
                bank_iban: (form.role === "AGENT") ? form.bank_iban : undefined
            };

            this.setState({loading: true});
            UserAPI.completeSignup(body, this.props.token.string).then(() => {
                this.setState({loading: false});
                global.SweetAlert.fire({
                    title: 'Registrazione completata',
                    text: 'La registrazione è stata completata correttamente, BENVENUTO!',
                    icon: 'success',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    },
                    confirmButtonText: 'Vai a login',
                    buttonsStyling: false
                }).then(function (result) {
                    if (result.value) {
                        window.location.replace('/login');
                    }
                })
            }).catch((response) => {
                    console.log(response);
                    let form = this.state.form;
                    form.password = "";
                    form.rePassword = "";
                    this.setState({loading: false, form: form});

                    global.SweetAlert.fire({
                        title: 'Si è verificato un errore',
                        text: 'Purtroppo si è verificato un errore non previsto, riprova',
                        icon: 'error',
                        allowOutsideClick: false,
                        showCancelButton: false,
                        customClass: {
                            confirmButton: 'btn btn-primary'
                        },
                        confirmButtonText: 'Riprova',
                        buttonsStyling: false
                    })
                })
        }
    }

    changeForm(key, text) {
        const form = this.state.form;
        form[key] = text;
        this.setState({form: form});
    }


    render() {
        document.title = "Registrazione | Pubbli Seller";

        let form = this.state.form;
        let errors = this.state.errors;

        return <React.Fragment>
                    <div className="text-center mt-2">
                        <h4 className="text-primary">Benvenuto {((this.props.token.user.role === "ADMIN") ? <b>AMMINISTRATORE</b> : ((this.props.token.user.role === "AGENT") ? <b>{this.props.token.user.firstname}</b> : ((this.props.token.user.role === "MONITOR") ? <b>{this.props.token.user.firstname}</b> : null)))}!</h4>
                        <h5 className="text-primary">La tua avventura inizia qui 🚀</h5>
                        <p className="text-muted">Verifica la correttezza dei dati e completa la registrazione del tuo profilo.</p>
                    </div>
                    <div className="p-2 mt-4">
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault();
                                this.onSignupPress()
                            }}
                            action="#">

                            <div className="mb-3">
                                <Label htmlFor="email" className="form-label">Email</Label>
                                <Input
                                    name="email"
                                    className="form-control"
                                    type="text"
                                    disabled
                                    value={form.email}
                                />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="firstname" className="form-label">Nome</Label>
                                <Input
                                    name="firstname"
                                    className="form-control"
                                    placeholder="Inserisci il tuo nome"
                                    type="text"
                                    tabIndex={1}
                                    value={form.firstname}
                                    invalid={errors.firstname}
                                    onChange={(text) => this.changeForm("firstname", text.target.value)}
                                />
                                {(errors.firstname) ? <FormFeedback type="invalid">{errors.firstname}</FormFeedback> : null}
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="lastname" className="form-label">Cognome</Label>
                                <Input
                                    name="lastname"
                                    className="form-control"
                                    placeholder="Inserisci il tuo nome"
                                    type="text"
                                    tabIndex={2}
                                    value={form.lastname}
                                    invalid={errors.lastname}
                                    onChange={(text) => this.changeForm("lastname", text.target.value)}
                                />
                                {(errors.lastname) ? <FormFeedback type="invalid">{errors.lastname}</FormFeedback> : null}
                            </div>

                            <div className="mb-3">
                                <Label htmlFor="lastname" className="form-label">Cellulare</Label>
                                <Input
                                    name="cell"
                                    className="form-control"
                                    placeholder="Inserisci il tuo numero di telefono"
                                    type="tel"
                                    tabIndex={3}
                                    value={form.cell}
                                    invalid={errors.cell}
                                    onChange={(text) => this.changeForm("cell", text.target.value)}
                                />
                                {(errors.cell) ? <FormFeedback type="invalid">{errors.cell}</FormFeedback> : <FormText>
                                    Assicurati di digitare correttamente il tuo <u>numero di cellulare</u>, è necessario inserire anche il prefisso internazionale (+39 per Italia)
                                </FormText>}
                            </div>

                            {(this.props.token.user.role === "AGENT") ? <span>
                                <div className="mb-3">
                                    <Label htmlFor="vat_id" className="form-label">Partita IVA</Label>
                                    <Input
                                        name="vat_id"
                                        className="form-control"
                                        placeholder="Inserisci la tuia partita iva"
                                        type="text"
                                        tabIndex={3}
                                        value={form.vat_id}
                                        invalid={errors.vat_id}
                                        onChange={(text) => this.changeForm("vat_id", text.target.value)}
                                    />
                                        {(errors.vat_id) ? <FormFeedback type="invalid">{errors.vat_id}</FormFeedback> : null}
                                </div>

                                <div className="mb-3">
                                    <Label htmlFor="bank_iban" className="form-label">Codice IBAN</Label>
                                    <Input
                                        name="bank_iban"
                                        className="form-control"
                                        placeholder="Inserisci il tuo codice iban"
                                        type="tel"
                                        tabIndex={3}
                                        value={form.bank_iban}
                                        invalid={errors.bank_iban}
                                        onChange={(text) => this.changeForm("bank_iban", text.target.value)}
                                    />
                                    {(errors.bank_iban) ? <FormFeedback type="invalid">{errors.bank_iban}</FormFeedback> : null}
                                </div>
                            </span> : null}

                            <div className="mb-3">
                                <Label htmlFor="password" className="form-label">Password</Label>
                                <Input
                                    name="password"
                                    className="form-control"
                                    placeholder="Digita la tua password"
                                    type="password"
                                    tabIndex={4}
                                    value={form.password}
                                    invalid={errors.password}
                                    onChange={(text) => this.changeForm("password", text.target.value)}
                                />
                                {(errors.password) ? <FormFeedback type="invalid">{errors.password}</FormFeedback> : <FormText>
                                    La password deve essere almeno di 6 caratteri con almeno una lettera maiuscola, una lettera minuscola ed un numero
                                </FormText>}
                            </div>

                            <div className="mb-3">
                                <Label htmlFor="password" className="form-label">Reinserisci password</Label>
                                <Input
                                    name="password"
                                    className="form-control"
                                    placeholder="Ridigita la tua password"
                                    type="password"
                                    tabIndex={5}
                                    value={form.rePassword}
                                    invalid={errors.rePassword}
                                    onChange={(text) => this.changeForm("rePassword", text.target.value)}
                                />
                                {(errors.rePassword) ? <FormFeedback type="invalid">{errors.rePassword}</FormFeedback> : null}
                            </div>
                            <div className="mt-4">
                                <Button tabIndex={6} color="success" className="btn btn-success w-100" type="submit">Completa registrazione {(this.state.loading ? <Spinner size='sm' type='grow' /> : null)}</Button>
                            </div>
                        </Form>
                    </div>

        </React.Fragment>
    }
}

export default RedirectSignupForm;
