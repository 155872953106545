import React, { Component } from 'react';
import {Card, CardBody, Col, Container, Row} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import {withRouter} from "react-router-dom";
import AppointmentTable from "./AppointmentTable";

class AppointmentList extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };
        document.title ="Lista Appuntamenti | Pubbli Seller";
    }

    componentDidMount() {

    }


    render() {

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <BreadCrumb title="Appuntamenti" pageTitle="Pubbli Seller"/>
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <AppointmentTable />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(AppointmentList);
