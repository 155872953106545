let list = function(filter) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.appointment, {
            headers: {'x-access-token': hash},
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('appointment list', responseJson);
                if (responseJson.status === "LIST_APPOINTMENTS_OK") {
                    resolve(responseJson.list);
                } else {
                    reject(responseJson);
                }
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};


let add = function(appointment) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.appointment, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'PUT',
            body: JSON.stringify({
                name: appointment.name,
                phone: appointment.phone,
                email: appointment.email,
                date_time: appointment.date_time,
                marker: appointment.marker
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('appointment add', responseJson);
                if (responseJson.status === "APPOINTMENT_CREATED")
                    resolve(responseJson.appointment);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let edit = function(appointment) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.appointment, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                id: appointment.id,
                name: appointment.name,
                phone: appointment.phone,
                email: appointment.email,
                date_time: appointment.date_time,
                deal: appointment.deal
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                 global.checkAuth(responseJson.status);
                console.log('appointment edit', responseJson);
                if (responseJson.status === "APPOINTMENT_EDITED")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let remove = function(id) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.appointment, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'DELETE',
            body: JSON.stringify({
                id: id
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('appointment delete', responseJson);
                if (responseJson.status === "APPOINTMENT_DELETED")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

const AppointmentAPI = {
    list: list,
    add: add,
    edit: edit,
    remove: remove
};

module.exports = AppointmentAPI;
