import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Utils } from "custom";

const Navdata = () => {
    let permissionsAdmin = Utils.getUserData().permissions_admin;

    let role = Utils.getUserData().role;

    let menuItems = [];

    switch (role) {
        case "ADMIN":
            menuItems = [
                {
                    id: "map",
                    label: "Mappa",
                    icon: "mdi mdi-map",
                    link: "/map",
                    disabled: false
                },
                {
                    label: "Menu",
                    isHeader: true,
                },
                // {
                //     id: "dashboard",
                //     label: "Dashboard",
                //     icon: "mdi mdi-speedometer",
                //     link: "/dashboard"
                // },
                {
                    id: "administrators",
                    label: "Amministratori",
                    icon: "mdi mdi-key",
                    link: "/admin",
                    disabled: !permissionsAdmin.includes("ADMIN")
                },
                {
                    id: "agents",
                    label: "Agenti",
                    icon: "mdi mdi-briefcase",
                    link: "/agent",
                    disabled: !permissionsAdmin.includes("AGENT")
                },
                {
                    id: "monitors",
                    label: "Punti di diffusione",
                    icon: "mdi mdi-monitor",
                    link: "/monitor",
                    disabled: !permissionsAdmin.includes("MONITOR")
                },
                {
                    id: "signals",
                    label: "Segnalazioni",
                    icon: "mdi mdi-head-lightbulb",
                    link: "/report",
                    disabled: !permissionsAdmin.includes("REPORT")
                },
                {
                    id: "tickets",
                    label: "Codici Campagne",
                    icon: "mdi mdi-apps-box",
                    link: "/campaigncode",
                    disabled: !permissionsAdmin.includes("CAMPAIGN")
                },
                {
                    label: "Risultati",
                    isHeader: true,
                },
                {
                    id: "clients",
                    label: "Clienti",
                    icon: "mdi mdi-account-group",
                    link: "/client",
                    disabled: !permissionsAdmin.includes("CLIENT")
                },
                {
                    id: "appointments",
                    label: "Appuntamenti",
                    icon: "mdi mdi-calendar",
                    link: "/appointments",
                    disabled: true //!permissionsAdmin.includes("APPOINTMENT")
                },
                {
                    id: "contracts",
                    label: "Contratti",
                    icon: "mdi mdi-file-document-edit",
                    link: "/contract",
                    disabled: !permissionsAdmin.includes("CONTRACT")
                },
                {
                    id: "campaigns",
                    label: "Campagne",
                    icon: "mdi mdi-bullhorn",
                    link: "/campaign",
                    disabled: !permissionsAdmin.includes("CAMPAIGN")
                },
                {
                    label: "Assistenza",
                    isHeader: true,
                },
                {
                    id: "ticket",
                    label: "Ticket",
                    icon: "mdi mdi-cog-box",
                    link: "/ticket",
                    disabled: true //!permissionsAdmin.includes("TICKET")
                }
            ];
            break;
        case "AGENT":
            menuItems = [
                {
                    id: "map",
                    label: "Mappa",
                    icon: "mdi mdi-map",
                    link: "/map",
                    disabled: false
                },
                {
                    label: "Menu",
                    isHeader: true,
                },
                {
                    id: "monitors",
                    label: "Punti di diffusione",
                    icon: "mdi mdi-monitor",
                    link: "/monitor",
                    disabled: false
                },
                {
                    id: "reports",
                    label: "Segnalazioni",
                    icon: "mdi mdi-head-lightbulb",
                    link: "/report",
                    disabled: false
                },
                {
                    label: "Risultati",
                    isHeader: true,
                },
                {
                    id: "clients",
                    label: "Clienti",
                    icon: "mdi mdi-account-group",
                    link: "/client",
                    disabled: false
                },
                {
                    id: "appointments",
                    label: "Appuntamenti",
                    icon: "mdi mdi-calendar",
                    link: "/appointment",
                    disabled: false
                },
                {
                    id: "contracts",
                    label: "Contratti",
                    icon: "mdi mdi-file-document-edit",
                    link: "/contract",
                    disabled: false
                }
            ];
            break;
        case "MONITOR":
            menuItems = [];
            break;
        default:
            menuItems = [];
            break;
    }

    return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
