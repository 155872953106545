import React, {Component} from 'react';
import {Alert, Card, CardBody, CardHeader, Col, Container, Input, Label, Row} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import {withRouter, Link} from "react-router-dom";

import {Utils, GeoForm, Loading} from "custom";
import {AppointmentAPI, CampaignCodeAPI, ClientAPI, ReportAPI} from "api";

import Select from "react-select";
import {debounce} from "lodash";

class ContractBuildPreselect extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            address:null,
            placeId: null,
            campaignId: null,
            campaignCode: null,
            gift: null,
            campaignCodes: [],
            client: null,
            error: false
        };
        document.title = "Nuovo Contratto | Pubbli Seller";
    }

    async componentDidMount() {

        let search = window.location.search;
        let params = new URLSearchParams(search);
        let type = params.get("type");
        let id = params.get("id");
        let client = null;

        if (type !== null || id !== null) {
            try {
                if (type === "report") {
                    let reports = await ReportAPI.list();
                    let check = false;
                    for (let d = 0; d < reports.length; d++) {
                        if (reports[d].id === Number(id))
                            check = true;
                    }
                    if (!check) {
                        this.setState({error: true});
                        return;
                    }
                } else if (type === "client") {
                    client = await ClientAPI.detail(Number(id));
                } else if (type === "appointment") {
                    let appointments = await AppointmentAPI.list();
                    let check = false;
                    for (let d = 0; d < appointments.length; d++) {
                        if (appointments[d].id === Number(id))
                            check = true;
                    }
                    if (!check) {
                        this.setState({error: true});
                        return;
                    }
                } else {
                    this.setState({error: true});
                    return;
                }
            } catch (e) {
                this.setState({error: true});
                return;
            }
        }

        CampaignCodeAPI.list().then((list) => {
            let options = [];
            for (let i = 0; i < list.length; i++) {
                options.push({value: list[i].id, label: <small>{list[i].code + " (" + list[i].n_monitors + " monitors ogni " + list[i].ads_frequency + " minuti - " + Utils.getValueMoneyLabel(list[i].deposit) + " + " + Utils.getValueMoneyLabel(list[i].price_month) + " X " + list[i].duration + " mesi)"}</small>})
            }
            this.setState({loading: false, campaignCodes: options, client: client});
        }).catch(() => {
            this.setState({loading: false, error: true});
        });
    }

    componentWillMount() {
        console.log(global.draftTemp)
        if (global.draftTemp) {
            let getTemp = {
                placeId: global.draftTemp.marker.placeId,
                address: global.draftTemp.marker.address,
                campaignId: global.draftTemp.campaign.id,
                campaignCode: global.draftTemp.campaign.code,
                gift: global.draftTemp.gift
            }
            this.setState(getTemp)
        }
    }


    getButtonNext() {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let type = params.get("type");
        let id = params.get("id");
        let placeId = this.state.placeId;
        let campaignId = this.state.campaignId;
        let gift = this.state.gift;

        let link = null;

        if (gift !== null && campaignId !== null) {
            if (type === null && placeId !== null) {
                link = "/contract/build/campaign/monitor?campaignId=" + campaignId + "&gift=" + gift + "&placeId=" + placeId;
            } else if (type === "report" && id !== null && placeId !== null) {
                link = "/contract/build/campaign/monitor?campaignId=" + campaignId + "&gift=" + gift + "&placeId=" + placeId + "&type=report&id=" + id;
            }  else if (type === "appointment" && id !== null && placeId !== null) {
                link = "/contract/build/campaign/monitor?campaignId=" + campaignId + "&gift=" + gift + "&placeId=" + placeId + "&type=appointment&id=" + id;
            } else if (type === "client" && id !== null && placeId === null) {
                link = "/contract/build/campaign/monitor?campaignId=" + campaignId + "&gift=" + gift + "&type=client&id=" + id;
            }
        }

        if (link !== null) {
            global.draftTemp = {
                marker: {placeId: this.state.placeId, address: this.state.address},
                campaign: {id: this.state.campaignId, code: this.state.campaignCode},
                gift: this.state.gift
            }

            return <Row>
                <Col lg={12}>
                    <div className="text-center flex-row">
                        <Link to={link}>
                            <button type="button"
                                    className="btn bg-primary text-white m-1">
                                PROSEGUI
                            </button>
                        </Link>
                    </div>
                </Col>
            </Row>;
        }

        return null;
    }


    render() {

        if (this.state.error) {
            return <React.Fragment>
                <div className="page-content">
                    <Alert color="danger" >Si è verificato un errore durante il recupero dei dati.</Alert>
                </div>
            </React.Fragment>
        }

        return (
            <React.Fragment>
                {(this.state.loading) ? <Loading /> : <div className="page-content">
                    <Container fluid>
                        <BreadCrumb title="Nuovo Contratto" pageTitle="Pubbli Seller"/>
                        {(this.state.client === null) ? <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <h2>Indirizzo</h2>
                                        <Row>
                                            <Col className="m-3">
                                                <GeoForm preset={(this.state.address !== null) ? {full_address: this.state.address} : undefined} errors={false} hideDetail onResolve={(res) => this.setState({placeId: res.placeId, address: res.full_address})}/>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row> : null}
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <h2>Codice Campagna</h2>
                                        <Row>
                                            <Col className="m-3">
                                                <Select
                                                    placeholder={(this.state.campaignCode === null) ? "Seleziona campagna..." : this.state.campaignCode}
                                                    onChange={(selectedOption, triggeredAction) => {
                                                        this.setState({campaignId: selectedOption.value, campaignCode: selectedOption.label})
                                                    }}
                                                    options={this.state.campaignCodes}
                                                />
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <h2>Monitor Voucher</h2>
                                        <Row>
                                            <Col className="m-3">
                                                <Input type="number" value={this.state.gift} onChange={(e) => (!isNaN(e.target.value)) ? this.setState({gift: e.target.value}) : {}}/>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        {this.getButtonNext()}
                        <br/>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center flex-row">
                                    <Link to={"/contract/build"} onClick={() => {global.draftTemp = undefined}}>
                                        <a>Indietro</a>
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>}
            </React.Fragment>
        );
    }
}

export default withRouter(ContractBuildPreselect);
