import React, {Fragment, Component} from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Alert,
    Row,
    Col,
    Label,
    FormFeedback,
    Input
} from 'reactstrap';

import Select from "react-select";

import {Utils, GeoForm} from "custom";
import {MonitorAPI} from "api";
import {Link} from "react-router-dom";

class GestMonitor extends Component {
    constructor(props) {
        super(props);
        let preset = this.props.preset;

        this.state = {
            form: {
                id: (preset) ? preset.id : null,
                name: (preset) ? preset.name : "",
                medium_lobby: (preset) ? preset.medium_lobby : "",
                marker: (preset) ? preset.marker : {
                    full_address: "",
                    address: "",
                    lat: "",
                    lon: "",
                    city: "",
                    region: "",
                    province: "",
                    cap: ""
                }
            },
            loading: false,
            errors: false
        };
    }

    resetForm() {
        let preset = this.props.preset;
        this.setState({
            form: {
                id: (preset) ? preset.id : null,
                name: (preset) ? preset.name : "",
                medium_lobby: (preset) ? preset.medium_lobby : "",
                marker: (preset) ? preset.marker : {
                    full_address: "",
                    address: "",
                    lat: "",
                    lon: "",
                    city: "",
                    region: "",
                    province: "",
                    cap: ""
                }
            }
        });
        global.onSetModal({isOpen: false, content: "", loading: false});
    }

    changeForm(key, text) {
        const form = this.state.form;
        form[key] = text;
        this.setState({form: form}, () => this.showModal());
    }

    setMarker(marker) {
        let form = this.state.form;
        form.marker = {
            full_address: marker.full_address,
            address: marker.address,
            lat: marker.lat,
            lon: marker.lon,
            city: marker.city,
            region: marker.region,
            province: marker.province,
            cap: marker.cap
        };
        this.setState({form: form}, () => this.showModal());
    }

    showModal() {
        let form = this.state.form;
        let errors = this.state.errors;

        global.onSetModal({
            title: this.props.label,
            content: <div>
                <Row>
                    <Col md="12">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Nome*
                            </Label>
                            <Input type="text" value={form.name}
                                   invalid={Boolean(errors.name)}
                                   onChange={(e) => this.changeForm('name', e.target.value)}/>
                            <FormFeedback>{errors.name}</FormFeedback>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Ingressi medi*
                            </Label>
                            <Input type="number" value={form.medium_lobby}
                                   invalid={Boolean(errors.medium_lobby)}
                                   onChange={(e) => this.changeForm('medium_lobby', Number(e.target.value))}/>
                            <FormFeedback>{errors.medium_lobby}</FormFeedback>
                        </div>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col md="12">
                        <GeoForm preset={form.marker} onResolve={this.setMarker.bind(this)} errors={errors}/>
                    </Col>
                </Row>
            </div>,
            isOpen: true,
            toggle: false,
            size: 'lg',
            type: 'info',
            buttons: [
                {label: 'SALVA', disabled: (this.state.form.marker.full_address === ""), color: 'success', onClick: () => this.saveMonitor()},
                {
                    label: 'CHIUDI',
                    color: 'danger',
                    onClick: () => this.resetForm()
                },
            ]
        })
    }

    saveMonitor() {
        const self = this;
        let form = this.state.form;
        let error = {};

        if (form.name === '')
            error.name = "Il campo nome è obbligatorio";

        if (form.medium_lobby === '')
            error.medium_lobby = "Il campo ingressi medi è obbligatorio";

        if (form.marker.lat === '')
            error.lat = "Il campo latitudine è obbligatorio";

        if (form.marker.lon === '')
            error.lon = "Il campo longitudine è obbligatorio";

        if (form.marker.city === '')
            error.city = "Il campo città è obbligatorio";

        if (form.marker.region === '')
            error.region = "Il campo regione è obbligatorio";

        if (form.marker.province === '')
            error.province = "Il campo provincia è obbligatorio";

        if (form.marker.cap === '')
            error.cap = "Il campo cap è obbligatorio";

        this.setState({errors: error}, () => this.showModal());

        if (Object.keys(error).length === 0) {
            global.onSetModal({loading: true});

            let add = (form.id === null);
            let api = (add) ? MonitorAPI.add : MonitorAPI.edit;

            api(form).then(() => {
                self.props.onResolve();
                global.SweetAlert.fire({
                    title: (add) ? 'Punto di diffusione creato' : 'Punto di diffusione modificato',
                    text: (add) ? 'Il punto di diffusione è stato creato correttamente.' : 'Il punto di diffusione è stato modificato correttamente.',
                    icon: 'success',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    },
                    confirmButtonText: 'Chiudi',
                    buttonsStyling: false
                }).then(() => {
                    self.resetForm();
                })
            }).catch(() => {
                global.onSetModal({loading: false});
                global.SweetAlert.fire({
                    title: 'Errore',
                    text: (add) ? 'Si è verificato un errore durante la creazione del punto di diffusione' : 'Si è verificato un errore durante la modifica del punto di diffusione',
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    }
                });
            });
        }
    }


    render() {
        return (
            <React.Fragment>
                {(this.props.preset) ? <button
                    type="button"
                    className="btn btn-success edit-btn"
                    onClick={() => this.showModal()}>
                    <i className="ri-edit-2-fill align-bottom me-1"></i> MODIFICA
                </button> : <button
                    type="button"
                    className="btn btn-success add-btn"
                    onClick={() => this.showModal()}>
                    <i className="ri-add-line align-bottom me-1"></i> CREA PUNTO DI DIFFUSIONE
                </button>}
            </React.Fragment>
        )
    }
}

export default GestMonitor;
