import React, {Fragment} from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert, Row, Col, Label, FormFeedback, Input, Spinner} from 'reactstrap';

import Select, {components} from "react-select";

import {Utils} from "custom";
import {GeoAPI, MonitorAPI} from "api";

import { debounce } from "lodash";
import monitorIcon from "../assets/icons/marker_monitor.png";
import monitorFullIcon from "../assets/icons/marker_monitor_full.png";
import contactIcon from "../assets/icons/marker_contact.png";
import dealIcon from "../assets/icons/marker_contact_deal.png";
import contractIcon from "../assets/icons/marker_contract_ads.png";
import contractExpIcon from "../assets/icons/marker_contract_expired.png";

class GeoForm extends React.Component {
    constructor(props) {
        super(props);
        let preset = this.props.preset;

        this.state = {
            loadingGps: false,
            result: {
                full_address: (preset) ? preset.full_address : "",
                address: (preset) ? preset.address : "",
                lat: (preset) ? preset.lat : "",
                lon: (preset) ? preset.lon : "",
                city: (preset) ? preset.city : "",
                region: (preset) ? preset.region : "",
                province: (preset) ? preset.province : "",
                cap: (preset) ? preset.cap : ""
            },
            addressSuggestion: [],
            loading: false,
            error: false
        };
    }

    resetForm() {
        let result = {
            full_address: "",
            address: "",
            lat: "",
            lon: "",
            city: "",
            region: "",
            province: "",
            cap: ""
        };
        this.setState({result: result});
        this.props.onResolve(result);
    }

    changeFormResult(key, text) {
        const form = this.state.result;
        if (key === "cap")
            if (text.length > 5)
                return;

        form[key] = text;
        this.setState({result: form});
        this.props.onResolve(form);
    }

    onSelectAddress(suggestion) {
        this.setState({loading: true});
        GeoAPI.detail(suggestion.value).then((result) => {
            let res = {
                full_address: result.fullAddress,
                address: result.address,
                lat: result.lat,
                lon: result.lon,
                city: result.city,
                region: result.region,
                province: result.province,
                cap: result.cap,
                placeId: suggestion.value
            };

            this.setState({loading: false, error: false, result: res});
            this.props.onResolve(res);
        }).catch(() => {
            this.setState({error: true, loading: false});
        });
    }

    async onUseGPS(manual) {

        const self = this;

        if (manual) {
            const marker = this.state.result;
            GeoAPI.geoGps({lat: Number(marker.lat), lon: Number(marker.lon) }).then((place) => {
                let res = {
                    full_address: place.fullAddress,
                    address: place.address,
                    lat: place.lat,
                    lon: place.lon,
                    city: place.city,
                    region: place.region,
                    province: place.province,
                    cap: place.cap,
                    placeId: place.place_id
                };

                self.setState({loadingGps: false, error: false, addressSuggestion: [], result: res});
                self.props.onResolve(res);
                global.onSetModal({isOpen: false, content: ""})
            }).catch((err) => {
                self.setState({error: true, loadingGps: false});
            })

            return;
        }

        try {
            const permission = await navigator.permissions.query({ name: 'geolocation' });

            if (permission.state === 'prompt' || permission.state === 'denied') {
                alert('È necessario concedere il permesso per accedere alla posizione.');
                return;
            }
        } catch (error) {
            console.error('Error requesting permission:', error);
            return;
        }


        self.setState({loadingGps: true});

        navigator.geolocation.getCurrentPosition(function(position) {
            GeoAPI.geoGps({lat: position.coords.latitude, lon: position.coords.longitude }).then((place) => {
                console.log(place)
                let res = {
                    full_address: place.fullAddress,
                    address: place.address,
                    lat: place.lat,
                    lon: place.lon,
                    city: place.city,
                    region: place.region,
                    province: place.province,
                    cap: place.cap,
                    placeId: place.place_id
                };

                self.setState({loadingGps: false, error: false, addressSuggestion: [], result: res}, () => global.onSetModal({isOpen: false, content: ""}));
                self.props.onResolve(res);
            }).catch((err) => {
                self.setState({error: true, loadingGps: false});
            })
        });
    }

    onOpenModal() {
        const marker = this.state.result;
        let errors = this.props.errors;

        global.onSetModal({
            title: "FILTRI MAPPA",
            content: <div>
                <div className='mb-2'>
                    <Row>
                        <Col md="6">
                            <div className='mb-2'>
                                <Label className='form-label'>
                                    Lat
                                </Label>
                                <Input type="number" value={marker.lat}
                                       onChange={(e) => {this.changeFormResult('lat', e.target.value);this.onOpenModal()}}
                                       invalid={Boolean(errors.lat)}/>
                                <FormFeedback>{errors.lat}</FormFeedback>
                            </div>
                        </Col>
                        <Col md="6">
                            <div className='mb-2'>
                                <Label className='form-label'>
                                    Lon
                                </Label>
                                <Input type="number" value={marker.lon}
                                       onChange={(e) => {this.changeFormResult('lon', e.target.value);this.onOpenModal()}}
                                       invalid={Boolean(errors.lon)}/>
                                <FormFeedback>{errors.lon}</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                    <hr />
                    <div className="text-center">
                        <Button color="dark" outline className="btn btn-ghost-dark" onClick={() => this.onUseGPS(false)}>
                            {(this.state.loadingGps) ? <Spinner animation="border" size="sm"/> : <span><i className="ri-navigation-fill align-middle"></i> Posizione Attuale</span>}
                        </Button>
                    </div>
                </div>
            </div>,
            isOpen: true,
            toggle: false,
            size: 'md',
            type: 'info',
            buttons: [
                {label: 'APPLICA', color: 'success', onClick: () => this.onUseGPS(true)},
                {label: 'CHIUDI', color: 'danger', onClick: () => global.onSetModal({isOpen: false, content: ""})}
            ]
        })
    }

    render() {
        const self = this;
        const marker = this.state.result;
        let errors = this.props.errors;


        let full_address = (typeof this.props.preset !== "undefined") ? this.props.preset.full_address : ((this.state.result.full_address === "") ? "Cerca indirizzo" : this.state.result.full_address);

        const onChangeGeoForm = debounce((search) => {
            if (search === "")
                return;
            this.setState({loading: true});
            GeoAPI.autocomplete(search, "address").then((suggestions) => {
                let options = [];
                for (let i = 0; i < suggestions.length; i++) {
                    options.push({value: suggestions[i].place_id, label: suggestions[i].fullAddress.replace(", ", " ")})
                }
                self.setState({loading: false, error: false, addressSuggestion: options});
            }).catch(() => {
                self.setState({error: true, loading: false});
            });
        }, 1500);


        return (
            <React.Fragment>
                <Row>
                    <Col>
                        <Select
                            placeholder={full_address}
                            onChange={(selectedOption, triggeredAction) => {
                                if (triggeredAction.action === 'clear')
                                    self.setState({addressSuggestion: []}, () => self.resetForm());
                                else
                                    self.onSelectAddress(selectedOption);
                            }}
                            onInputChange={(search) => onChangeGeoForm(search)}
                            options={this.state.addressSuggestion}
                            isLoading={this.state.loading}
                            isClearable={true}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                        <Button color="dark" outline className="btn btn-ghost-dark" onClick={() => this.onOpenModal()}>
                            <span><i className="ri-map-pin-2-fill align-middle"></i> Posizione Manuale</span>
                        </Button>
                    </Col>
                </Row>
                {(this.state.error) ? <Alert color="danger">Si è verificato un errore durante il recupero dell'indirizzo</Alert> : null}
                <br />
                {(!this.props.hideDetail) ? <div>
                    <Row>
                        <Col md="6">
                            <div className='mb-2'>
                                <Label className='form-label'>
                                    Lat
                                </Label>
                                <Input type="number" value={marker.lat}
                                       onChange={(e) => this.changeFormResult('lat', e.target.value)}
                                       invalid={Boolean(errors.lat)}/>
                                <FormFeedback>{errors.lat}</FormFeedback>
                            </div>
                        </Col>
                        <Col md="6">
                            <div className='mb-2'>
                                <Label className='form-label'>
                                    Lon
                                </Label>
                                <Input type="number" value={marker.lon}
                                       onChange={(e) => this.changeFormResult('lon', e.target.value)}
                                       invalid={Boolean(errors.lon)}/>
                                <FormFeedback>{errors.lon}</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <div className='mb-2'>
                                <Label className='form-label'>
                                    Città
                                </Label>
                                <Input type="text" value={marker.city}
                                       onChange={(e) => this.changeFormResult('city', e.target.value)}
                                       invalid={Boolean(errors.city)}/>
                                <FormFeedback>{errors.city}</FormFeedback>
                            </div>
                        </Col>
                        <Col md="6">
                            <div className='mb-2'>
                                <Label className='form-label'>
                                    Provincia
                                </Label>
                                <Input type="text" value={marker.province} maxLength={2}
                                       onChange={(e) => this.changeFormResult('province', e.target.value.toUpperCase())}
                                       invalid={Boolean(errors.province)}/>
                                <FormFeedback>{errors.province}</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <div className='mb-2'>
                                <Label className='form-label'>
                                    Regione
                                </Label>
                                <Input type="text" value={marker.region}
                                       onChange={(e) => this.changeFormResult('region', e.target.value)}
                                       invalid={Boolean(errors.region)}/>
                                <FormFeedback>{errors.region}</FormFeedback>
                            </div>
                        </Col>
                        <Col md="6">
                            <div className='mb-2'>
                                <Label className='form-label'>
                                    CAP
                                </Label>
                                <Input type="number" value={marker.cap}
                                       onChange={(e) => this.changeFormResult('cap', e.target.value)}
                                       invalid={Boolean(errors.cap)}/>
                                <FormFeedback>{errors.cap}</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                </div> : null}
            </React.Fragment>
        )
    }
}

export default GeoForm;
