import React, {Component} from 'react';
import {
    Card,
    CardBody,
    Col,
    Container,
    Input,
    Label,
    Row,
    Button,
    Form,
    FormFeedback,
    Alert,
    FormText, Spinner
} from 'reactstrap';
import ParticlesAuth from "../Authentication/ParticlesAuth";

import {withRouter, Link, Redirect} from "react-router-dom";

import logoLight from "../../assets/images/logo-light.png";

import {AuthAPI} from "api";

import {Utils, Loading} from "custom";


class Homepage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mode: null,
            token: null,
            loading: true
        };
    }

    componentDidMount() {
        if (!Utils.isUserLoggedIn())
            window.location.href="/login";
        else
            this.setState({loading: false})
    }

    onLogout() {
        localStorage.removeItem("hash");
        window.location.href="/login";
    }

    render() {

        let user = Utils.getUserData();

        return <React.Fragment>
            {(this.state.loading) ? <Loading/> : (<ParticlesAuth>
                <div className="auth-page-content">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-5 mb-4 text-white-50">
                                    <div>
                                        <img src={logoLight} alt="" height="180"/>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4">
                                    <CardBody className="p-4">
                                        <div className="text-center mt-2">
                                            <h4 className="text-primary">Benvenuto <b>{user.firstname}</b>!</h4>
                                            <h5 className="text-primary">{user.monitor.name}</h5>
                                        </div>
                                        <div className="p-2 mt-4">
                                            <div className="mt-4">
                                                <Link to={"/new-voucher"}>
                                                    <Button color="success"
                                                            className="btn btn-primary w-100"><i
                                                        className="ri-add-box-fill"></i> Nuovo
                                                        Voucher</Button>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="p-2 mt-4">
                                            <div className="mt-4">
                                                <Link to={"/list-voucher"}>
                                                    <Button color="primary"
                                                            className="btn btn-primary w-100"><i
                                                        className="ri-ticket-2-fill"></i> I miei Voucher</Button>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="p-2 mt-4">
                                            <div className="mt-4">
                                                <Button color="danger"
                                                        className="btn btn-danger w-100" onClick={() => this.onLogout()}><i
                                                    className="ri-logout-box-line"></i> Logout</Button>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>)}
        </React.Fragment>
    }
}

export default withRouter(Homepage);
