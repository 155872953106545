import React, {Fragment} from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Alert,
    Row,
    Col,
    Label,
    FormFeedback,
    Input,
    Badge
} from 'reactstrap';

import {Utils, GestContract, DeleteContract, Loading, ListCampaign, /*ListCampaign*/} from "custom";
import { ContractAPI } from "api";

import {Grid, _} from 'gridjs-react';
import {Link} from "react-router-dom";

import * as XLSX from 'xlsx';

const moment = require("moment")

class ListContract extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contracts: [],
            loading: true,
            error: false
        };
    }

    componentDidMount() {
        this.getContracts();
    }

    getContracts() {
        ContractAPI.list(this.props.id_client_contract).then((contracts) => {
            this.setState({error: false, loading: false, contracts: contracts});
        }).catch(() => {
            this.setState({error: true, loading: false});
        })
    }

    showModalCampaigns(id_contract_campaign) {
        global.onSetModal({
            title: "LISTA CAMPAGNE",
            content: <div>
                <ListCampaign id_contract_campaign={id_contract_campaign} />
            </div>,
            isOpen: true,
            toggle: true,
            size: 'xl',
            type: 'info',
            buttons: [
                {
                    label: 'CHIUDI',
                    color: 'danger',
                    onClick: () => global.onSetModal({isOpen: false})
                },
            ]
        })
    }

    checkVerified(key, id) {
        let contracts = this.state.contracts;

        for (let i = 0; i < contracts.length; i++) {
            if (key === "verified" && contracts[i].id === id && contracts[i].verified)
                return true

            if (key === "contract" && contracts[i].id === id) {
                if (contracts[i].linkContract !== null)
                    return true;
            }
        }

        return false;
    }

    getLink(id) {
        let contracts = this.state.contracts;

        for (let i = 0; i < contracts.length; i++) {
            if (contracts[i].id === id)
                return contracts[i].linkContract
        }
        return "#";
    }

    async exportToExcel() {

        let contracts = [];

        const fileType =
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';

        let data = await ContractAPI.exports();

        for (let i = 0; i < data.length; i++) {
            contracts.push({
                id: data[i].id,
                data_firma: moment(data[i].date_start).format("DD/MM/YYYY"),
                typology: data[i].typology,
                nuovo_cliente: "",
                segnalazione: "",
                cliente: data[i].name,
                indirizzo: data[i].address,
                city: data[i].city,
                provincia: data[i].province,
                nome_titolare: data[i].name_owner,
                telefono_azienda: data[i].phone,
                nome_referente: data[i].name_referent,
                telefono_referente: data[i].tel_referent,
                email_referente: data[i].email_referent,
                agente: data[i].agent,
                codice_campagna: data[i].campaign,
                codice_campagna_2: "",
                durata_mesi: "",
                n_monitor: "",
                n_pass: "",
                imponibile_video: "",
                imponibile_campagna: data[i].value,
                iva22: "",
                totale: "",
                anticipo: "",
                mod_pag: "",
                data_pag: "",
                ammontare_dilazionare: "",
                n_rate: "",
                importo_rata: "",
                modalita_pagam: "",
                settore: data[i].category,
                mese: "",
                anno: "",
                stato: "",
                note: "",
                cod_destinatario: data[i].unique_code,
                partita_iva: data[i].vat_id,
                codice_fiscale: data[i].fiscal_code,
                iban: data[i].bank_iban
            })
        }

        const ws = XLSX.utils.json_to_sheet(contracts);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const dataBlob = new Blob([excelBuffer], { type: fileType });

        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(dataBlob);
        downloadLink.download = "contratti_" + moment().format('DD-MM-YYYY_HH-mm') + fileExtension;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

    render() {
        if (this.state.error) {
            return <React.Fragment>
                <Alert color="danger">Si è verificato un errore durante il recupero della lista contratti.</Alert>
            </React.Fragment>
        }


        return (this.state.loading) ? <Loading /> : <React.Fragment>
            <div style={{margin: 10}}>
                {((Utils.getUserData().role === "AGENT") ? <Link to={"/contract/build"}>
                    <button
                        type="button"
                        className="btn btn-success add-btn">
                        <i className="ri-add-line align-bottom me-1"></i> CREA CONTRATTO
                    </button>
                </Link> : ((typeof this.props.id_client_contract !== "undefined") ? <GestContract id_client_contract={this.props.id_client_contract} onResolve={() => {
                    this.getContracts();
                }}/> : null))}
                {(Utils.getUserData().role === "ADMIN" && typeof this.props.id_client_contract === "undefined") ? <button
                        onClick={() => this.exportToExcel()}
                        type="button"
                        className="btn btn-success add-btn float-end">
                        <i className="ri-file-excel-2-fill align-bottom me-1"></i> ESPORTA XLS
                    </button> : null}


                {/*<ExcelFile filename={"contratti-" + moment().format('DD-MM-YYYY_HH-mm')} element={<Button color="success" size="sm" style={{margin: 10, cursor: 'pointer'}}>*/}
                {/*    <i className="fa fa-lg fa-file-excel-o"/> ESPORTA XLS</Button>}>*/}
                {/*    <ExcelSheet data={this.state.items} name="Contratti">*/}
                {/*        <ExcelColumn label={"ID"} value={"id"}/>*/}
                {/*        <ExcelColumn label="NOME" value={(col) => col.contract.client.nickname}/>*/}
                {/*        <ExcelColumn label="RAGIONE SOCIALE" value={(col) => col.contract.client.company_name}/>*/}
                {/*        <ExcelColumn label="CITTA'" value={(col) => col.address_component.city}/>*/}
                {/*        <ExcelColumn label="INDIRIZZO" value={(col) => col.address_component.route}/>*/}
                {/*        <ExcelColumn label="AGENTE" value={(col) => col.owner.firstname + " " + col.owner.lastname}/>*/}
                {/*        <ExcelColumn label="DATA INIZIO" value={(col) => moment(col.contract.start).format('DD/MM/YYYY')}/>*/}
                {/*        <ExcelColumn label="DATA FINE" value={(col) => moment(col.contract.end).format('DD/MM/YYYY')}/>*/}
                {/*    </ExcelSheet>*/}
                {/*</ExcelFile>*/}


            </div>
            <Grid
                data={this.state.contracts}
                columns={[
                    {
                        id: 'id',
                        name: '#',
                        formatter: (cell, row) => _(<div className="text-nowrap text-center">
                            <li className="list-inline-item cursor-pointer">
                                <a href={this.getLink(cell)} target={"_blank"}>
                                    {(this.checkVerified("contract", cell)) ? <i className="ri-file-2-fill fs-20 text-success"></i> : <i className="ri-file-2-fill fs-20 text-danger"></i>}
                                    {(this.checkVerified("verified", cell)) ? <i className="ri-check-fill fs-20 text-success"></i> : <i className="ri-close-fill fs-20 text-danger"></i>}
                                </a>
                            </li>
                        </div>)
                    },
                    {
                        id: 'typology',
                        name: "Tipologia",
                        formatter: (cell, row) => _(<div className="text-nowrap text-center">
                            {(cell === "ADVERTISING") ? <Badge color="primary" >PUBBLICITÀ</Badge> : <Badge color="info">NOLEGGIO</Badge>}
                        </div>)
                    },
                    {
                        id: 'client',
                        name: "Cliente",
                        hidden: (typeof this.props.id_client_contract !== "undefined"),
                        formatter: (cell, row) => _(<div className="text-nowrap">
                            <span className="text-nowrap"><a href="javascript:void(0)" onClick={() => this.props.history.push("/client/" + cell.id)}><b>{cell.name}</b></a></span>
                        </div>)
                    },
                    {
                        id: 'status',
                        name: "Stato",
                        sort: false,
                        formatter: (cell, row) => _(<div className="text-nowrap text-center">
                            {(cell === "ACTIVE") ? <Badge color="success" >Attivo</Badge> : ((cell === "WAITING") ? <Badge color="warning" >In attesa</Badge> : <Badge color="danger">Scaduto</Badge>)}
                        </div>)
                    },
                    {
                        id: 'value',
                        name: "Valore",
                        formatter: (cell, row) => _(<div className="text-nowrap text-center">
                            <strong>{Utils.getValueMoneyLabel(cell)}</strong>
                        </div>)
                    },
                    {
                        id: 'agent',
                        name: "Agente",
                        hidden: (Utils.getUserData().role !== "ADMIN"),
                        formatter: (cell, row) => _(<div className="text-nowrap">
                            <span className="text-nowrap">{(cell !== null) ? <a href="javascript:void(0)" onClick={() => this.props.history.push("/agent/" + cell.id)}><b>{cell.firstname} {cell.lastname}</b></a> : <b className="text-danger">NON ASSEGNATO</b>}</span>
                        </div>)
                    },
                    {
                        id: 'date_start',
                        name: "Data firma",
                        formatter: (cell, row) => _(
                            <div className="text-nowrap text-center">{moment(cell).format("DD/MM/YYYY")}</div>
                        ),
                    },
                    {
                        id: 'date_expire',
                        name: "Data scadenza",
                        formatter: (cell, row) => _(
                            <div className="text-nowrap text-center">{moment(cell).format("DD/MM/YYYY")}</div>
                        ),
                    },
                    {
                        id: 'campaigns',
                        name: "Campagne",
                        hidden: (Utils.getUserData().role !== "ADMIN"),
                        formatter: (cell, row) => _(
                            (row.cells[1].data === "ADVERTISING") ? <div className="text-nowrap text-center cursor-pointer">{(cell.length > 0) ? <p onClick={() => this.showModalCampaigns(row.cells[0].data)}><strong>{cell[0].name} {(cell.length > 1) ? ("e altre " + (cell.length-1)) : ""}</strong></p> : <button className="btn btn-sm btn-info" onClick={() => this.props.history.push("/campaign/add/" + row.cells[0].data)}><i className="mdi mdi-plus" /></button>}</div> : null
                        ),
                    },
                    {
                        id: 'campaigns',
                        name: "Campagna",
                        hidden: (Utils.getUserData().role !== "AGENT"),
                        formatter: (cell, row) => _(
                            (row.cells[1].data === "ADVERTISING") ? <div className="text-nowrap text-center cursor-pointer"><p><strong>{cell[0].name}</strong></p></div> : null
                        ),
                    },
                    {
                        name: '',
                        sort: false,
                        hidden: (Utils.getUserData().role !== "ADMIN"),
                        formatter: (cell, row, i) => _(<ul
                            className="list-inline hstack gap-2 mb-0">
                            <GestContract id_client_contract={this.props.id_client_contract}
                                  preset={{
                                      id: row.cells[0].data,
                                      typology: row.cells[1].data,
                                      value: row.cells[4].data,
                                      id_agent_contract: (row.cells[5].data !== null) ? row.cells[5].data.id : null,
                                      path: this.getLink(row.cells[0].data),
                                      date_start: row.cells[6].data,
                                      date_expire: row.cells[7].data
                                  }} onResolve={() => {
                                this.getContracts();
                            }}/>
                            <DeleteContract contract={{id: row.cells[0].data}} onResolve={() => {
                                this.getContracts();
                            }}/>
                        </ul>)
                    }
                ]}
                sort={true}
                pagination={{enabled: true, limit: 10}}
                search={{
                    selector: (cell, rowIndex, cellIndex) => ((cellIndex === 8) ? JSON.stringify(cell) : ((cellIndex === 2) ? JSON.stringify(cell) : cell))
                }}
                language={{
                    'search': {
                        'placeholder': '🔍 Cerca...'
                    },
                    'pagination': {
                        'previous': 'Indietro',
                        'next': 'Avanti',
                        'showing': 'Stai visualizzando da',
                        'results': 'Contratti',
                        'to': 'a',
                        'of': 'di',
                    },
                    'noRecordsFound': 'Nessun contratto trovato',
                    'error': 'Si è verificato un errore non previsto',
                }}
            />
        </React.Fragment>
    }
}

export default ListContract;
