import React, { Component } from 'react';
import {
    Alert,
    Card,
    CardHeader,
    CardBody,
    Col,
    Container,
    Row,
    Table,
    Input, Label, FormFeedback, Button, Accordion, AccordionItem, Collapse
} from 'reactstrap';
import {withRouter, Redirect, Link,} from "react-router-dom";

import { CampaignAPI, MonitorAPI } from "api";

import { Loading, ListSlot } from "custom";
import BreadCrumb from "../../Components/Common/BreadCrumb";

import { Utils } from "custom";
import Select from "react-select";
import {debounce} from "lodash";

class ContractGest extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            monitorSearch: [],
            loadingSearch: false,
            errors: false,
            form: null,
            mode: "edit",
            monitors: [],
            accordionOpen: null
        };
        document.title ="Gestione Campagna | Pubbli Seller";
    }

    componentDidMount() {
        if (typeof this.props.match.params.id !== "undefined") {
            this.getDetailCampaign()
        } else {
            this.setState({form: {
                id: null,
                id_contract_campaign: null,
                name: "",
                status: null,
                date_start: "",
                date_end: "",
                slots: []
            }, loading: false, mode: "add", monitors: []});
        }
    }

    getDetailCampaign() {
        CampaignAPI.list({campaign: this.props.match.params.id}).then((campaign) => {

            let monitors = [];
            let slots = [];

            for (let i = 0; i < campaign.slots.length; i++) {
                let monitorCheck = false;
                slots.push(campaign.slots[i].id)
                for (let d = 0; d < monitors.length; d++) {
                    if (monitors[d].id === campaign.slots[i].monitor.id)
                        monitorCheck = true;
                }
                if (!monitorCheck)
                    monitors.push(campaign.slots[i].monitor);
            }

            this.setState({
                form: {
                    id: campaign.id,
                    id_contract_campaign: null,
                    name: campaign.name,
                    status: campaign.status,
                    date_start: campaign.date_start,
                    date_end: campaign.date_end,
                    slots: slots
                }, loading: false, mode: "edit", monitors: monitors
            })
        }).catch((err) => {
            console.error(err);
            this.setState({error: true})
        })
    }

    changeForm(key, text) {
        const form = this.state.form;
        form[key] = text;
        this.setState({form: form});
    }

    saveCampaign() {
        const self = this;
        let form = this.state.form;

        let error = {};

        if (form.name === '')
            error.name = "Il campo nome è obbligatorio";

        this.setState({errors: error});

        if (Object.keys(error).length === 0) {

            this.setState({loading: true});

            let add = (form.id === null);

            let api = (add) ? CampaignAPI.add : CampaignAPI.edit;

            api(form).then(() => {
                this.setState({loading: false});
                global.SweetAlert.fire({
                    title: (add) ? 'Campagna creata' : 'Campagna modificata',
                    text: (add) ? 'La campagna è stata creata correttamente.' : 'La campagna è stata modificata correttamente.',
                    icon: 'success',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    },
                    confirmButtonText: 'Chiudi',
                    buttonsStyling: false
                }).then(function (result) {
                    if (result.value) {
                        if (add)
                            window.location.replace("/campaign" + form.id);
                    }
                })
            }).catch(() => {
                this.setState({loading: false});
                global.SweetAlert.fire({
                    title: 'Errore',
                    text: (add) ? 'Si è verificato un errore durante la creazione della campagna' : 'Si è verificato un errore durante la modifica della campagna',
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    }
                });
            })
        }
    }

    getStatusCampaign(status, list) {
        if (!list)
            switch(status) {
                case "ACTIVE":
                    return {value: "ACTIVE", label: <span><i className="ri-checkbox-blank-fill text-success me-1" /> ATTIVA</span>};
                case "WAITING":
                    return {value: "WAITING", label: <span><i className="ri-checkbox-blank-fill text-warning me-1" /> IN ATTESA</span>};
                case "SUSPENDED":
                    return {value: "SUSPENDED", label: <span><i className="ri-checkbox-blank-fill text-warning me-1" /> SOSPESA</span>};
                case "CANCELED":
                    return {value: "CANCELED", label: <span><i className="ri-checkbox-blank-fill text-danger me-1" /> CANCELLATA</span>};
            }

        return [
            {value: "ACTIVE", label: <span><i className="ri-checkbox-blank-fill text-success me-1" /> ATTIVA</span>},
            {value: "WAITING", label: <span><i className="ri-checkbox-blank-fill text-warning me-1" /> IN ATTESA</span>},
            {value: "SUSPENDED", label: <span><i className="ri-checkbox-blank-fill text-warning me-1" /> SOSPESA</span>},
            {value: "CANCELED", label: <span><i className="ri-checkbox-blank-fill text-danger me-1" /> CANCELLATA</span>}
        ]
    }

    selectSlot(slot) {
        if (slot.reserved)
            if (slot.campaign.id !== this.state.form.id)
                return;

        let form = this.state.form;
        let check = false;
        for (let i = 0; i < form.slots.length; i++) {
            if (slot.id === form.slots[i]) {
                check = true;
                form.slots.splice(i, 1);
            }
        }

        if (!check)
            form.slots.push(slot.id)

        this.setState({form: form});
    }

    render() {

        const self = this;

        const onSearchMonitor = debounce((search) => {
            if (search === "")
                return;
            this.setState({loadingSearch: true});
            MonitorAPI.list({search: search}).then((list) => {
                let options = [];
                for (let i = 0; i < list.length; i++) {
                    let check = false;
                    for (let d = 0; d < self.state.monitors.length; d++) {
                        if (self.state.monitors[d].id === list[i].id)
                            check = true
                    }
                    if (!check)
                        options.push({value: list[i].id, label: list[i].name + " - " + list[i].full_address})
                }
                self.setState({loadingSearch: false, monitorSearch: options});
            }).catch(() => {
                self.setState({loadingSearch: false});
            });
        }, 500);



        if (this.state.error) {
            return <React.Fragment>
                <div className="page-content">
                    <Alert color="danger" >Si è verificato un errore durante il recupero dei dettagli della campagna.</Alert>
                </div>
            </React.Fragment>
        }

        const form = this.state.form;
        const errors = this.state.errors;

        return (
            <React.Fragment>
                {(this.state.loading) ? <Loading /> : <div className="page-content">
                    <Container fluid>
                        <BreadCrumb title={(this.state.mode === "edit") ? "MODIFICA CAMPAGNA" : "CREA CAMPAGNA"} pageTitle="Pubbli Seller"/>
                        <Button className="btn btn-sm btn-primary m-1" onClick={() => window.history.go(-1)}><i className="ri-arrow-left-circle-line fs-5 text-white-75 align-middle" /> Torna indietro</Button>
                        <Card>
                            <CardHeader>
                                <h5><i className="ri-file-2-fill align-bottom me-1" style={{margin: 10}}></i> CAMPAGNA</h5>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md="6">
                                        <div className='mb-2'>
                                            <Label className='form-label'>
                                                Stato
                                            </Label>
                                            <Select
                                                value={(this.state.form.status !== null) ? this.getStatusCampaign(form.status) : undefined}
                                                placeholder={"Seleziona lo stato della campagna..."}
                                                onChange={(selectedOption, triggeredAction) => {
                                                    self.changeForm("status", selectedOption.value)
                                                }}
                                                options={this.getStatusCampaign(form.status, true)}
                                            />
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className='mb-2'>
                                            <Label className='form-label'>
                                                Nome
                                            </Label>
                                            <Input type="text" value={form.name}
                                                   invalid={Boolean(errors.name)}
                                                   onChange={(e) => this.changeForm('name', e.target.value)}/>
                                            <FormFeedback>{errors.name}</FormFeedback>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6">
                                        <div className='mb-2'>
                                            <Label className='form-label'>
                                                Data inizio
                                            </Label>
                                            <Input type="date" value={form.date_start}
                                                   invalid={Boolean(errors.date_start)}
                                                   onChange={(e) => this.changeForm('date_start', e.target.value)}/>
                                            <FormFeedback>{errors.date_start}</FormFeedback>
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className='mb-2'>
                                            <Label className='form-label'>
                                                Data fine
                                            </Label>
                                            <Input type="date" value={form.date_end}
                                                   invalid={Boolean(errors.date_end)}
                                                   onChange={(e) => this.changeForm('date_end', e.target.value)}/>
                                            <FormFeedback>{errors.date_end}</FormFeedback>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardHeader>
                                <h5><i className="ri-tv-2-fill align-bottom me-1"></i> MONITOR</h5>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col className="m-3">
                                        <Select
                                            placeholder={"Cerca monitor..."}
                                            value={null}
                                            onChange={(selectedOption, triggeredAction) => {
                                                let monitors = self.state.monitors;
                                                monitors.push(selectedOption);
                                                self.setState({
                                                    monitors: monitors,
                                                    accordionOpen: monitors.length
                                                });
                                            }}
                                            onInputChange={(search) => onSearchMonitor(search)}
                                            options={this.state.monitorSearch}
                                            isLoading={this.state.loadingSearch}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Accordion>
                                            {this.state.monitors.map((monitor, i) => {
                                                return <AccordionItem key={i}>
                                                    <h2 className="accordion-header">
                                                        <button
                                                            className={"accordion-button"} type="button" onClick={() => this.setState({accordionOpen: (this.state.accordionOpen === i) ? null : i})} style={{ cursor: "pointer" }} >
                                                            {monitor.name} &nbsp;&nbsp;&nbsp;<small>{monitor.full_address}</small>
                                                        </button>
                                                    </h2>

                                                    <Collapse isOpen={(this.state.accordionOpen === i)} className="accordion-collapse">
                                                        <div className="accordion-body">
                                                            {(this.state.accordionOpen === i) ? <ListSlot id_monitor_slot={monitor.id} selected={form.slots} getVariable onResolve={(slot) => self.selectSlot(slot)}/> : null}
                                                        </div>
                                                    </Collapse>
                                                </AccordionItem>
                                            })}
                                        </Accordion>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <button
                                    type="button"
                                    className="btn btn-success float-end"
                                    id="create-btn" onClick={() => this.saveCampaign()}>
                                    <i className="ri-save-2-fill align-bottom me-1"></i> SALVA
                                </button>
                            </CardBody>
                        </Card>
                    </Container>
                </div>}
            </React.Fragment>
        );
    }
}

export default withRouter(ContractGest);
