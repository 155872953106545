import React, { Component } from 'react';
import {Card, CardBody, CardHeader, Col, Container, Row} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import {withRouter, Link} from "react-router-dom";

import GoogleMap from 'google-map-react';

import {Loading} from "custom";
import PreloadImage from 'react-preload-image'
import loading from '../../assets/icons/loading.gif';


import moment from 'moment';
import 'moment/locale/it';

import style from "./styles";
import contractExpIcon from "../../assets/icons/marker_contract_expired.png";
import contractRentIcon from "../../assets/icons/marker_contract_rent.png";
import contractAdsIcon from "../../assets/icons/marker_contract_ads.png";
import positionIcon from "../../assets/icons/marker_position.png";


const LoadingContainer = () => <Loading />;


// InfoWindow component
const InfoWindowMonitor = (props) => {
    const { place, marker, self } = props;
    const infoWindowStyle = {
        position: 'relative',
        bottom: 150,
        left: '-45px',
        width: 250,
        backgroundColor: 'white',
        boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
        padding: 10,
        fontSize: 14,
        zIndex: 1,
    };
    let urlGallery = "/gallery?"
    let links = [];

    for (let i = 0; i < place.links.length; i++) {
        let idUrl = place.links[i];
        console.log(idUrl)
        links.push("https://drive.google.com/uc?export=view&id=" + idUrl);
        urlGallery = urlGallery + "photo" + (i+1) + "=" + idUrl + "&";
    }

    return (
        <div style={infoWindowStyle}>
            <Row>
                <Col onClick={() => self.setState({show: false})}><i className="float-end mdi mdi-close-thick" style={{fontSize: "22px", marginBottom: "20px"}} /></Col>
            </Row>
            <Row>
                {(links.length > 0) ? <Link to={urlGallery}>
                    <Col sm={12} md={12} lg={12}>
                        <PreloadImage
                            style={{
                                position: 'relative',
                                width: '100%',
                                height: '200px',
                                // backgroundColor: '#222222',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                backgroundImage: `url(${loading})`
                            }}
                            alt=""
                            src={links[0]}
                        />
                    </Col>
                </Link> : null}
                <Col sm={12} md={12} lg={12}>
                    <br /><br />
                    <div style={{ fontSize: 16 }}>
                        <Link to={"/monitor/" + place.id}><strong>{place.name}</strong></Link>
                    </div>
                    <div style={{ fontSize: 15 }}>
                        Visitatori giornalieri medi: <strong>{place.medium_lobby}</strong>
                    </div>
                    <div style={{ fontSize: 14 }}>
                        Disponibilità: <span className={(place.available_slots < 10) ? "text-danger" : "text-success"}>{place.available_slots}</span> / 32
                    </div>
                    <div style={{ fontSize: 13, color: 'grey' }}>
                        {marker.full_address}
                    </div>
                    <br /><br />
                    <a href={"https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=" + marker.lat + "," + marker.lon} target={"_blank"}>Avvia navigazione</a>
                </Col>
            </Row>
        </div>
    );
};

const MarkerMonitor = ({ monitor, marker, markerSize, self }) => {
    const monitorIcon = require("../../assets/icons/marker_monitor.png");
    const monitorFullIcon = require("../../assets/icons/marker_monitor_full.png");

    return <div style={{
        // opacity: self.state.opacity,
        position: "absolute",
        top: "50%",
        left: "50%",
        width: "1px",
        height: "1px",
        transform: "translate(-50%, -50%)",
        cursor: "pointer",
        zIndex: (self.state.show === marker.id) ? 100 : 15,
    }}>
        <img alt="" onClick={() => self.setState({show: marker.id})} style={{transform: "translate(-50%, -100%)"}} src={(monitor.available_slots === 0) ? monitorFullIcon : monitorIcon} width={markerSize + "px"}/>
        {(self.state.show === marker.id) ? <InfoWindowMonitor place={monitor} marker={marker} self={self} /> : null}
    </div>
};





const InfoWindowClient = (props) => {
    const { place, marker, self } = props;
    const infoWindowStyle = {
        position: 'relative',
        bottom: 150,
        left: '-45px',
        width: 250,
        backgroundColor: 'white',
        boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
        padding: 10,
        fontSize: 14,
        zIndex: 1,
    };

    let agents = [];

    let contractActive = false;
    let daysDiff = 0;

    for (let i = 0; i < place.contracts.length; i++) {
        let c = moment(place.contracts[i].date_expire).diff(moment(), 'days');
        if (c > 0) {
            contractActive = true;
            if (c < daysDiff || daysDiff === 0) {
                daysDiff = c;
            }
        }
        if (place.contracts[i].agent !== null)
            agents.push({id: place.contracts[i].agent.id, name: place.contracts[i].agent.firstname + " " + place.contracts[i].agent.lastname});
    }

    agents.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i);

    let color = 'text-secondary';
    if (daysDiff < 30 && daysDiff > 10) {
        color = 'text-warning';
    } else if (daysDiff <= 10) {
        color = 'text-danger';
    }

    let contract = <div style={{ fontSize: 14 }}>
        Contratto: {(contractActive) ? <small className='text-success'><b>ATTIVO</b></small> : <small className='text-danger'><b>SCADUTO</b></small>}
    </div>

    let contractExpiresLabel = (contractActive) ? <small className={color}>Scade tra {daysDiff} giorni</small> : null;

    return (
        <div style={infoWindowStyle}>
            <Row>
                <Col onClick={() => self.setState({show: false})}><i className="float-end mdi mdi-close-thick" style={{fontSize: "22px"}} /></Col>
            </Row>
            <Row>
                <Col sm={12} md={12} lg={12}>
                    <div style={{ fontSize: 16 }}>
                        <Link to={"/client/" + place.id}><strong>{place.name}</strong></Link>
                    </div>
                    <br />
                    <div style={{ fontSize: 15 }}>
                        Agent{(agents.length > 1) ? "i" : "e"}: {(agents.length > 0) ? agents.map((agent, i) => {
                            return <span key={i}><Link to={"/agent/" + agent.id}><strong>{agent.name}</strong></Link><br /></span>
                    }) : <strong className="text-danger">N/D</strong>}
                    </div>
                    {contract}
                    {contractExpiresLabel}
                    <div style={{ fontSize: 13, color: 'grey' }}>
                        {marker.full_address}
                    </div>
                    <br /><br />
                    <a href={"https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=" + marker.lat + "," + marker.lon} target={"_blank"}>Avvia navigazione</a>
                </Col>
            </Row>
        </div>
    );
};


const MarkerClient = ({ client, marker, markerSize, self }) => {

    const contractAdsIcon = require("../../assets/icons/marker_contract_ads.png");
    const contractRentIcon = require("../../assets/icons/marker_contract_rent.png");
    const contractExpIcon = require("../../assets/icons/marker_contract_expired.png");

    let icon = contractExpIcon;

    if (client.contracts.length > 0) {
        for (let i = 0; i < client.contracts.length; i++) {
            if (moment(client.contracts[i].date_expire).diff(moment(), 'days') > 0) {
                if (client.contracts[i].typology === "RENTAL")
                    icon = contractRentIcon;
            }
        }
        for (let i = 0; i < client.contracts.length; i++) {
            let diffExpire = moment(client.contracts[i].date_expire).diff(moment(), 'days');
            if (diffExpire > 0) {
                if (client.contracts[i].typology === "ADVERTISING") {
                    icon = contractAdsIcon;
                }
            }
        }
    }

    return <div style={{
        // opacity: self.state.opacity,
        position: "absolute",
        top: "50%",
        left: "50%",
        width: "1px",
        height: "1px",
        transform: "translate(-50%, -50%)",
        cursor: "pointer",
        zIndex: (self.state.show === marker.id) ? 100 : 10,
    }}>
        <img alt="" onClick={() => self.setState({show: marker.id})} style={{transform: "translate(-50%, -100%)"}} src={icon} width={markerSize + "px"}/>
        {(self.state.show === marker.id) ? <InfoWindowClient place={client} marker={marker} self={self} /> : null}
    </div>
};

const InfoWindowAppointment = (props) => {
    const { place, marker, self } = props;
    const infoWindowStyle = {
        position: 'relative',
        bottom: 150,
        left: '-45px',
        width: 250,
        backgroundColor: 'white',
        boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
        padding: 10,
        fontSize: 14,
        zIndex: 1,
    };


    let status = <div style={{ fontSize: 14 }}>
        {(place.status === "APPOINTMENT") ? <small className='text-primary'><b>APPUNTAMENTO</b></small> : <small className='text-warning'><b>IN TRATTATIVA</b></small>}
    </div>
    let appointmentDate = (place.date_time !== null) ? <div style={{ fontSize: 14 }}>
        {<small className='text-primary'>{moment(place.date_time).format("DD/MM/YYYY HH:mm")}</small>}
    </div> : null


    return (
        <div style={infoWindowStyle}>
            <Row>
                <Col onClick={() => self.setState({show: false})}><i className="float-end mdi mdi-close-thick" style={{fontSize: "22px"}} /></Col>
            </Row>
            <Row>
                <Col sm={12} md={12} lg={12}>
                    <div style={{ fontSize: 16 }}>
                        <Link to={"/appointment"}><strong>{place.name}</strong></Link>
                    </div>
                    <br />
                    <div style={{ fontSize: 15 }}>
                        Agente: <Link to={"/agent/" + place.agent.id}><strong>{place.agent.firstname} {place.agent.lastname}</strong></Link>
                    </div>
                    {status}
                    {appointmentDate}
                    <br />
                    <div style={{ fontSize: 13, color: 'grey' }}>
                        {marker.full_address}
                    </div>
                    <br /><br />
                    <a href={"https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=" + marker.lat + "," + marker.lon} target={"_blank"}>Avvia navigazione</a>
                </Col>
            </Row>
        </div>
    );
};


const MarkerAppointment = ({ appointment, marker, markerSize, self }) => {
    const contactIcon = require("../../assets/icons/marker_contact.png");
    const dealIcon = require("../../assets/icons/marker_contact_deal.png");

    return <div style={{
        // opacity: self.state.opacity,
        position: "absolute",
        top: "50%",
        left: "50%",
        width: "1px",
        height: "1px",
        transform: "translate(-50%, -50%)",
        cursor: "pointer",
        zIndex: (self.state.show === marker.id) ? 100 : 10,
    }}>
        <img alt="" onClick={() => self.setState({show: marker.id})} style={{transform: "translate(-50%, -100%)"}} src={(appointment.status === "APPOINTMENT") ? contactIcon : dealIcon} width={markerSize + "px"}/>
        {(self.state.show === marker.id) ? <InfoWindowAppointment place={appointment} marker={marker} self={self} /> : null}
    </div>
};

const MarkerPosition = () => {
    const positionIcon = require("../../assets/icons/marker_position.png");
    return <div style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        width: "1px",
        height: "1px",
        transform: "translate(-50%, -50%)",
        cursor: "pointer",
        zIndex: 100,
    }}>
        <img alt="" style={{transform: "translate(-50%, -50%)"}} src={positionIcon} width={"20px"}/>
    </div>
};


class MapContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            zoom: 13,
            show: null,
            opacity: 1
        }
    }


    render() {
        let covers = this.props.covers;
        let monitors = this.props.monitors;
        let clients = this.props.clients;
        let appointments = this.props.appointments;

        let filters = this.props.filters;

        return (
            <React.Fragment>
                <GoogleMap
                    defaultZoom={(this.props.searchCoords) ? 17 : 13}
                    // onDrag={() => this.setState({opacity: 0.1})}
                    // onDragEnd={() => this.setState({opacity: 1})}
                    options={{
                        panControl: true,
                        gestureHandling: 'greedy',
                        keyboardShortcuts: false,
                        // getClickableIcons: false,
                        fullscreenControl: true,
                        styles: (!filters.mapStandard) ? style.map : []
                    }}
                    gestureHandling="greedy"
                    center={(this.props.searchCoords) ? [this.props.searchCoords.lat, this.props.searchCoords.lon] : undefined}
                    defaultCenter={[this.props.coords.lat, this.props.coords.lon]}
                    onChange={(e) => this.setState({zoom: e.zoom})}
                    heatmap={{
                        positions: covers,
                        options: {
                            radius: this.state.zoom*10/3,
                            opacity: 0.8,
                        },
                    }}
                    bootstrapURLKeys={{
                        key: process.env.REACT_APP_GOOGLE_SERVICES_KEY,
                        libraries: ['visualization'],
                    }}
                >

                    {(this.props.coords.lat !== Number(process.env.REACT_APP_GOOGLE_SERVICES_DEFAULT_LAT) || this.props.coords.lon !== Number(process.env.REACT_APP_GOOGLE_SERVICES_DEFAULT_LON)) ? <MarkerPosition
                        lat={this.props.coords.lat}
                        lng={this.props.coords.lon}
                    /> : null}

                    {monitors.map((place, i) => (
                        <MarkerMonitor
                            key={i}
                            lat={place.lat}
                            lng={place.lon}
                            monitor={place.monitor}
                            marker={place}
                            markerSize={this.state.zoom*5/3}
                            self={this}
                        />
                    ))}

                    {appointments.map((place, i) => (
                        <MarkerAppointment
                            key={i}
                            lat={place.lat}
                            lng={place.lon}
                            appointment={place.appointment}
                            marker={place}
                            markerSize={this.state.zoom*5/3}
                            self={this}
                        />
                    ))}

                    {clients.map((place, i) => (
                        <MarkerClient
                            key={i}
                            lat={place.lat}
                            lng={place.lon}
                            client={place.client}
                            marker={place}
                            markerSize={this.state.zoom*5/3}
                            self={this}
                        />
                    ))}
                </GoogleMap>
                {/*<button*/}
                {/*    type="button"*/}
                {/*    className="btn text-dark bg-white"*/}
                {/*    style={{position: 'absolute', top: '118px', right: '67px'}}*/}
                {/*    onClick={() => this.handleLocationClick()}>*/}
                {/*    <i className="mdi mdi-crosshairs-gps align-bottom"></i>*/}
                {/*</button>*/}
            </React.Fragment>
        );
    }
}

export default MapContainer
