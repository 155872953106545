import React, { Component } from 'react';
import {Alert, Badge, Card, CardHeader, CardBody, Col, Container, Row, Nav, NavItem, NavLink, TabPane, TabContent, Table} from 'reactstrap';
import {withRouter, Link} from "react-router-dom";


class TabAnag extends Component {

    render() {

        const client = this.props.client;

        return (
            <React.Fragment>
                <Row>
                    <Col xxl={12}>
                        <Card>
                            <CardHeader>
                                <h5 className="card-title mb-5 d-md-none">Posizione</h5>
                            </CardHeader>
                            <CardBody>
                                <div className="table-responsive" style={{padding: 10}}>
                                    <Table className="table-borderless mb-0">
                                        <tbody>
                                            <tr>
                                                <th className="ps-0" scope="row">Nome:</th>
                                                <td className="text-muted">{client.name}</td>
                                            </tr>
                                            <tr>
                                                <th className="ps-0" scope="row">Ragione Sociale:</th>
                                                <td className="text-muted">{client.business_name}</td>
                                            </tr>
                                            <tr>
                                                <th className="ps-0" scope="row">Partita IVA:</th>
                                                <td className="text-muted"><a href={"https://www.reportaziende.it/" + client.vat_id} target="_blank">{client.vat_id}</a></td>
                                            </tr>
                                            <tr>
                                                <th className="ps-0" scope="row">Codice Fiscale:</th>
                                                <td className="text-muted">{client.fiscal_code}</td>
                                            </tr>
                                            <tr>
                                                <th className="ps-0" scope="row">Codice univoco:</th>
                                                <td className="text-muted">{client.unique_code}</td>
                                            </tr>
                                            <tr>
                                                <th className="ps-0" scope="row">PEC:</th>
                                                <td className="text-muted"><a href={"mailto:" + client.pec}>{client.pec}</a></td>
                                            </tr>
                                            <tr>
                                                <th className="ps-0" scope="row"></th>
                                                <td className="text-muted"></td>
                                            </tr>
                                            <tr>
                                                <th className="ps-0" scope="row">Telefono:</th>
                                                <td className="text-muted"><a href={"tel:" + client.phone}>{client.phone}</a></td>
                                            </tr>
                                            <tr>
                                                <th className="ps-0" scope="row">Email:</th>
                                                <td className="text-muted"><a href={"mailto:" + client.email}>{client.email}</a></td>
                                            </tr>
                                            <tr>
                                                <th className="ps-0" scope="row">IBAN BANCARIO:</th>
                                                <td className="text-muted">{client.bank_iban}</td>
                                            </tr>
                                            <tr>
                                                <th className="ps-0" scope="row">CATEGORIA:</th>
                                                <td className="text-muted">{client.category}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default withRouter(TabAnag);
