export function checkVers() {
    const packageJson = require("../package.json");
    const moment = require("moment");

    return new Promise(function(resolve, reject) {
        const refreshCacheAndReload = () => {
            if (caches) {
                caches.keys().then((names) => {
                    for (const name of names) {
                        caches.delete(name);
                    }
                });
            }
            window.location.reload(true);
        };

        const buildDateGreaterThan = (latestDate, currentDate) => {
            const momLatestDateTime = moment(latestDate);
            const momCurrentDateTime = moment(currentDate);
            return momLatestDateTime.isAfter(momCurrentDateTime);
        };

        fetch("/meta.json")
            .then((response) => response.json())
            .then((meta) => {
                const latestVersionDate = meta.buildDate;
                const currentVersionDate = packageJson.buildDate;

                const shouldForceRefresh = buildDateGreaterThan(
                    latestVersionDate,
                    currentVersionDate
                );
                if (shouldForceRefresh) {
                    refreshCacheAndReload();
                } else {
                    resolve();
                }
            }).catch((err) => {
                console.log(err)
        });
    });
}
