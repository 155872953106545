let list = function() {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');
        fetch(global.route.agent, {
            headers: {'x-access-token': hash},
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('agents list', responseJson);
                if (responseJson.status === "LIST_AGENTS")
                    resolve(responseJson.list);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let detail = function(id) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');
        fetch(global.route.agent + "/" + id, {
            headers: {'x-access-token': hash},
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('agent detail', responseJson);
                if (responseJson.status === "AGENT_DETAIL_OK")
                    resolve(responseJson.agent);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let add = function(user) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.agent, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'PUT',
            body: JSON.stringify({
                firstname: user.firstname,
                lastname: user.lastname,
                email: user.email,
                cell: user.cell,
                vat_id: user.vat_id,
                bank_iban: user.bank_iban
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('agent add', responseJson);
                if (responseJson.status === "USER_CREATED")
                    resolve(responseJson.user);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let edit = function(form) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.agent, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                id: form.id,
                active: form.active,
                firstname: form.firstname,
                lastname: form.lastname,
                email: form.email,
                cell: form.cell,
                vat_id: form.vat_id,
                bank_iban: form.bank_iban
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                 global.checkAuth(responseJson.status);
                console.log('agent edit', responseJson);
                if (responseJson.status === "AGENT_EDITED")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let remove = function(id) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.agent, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'DELETE',
            body: JSON.stringify({
                id: id
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('agent delete', responseJson);
                if (responseJson.status === "AGENT_DELETED")
                    resolve(responseJson.user);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

const AgentAPI = {
    list: list,
    detail: detail,
    add: add,
    edit: edit,
    remove: remove,
};

module.exports = AgentAPI;
