import React, {Component} from 'react';
import {
    Card,
    CardBody,
    Col,
    Container,
    Input,
    Label,
    Row,
    Button,
    FormFeedback,
    Table
} from 'reactstrap';
import ParticlesAuth from "../Authentication/ParticlesAuth";

import {withRouter, Link} from "react-router-dom";

import logoLight from "../../assets/images/logo-light.png";

import {ReportAPI, MonitorAPI} from "api";

import {Utils, Loading} from "custom";


class NewVoucher extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: "",
            phone: "",
            company: "",
            errors: false,
            loading: false,
            completed: false,
            idMonitor: null
        };
    }

    componentDidMount() {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let idMonitor = params.get("m");
        if (idMonitor === null && !Utils.isUserLoggedIn())
            window.location.href = "/login";
        this.setState({idMonitor: idMonitor});

    }

    onSendReport() {
        const self = this;
        let form = {
            name: this.state.name,
            phone: this.state.phone,
            company: this.state.company
        };

        let error = {};

        if (form.name === '')
            error.name = "Il campo nome è obbligatorio";

        if (form.phone === '')
            error.phone = "Il campo telefono è obbligatorio";

        this.setState({errors: error});

        if (Object.keys(error).length === 0) {
            this.setState({loading: true})

            ReportAPI.addGuest(form, this.state.idMonitor).then(() => {
                this.setState({loading: false})

                global.SweetAlert.fire({
                    title: 'Voucher creato',
                    text: 'Il voucher è stato creato correttamente.',
                    icon: 'success',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    },
                    confirmButtonText: 'Chiudi',
                    buttonsStyling: false
                }).then(function (result) {
                    if (result.value) {
                        window.location.href = "/homepage";
                    }
                })
            }).catch(() => {
                this.setState({loading: false})
                global.SweetAlert.fire({
                    title: 'Errore',
                    text: 'Si è verificato un errore durante la creazione del voucher',
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    }
                });
            })
        }
    }

    onViewAds() {

        MonitorAPI.getAdvertisers(this.state.idMonitor).then((advertisers) => {

            global.onSetModal({
                title: "LISTA INSERZIONISTI",
                content: <div>
                    <Row>
                        <Col>
                            <Table striped>
                                <thead>
                                    {(advertisers.length > 0) ? <tr>
                                        <th>Nome</th>
                                        <th>Telefono</th>
                                        <th>Indirizzo</th>
                                    </tr> : <tr>
                                        <th>Nessun inserzionista trovato</th>
                                    </tr>}
                                </thead>
                                <tbody>
                                    {advertisers.map((advertiser, i) => {
                                        return <tr key={i}>
                                            <td>{advertiser.name}</td>
                                            <td>{advertiser.phone}</td>
                                            <td><small>{advertiser.address}</small></td>
                                        </tr>
                                    })}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </div>,
                isOpen: true,
                toggle: false,
                size: 'lg',
                type: 'info',
                buttons: [
                    {
                        label: 'CHIUDI',
                        color: 'info',
                        onClick: () => global.onSetModal({isOpen: false, content: ""})
                    },
                ]
            })
        })
    }


    render() {
        return <React.Fragment>
            {(this.state.loading) ? <Loading/> : (<ParticlesAuth>
                <div className="auth-page-content">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-5 mb-4 text-white-50">
                                    <div>
                                        <img src={logoLight} alt="" height="180"/>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                {(!this.state.completed) ? <Card className="mt-4">
                                    <CardBody className="p-4">
                                        <div className="mb-3">
                                            <div className="text-center mt-2" hidden={(this.state.token === null)}>
                                                <h4 className="text-primary">Benvenuto!</h4>
                                                <h5>Con il servizio pubblicitario di Pubbliartist, potrai raggiungere il
                                                    tuo pubblico ideale in modo efficace ed efficiente.</h5>
                                                <h6>Rilascia il tuo contatto per <u>ottenere un omaggio</u> e scopri
                                                    come possiamo aiutare la tua attività a crescere! 🚀</h6>
                                                <p>👇👇</p>
                                            </div>
                                            <Label htmlFor="name" className="form-label">Nome*</Label>
                                            <Input
                                                name="name"
                                                className="form-control"
                                                placeholder="Nome"
                                                type="text"
                                                tabIndex={2}
                                                value={this.state.name}
                                                invalid={this.state.errors.name}
                                                onChange={(text) => this.setState({name: text.target.value})}
                                            />
                                            {(this.state.errors.name) ? <FormFeedback
                                                type="invalid">{this.state.errors.name}</FormFeedback> : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label htmlFor="phone" className="form-label">Telefono*</Label>
                                            <Input
                                                name="phone"
                                                className="form-control"
                                                placeholder="Telefono"
                                                type="tel"
                                                tabIndex={3}
                                                value={this.state.phone}
                                                invalid={this.state.errors.phone}
                                                onChange={(text) => this.setState({phone: text.target.value})}
                                            />
                                            {(this.state.errors.phone) ? <FormFeedback
                                                type="invalid">{this.state.errors.phone}</FormFeedback> : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label htmlFor="company" className="form-label">Azienda</Label>
                                            <Input
                                                name="company"
                                                className="form-control"
                                                placeholder="Nome azienda"
                                                type="text"
                                                tabIndex={3}
                                                value={this.state.company}
                                                invalid={this.state.errors.company}
                                                onChange={(text) => this.setState({company: text.target.value})}
                                            />
                                            {(this.state.errors.company) ? <FormFeedback
                                                type="invalid">{this.state.errors.company}</FormFeedback> : null}
                                        </div>
                                        <div className="p-2 mt-4">
                                            <div className="mt-4">
                                                <Button color="success"
                                                        className="btn btn-success w-100"
                                                        onClick={() => this.onSendReport()}><i
                                                    className="ri-check-fill"></i> Invia</Button>
                                                <small hidden={(this.state.token === null)}><br/><br/>Cliccando su
                                                    "Invia" si accettano le condizioni di trattamento dei dati personali
                                                    per il solo scopo di ricontattare l'utente interessato al servizio.</small>
                                            </div>
                                        </div>
                                        <div className="p-2 mt-4" hidden={(this.state.token === null)}>
                                            <div className="mt-4">
                                                <Button color="info" className="btn btn-info w-100"
                                                        onClick={() => this.onViewAds()}><i
                                                    className="ri-mac-fill"></i> Visualizza Inserzionisti</Button>
                                            </div>
                                        </div>
                                        <div className="p-2 mt-4" hidden={(this.state.token !== null)}>
                                            <div className="mt-4">
                                                <Link to="/homepage"><Button color="secondary"
                                                                             className="btn btn-secondary w-100">Indietro</Button></Link>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card> : <Card className="mt-4">
                                    <CardBody className="p-4">
                                        <div className="text-center mt-2">
                                            <h4 className="text-primary">Ciao {this.state.name}!</h4>
                                            <h5>💎 Grazie! Rilasciando il tuo contatto hai diritto ad un omaggio
                                                speciale. 💎</h5>
                                            <h6>Chiamaci per ulteriori informazioni :)</h6>
                                            <i className="mdi mdi-check fs-48 text-success"></i>
                                            <br/>
                                            <h6>Ufficio Pubbliartist</h6>
                                            <a href="tel:+390854554939">085 4554939</a>

                                        </div>
                                    </CardBody>
                                </Card>}
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>)}
        </React.Fragment>
    }
}

export default withRouter(NewVoucher);
