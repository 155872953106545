import React, {Component} from 'react';
import {Alert, Card, CardBody, CardHeader, Col, Container, FormFeedback, FormText, Input, Label, Row} from 'reactstrap';
import {withRouter, Link} from "react-router-dom";

import {Utils, GeoForm, Loading} from "custom";
import {CampaignCodeAPI, ClientAPI, ReportAPI} from "api";

import Select from "react-select";

class FormClient extends Component {

    constructor(props) {
        super(props);
        let client = this.props.client;
        console.log("client", client)

        this.state = {
            client: {
                id: (typeof client.id !== "undefined") ? client.id : null,
                business_name: client.business_name,
                name: client.name,
                vat_id: client.vat_id,
                phone: client.phone,
                email: client.email,
                pec: client.pec,
                bank_iban: client.bank_iban,
                fiscal_code: client.fiscal_code,
                unique_code: client.unique_code,
                category: client.category
            },
            errors: false,
            loading: false
        }
        document.title = "Nuovo Contratto | Pubbli Seller";
    }


    changeForm(key, text) {
        if (key === "vat_id") {
            if (text.length > 11)
                return;
        }

        if (key === 'phone' || key === 'cell') {
            if (isNaN(text) && text[0] !== "+")
                return;

            if (text.length >= 3 && text.indexOf("+") === -1)
                text = "+39" + text;
        }

        const client = this.state.client;
        client[key] = text;
        this.setState({client: client});
    }

    next() {
        const client = this.state.client;
        let error = {};

        if (client.business_name === '')
            error.business_name = "Il campo ragione sociale è obbligatorio";

        if (client.name === '')
            error.name = "Il campo nome è obbligatorio";

        if (client.email === '')
            error.email = "Il campo email è obbligatorio";

        if (client.phone === '')
            error.phone = "Il campo telefono è obbligatorio";

        if (client.vat_id === '')
            error.vat_id = "Il campo partita iva è obbligatorio";

        if (client.unique_code === '')
            error.unique_code = "Il campo codice univoco è obbligatorio";

        if (client.email !== '' && !Utils.isValidEmail(client.email))
            error.email = "La mail inserita non è corretta";

        if (client.pec !== '' && client.pec !== null && !Utils.isValidEmail(client.pec))
            error.pec = "La pec inserita non è corretta";

        if (client.phone !== '' && !Utils.isValidCell(client.phone))
            error.phone = "Il telefono inserito non è corretto";

        if (client.bank_iban !== '' && client.bank_iban !== null && !Utils.isValidIban(client.bank_iban))
            error.bank_iban = "Il codice iban inserito non è corretto";

        if (client.fiscal_code !== '' && !Utils.isValidFiscalCode(client.fiscal_code))
            error.fiscal_code = "Il codice fiscale non è corretto";

        if (client.vat_id !== '' && !Utils.isPiva(client.vat_id))
            error.vat_id = "La partita iva non è corretta";

        this.setState({errors: error});

        if (Object.keys(error).length === 0) {

            this.setState({loading: true})

            ClientAPI.list({search: client.vat_id}).then((res) => {
                console.log(res, client)
                if (res.length > 0 && client.id === null) {
                    error.vat_id = "Questa partita iva è già un cliente";
                } else {
                    this.props.onNext(client);
                }

                this.setState({errors: error, loading: false});
            })
        }
    }


    render() {
        let errors = this.state.errors;
        let client = this.state.client;

        const categories = Utils.getCategoriesClient();

        return (
            <React.Fragment>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                <h2>Anagrafica Cliente</h2>
                                <Row>
                                    <Col md="6">
                                        <div className='mb-2'>
                                            <Label className='form-label'>
                                                Nome*
                                            </Label>
                                            <Input type="text" value={client.name}
                                                   invalid={Boolean(errors.name)}
                                                   onChange={(e) => this.changeForm('name', e.target.value)}/>
                                            <FormFeedback>{errors.name}</FormFeedback>
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className='mb-2'>
                                            <Label className='form-label'>
                                                Ragione sociale*
                                            </Label>
                                            <Input type="text" value={client.business_name}
                                                   invalid={Boolean(errors.business_name)}
                                                   onChange={(e) => this.changeForm('business_name', e.target.value)}/>
                                            <FormFeedback>{errors.business_name}</FormFeedback>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6">
                                        <div className='mb-2'>
                                            <Label className='form-label'>
                                                Cellulare*
                                            </Label>
                                            <Input type="tel" value={client.phone}
                                                   invalid={Boolean(errors.phone)}
                                                   onChange={(e) => this.changeForm('phone', e.target.value)}/>
                                            <FormText>
                                                Il numero sarà utilizzato per la validazione del contratto via SMS - assicurati di inserire un numero di cellulare
                                            </FormText>
                                            <FormFeedback>{errors.phone}</FormFeedback>
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className='mb-2'>
                                            <Label className='form-label'>
                                                Email*
                                            </Label>
                                            <Input type="email" value={client.email}
                                                   invalid={Boolean(errors.email)}
                                                   onChange={(e) => this.changeForm('email', e.target.value)}/>
                                            <FormText>
                                                Sarà inviata una copia del contratto a questo indirizzo
                                            </FormText>
                                            <FormFeedback>{errors.email}</FormFeedback>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6">
                                        <div className='mb-2'>
                                            <Label className='form-label'>
                                                Partita IVA*
                                            </Label>
                                            <Input type="number" value={client.vat_id}
                                                   invalid={Boolean(errors.vat_id)}
                                                   onChange={(e) => (!isNaN(e.target.value) ? this.changeForm('vat_id', e.target.value) : {})}/>
                                            <FormFeedback>{errors.vat_id}</FormFeedback>
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className='mb-2'>
                                            <Label className='form-label'>
                                                PEC
                                            </Label>
                                            <Input type="email" value={client.pec}
                                                   invalid={Boolean(errors.pec)}
                                                   onChange={(e) => this.changeForm('pec', e.target.value)}/>
                                            <FormFeedback>{errors.pec}</FormFeedback>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6">
                                        <div className='mb-2'>
                                            <Label className='form-label'>
                                                Codice Fiscale
                                            </Label>
                                            <Input type="text" value={client.fiscal_code}
                                                   invalid={Boolean(errors.fiscal_code)}
                                                   onChange={(e) => this.changeForm('fiscal_code', e.target.value)}/>
                                            <FormFeedback>{errors.fiscal_code}</FormFeedback>
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className='mb-2'>
                                            <Label className='form-label'>
                                                Codice Iban
                                            </Label>
                                            <Input type="text" value={client.bank_iban}
                                                   invalid={Boolean(errors.bank_iban)}
                                                   onChange={(e) => this.changeForm('bank_iban', e.target.value)}/>
                                            <FormFeedback>{errors.bank_iban}</FormFeedback>
                                            <FormText>
                                                Compilare se previsto addebito RID/SDD
                                            </FormText>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6">
                                        <div className='mb-2'>
                                            <Label className='form-label'>
                                                Codice Univoco*
                                            </Label>
                                            <Input type="text" value={client.unique_code}
                                                   invalid={Boolean(errors.unique_code)}
                                                   onChange={(e) => this.changeForm('unique_code', e.target.value)}/>
                                            <FormFeedback>{errors.unique_code}</FormFeedback>
                                            <FormText>
                                                Compilare con 000000 se sprovvisto
                                            </FormText>
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className='mb-2'>
                                            <Label className='form-label'>
                                                Categoria
                                            </Label>
                                            <Select
                                                value={categories[categories.findIndex((category) => client.category === category.value)]}
                                                placeholder={"Seleziona la categoria..."}
                                                onChange={(selectedOption, triggeredAction) => {
                                                    this.changeForm("category", selectedOption.value)
                                                }}
                                                options={categories}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <div className="text-center flex-row">
                            <button type="button" className="btn bg-primary text-white m-1" onClick={() => this.next()}>
                                {(this.state.loading) ? <i className="mdi mdi-spin mdi-loading" /> : "PROSEGUI"}
                            </button>
                        </div>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default withRouter(FormClient);
