import { GoogleApiWrapper, Map, Marker } from "google-maps-react";
import React from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';

const mapStyles = {
    width: '100%',
    height: '100%'
};


const LoadingContainer = () => <div>Caricamento...</div>

const GoogleMap = (props) => {

    const defaultOption = {
        defaultLat: (typeof props.option.defaultLat !== "undefined") ? props.option.defaultLat : 42.46267326769674,
        defaultLon: (typeof props.option.defaultLon !== "undefined") ? props.option.defaultLon : 14.217721420266223,
        initialZoom: (typeof props.option.initialZoom !== "undefined") ? props.option.initialZoom :12
    }

    return (
        <React.Fragment>
            <div id="gmaps-markers" className="gmaps" style={{ position: "relative" }}>
                <Map

                    google={props.google}
                    zoom={defaultOption.initialZoom}
                    style={mapStyles}
                    streetViewControl={false}
                    mapTypeControl={false}
                    initialCenter={{ lat: defaultOption.defaultLat, lng: defaultOption.defaultLon }}
                >
                    {props.markers.map((marker, i) => {
                        return <Marker key={i} position={{ lat: marker.lat, lng: marker.lon }} />
                    })}
                </Map>
            </div>
        </React.Fragment>
    );
}

export default (
    GoogleApiWrapper({
        apiKey: process.env.REACT_APP_GOOGLE_SERVICES_KEY,
        LoadingContainer: LoadingContainer,
        v: "3",
    })(GoogleMap)
)




