import React, {Fragment} from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Alert,
    Row,
    Col,
    Label,
    FormFeedback,
    Input,
    Badge
} from 'reactstrap';

import {Utils, Loading, GestLinkMonitor, DeleteLinkMonitor} from "custom";

import {Grid, _} from 'gridjs-react';

class ListLinkMonitor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            links: this.props.links ?? [],
            error: false
        };
    }


    render() {

        const self = this;
        if (this.state.error) {
            return <React.Fragment>
                <Alert color="danger">Si è verificato un errore durante il recupero della lista link.</Alert>
            </React.Fragment>
        }

        return <React.Fragment>
            <div style={{margin: 10}}>
                <GestLinkMonitor preset={{id_link_monitor: this.props.id_link_monitor}} onResolve={() => this.props.onResolve()}/>
            </div>
            <Grid
                data={this.state.links}
                columns={[
                    {
                        id: 'id',
                        name: '#'
                    },
                    {
                        id: 'name',
                        name: "Nome"
                    },
                    {
                        id: 'path',
                        name: "URL",
                        formatter: (cell, row) => _(
                            <a href={cell} target="_blank">{cell}</a>
                        ),
                    },
                    {
                        id: 'show',
                        name: "Galleria",
                        formatter: (cell, row) => _(<div className="text-nowrap text-center">
                            {(cell) ? <i className="ri-check-fill fs-20 text-success text-center"></i> : <i className="ri-close-fill fs-20 text-danger text-center"></i>}
                            </div>
                        ),
                    },
                    {
                        id: 'id',
                        name: '',
                        sort: false,
                        formatter: (cell, row, i) => _(<ul
                            className="list-inline hstack gap-2 mb-0">
                            <GestLinkMonitor preset={{
                                id_link_monitor: this.props.id_link_monitor,
                                id: row.cells[0].data,
                                name: row.cells[1].data,
                                path: row.cells[2].data,
                                show: row.cells[3].data
                            }} onResolve={this.props.onResolve.bind(this)}/>
                            <DeleteLinkMonitor id={row.cells[0].data} onResolve={self.props.onResolve.bind(this)}/>
                        </ul>)
                    }
                ]}
                resizable={true}
                search={true}
                sort={true}
                pagination={{enabled: true, limit: 10}}
                language={{
                    'search': {
                        'placeholder': '🔍 Cerca...'
                    },
                    'pagination': {
                        'previous': 'Indietro',
                        'next': 'Avanti',
                        'showing': 'Stai visualizzando da',
                        'results': 'Link',
                        'to': 'a',
                        'of': 'di',
                    },
                    'noRecordsFound': 'Nessun link trovato',
                    'error': 'Si è verificato un errore non previsto',
                }}
            />
        </React.Fragment>
    }
}

export default ListLinkMonitor;
