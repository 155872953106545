import React, { Component } from 'react';
import {
    Alert,
    Badge,
    Card,
    CardHeader,
    CardBody,
    Col,
    Container,
    Row,
    Nav,
    NavItem,
    NavLink,
    TabPane,
    TabContent,
    Table,
    Button
} from 'reactstrap';
import {withRouter, Redirect, Link} from "react-router-dom";

import {ClientAPI, MonitorAPI} from "api";

import {Loading, GoogleMap, Utils} from "custom";

import profileBg from '../../assets/images/monitor-bg.jpg';
import avatarClient from '../../assets/images/avatar/client.jpg';

import TabAnag from "./TabsGest/TabAnag";
import TabPosition from "./TabsGest/TabPosition";
import TabContract from "./TabsGest/TabContract";
import TabReferent from "./TabsGest/TabReferent";

const moment = require("moment");

class ClientGest extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: false,
            client: null,
            tabActive: 1
        };
        document.title ="Dettagli Cliente | Pubbli Seller";
    }

    componentDidMount() {
        this.getDetailClient()
    }

    getDetailClient() {
        ClientAPI.detail(this.props.match.params.id).then((client) => {
            this.setState({client: client, loading: false})
        }).catch((err) => {
            console.error(err);
            this.setState({error: true})
        })
    }

    deleteClient() {
        const self = this;
        global.SweetAlert.fire({
            title: "Elimina cliente",
            text: 'Attenzione, stai eliminando un cliente, questo comporterà l\'eliminazione di tutti i contratti, campagne e dati associati ad esso. Vuoi procedere??',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, elimina',
            cancelButtonText: 'Annulla',
            customClass: {
                confirmButton: 'btn btn-danger',
                cancelButton: 'btn btn-outline-danger ms-1'
            },
            buttonsStyling: false,
            showLoaderOnConfirm: true,
            preConfirm: function() {
                return new Promise(function(resolve, reject) {
                    ClientAPI.remove(self.state.client.id).then(() => {
                        resolve();
                    }).catch(() => {
                        global.SweetAlert.fire({
                            icon: 'danger',
                            title: 'Si è verificato un errore :(',
                            text: 'Purtroppo qualcosa è andato storto e non è stato possibile eliminare il cliente. Riprova più tardi.',
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        });
                        reject()
                    })
                });
            },
        }).then(function (result) {
            if (result.value) {
                global.SweetAlert.fire({
                    icon: 'success',
                    title: 'Cliente eliminato!',
                    text: 'Cliente eliminato correttamente.',
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                });
                window.location.replace("/client");
            }
        })
    }


    render() {

        if (this.state.error) {
            return <React.Fragment>
                <div className="page-content">
                    <Alert color="danger" >Si è verificato un errore durante il recupero dei dettagli del cliente.</Alert>
                </div>
            </React.Fragment>
        }

        const client = this.state.client;
        const user = Utils.getUserData();

        return (
            <React.Fragment>
                {(this.state.loading) ? <Loading /> : <div className="page-content">
                    <Container fluid>
                        <div className="profile-foreground position-relative mx-n4 mt-n4">
                            <div className="profile-wid-bg">
                                <img src={profileBg} alt="" className="profile-wid-img" />
                            </div>
                        </div>
                        <div className="pt-4 mb-4 mb-lg-3 pb-lg-4">
                            <Row className="g-4">
                                <div className="col-auto">
                                    <div className="avatar-lg">
                                        <img src={avatarClient} alt="user-img"
                                             className="img-thumbnail rounded-circle" />
                                    </div>
                                </div>

                                <Col>
                                    <Link to={"/client"}><Button className="btn btn-sm btn-info" ><i className="ri-arrow-left-circle-line fs-5 text-white-75 align-middle" /> Torna alla lista</Button></Link>
                                    <div className="p-2">
                                        <h3 className="text-white mb-1">{client.name}</h3>
                                        <p className="text-white-75">Cliente</p>
                                        <div className="hstack text-white-50 gap-1">
                                            <div className="me-2">
                                                <i className="ri-map-pin-user-line me-1 text-white-75 fs-16 align-middle"></i>{client.Marker.full_address}
                                            </div>
                                        </div>
                                        <div className="hstack text-white-50 gap-1">
                                            <div className="me-2">
                                                <i className="ri-calendar-2-fill me-1 text-white-75 fs-16 align-middle"></i>Creato il: {moment(client.createdAt).format("DD/MM/YYYY")}
                                            </div>
                                        </div>
                                    </div>
                                </Col>

                                <Col xs={12} className="col-lg-auto order-last order-lg-0">
                                    <Row className="text text-white-50 text-center">
                                        <Col lg={6} xs={3}>
                                            <div className="p-2">
                                                <h5 className="text-white mb-1">Contratto</h5>
                                                {(client.Contracts.length > 0) ? <Badge color="success" pill>Attivo</Badge> : <Badge color="danger" pill>Non Attivo</Badge>}
                                            </div>
                                        </Col>
                                        <Col lg={6} xs={3}>
                                            <div className="p-2">
                                                <h5 className="text-white mb-1">ID</h5>
                                                <p className="fs-16 mb-0">{client.id}</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>

                        <Row>
                            <Col lg={12}>
                                <div>
                                    <div className="d-flex">
                                        <Nav pills className="animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1" role="tablist">
                                            <NavItem>
                                                <NavLink
                                                    className={ "cursor-pointer " + ((this.state.tabActive === 1) ? "fs-16 bg-white text-dark" : null)}
                                                    onClick={() => this.setState({tabActive: 1}) }
                                                >
                                                    <i className="ri-user-settings-fill d-inline-block d-md-none"></i> <span
                                                    className="d-none d-md-inline-block">Anagrafica</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={ "cursor-pointer " + ((this.state.tabActive === 2) ? "fs-16 bg-white text-dark" : null)}
                                                    onClick={() => this.setState({tabActive: 2}) }
                                                >
                                                    <i className="ri-map-2-fill d-inline-block d-md-none"></i> <span
                                                    className="d-none d-md-inline-block">Posizione</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={ "cursor-pointer " + ((this.state.tabActive === 3) ? "fs-16 bg-white text-dark" : null)}
                                                    onClick={() => this.setState({tabActive: 3}) }
                                                >
                                                    <i className="ri-list-unordered d-inline-block d-md-none"></i> <span
                                                    className="d-none d-md-inline-block">Contratti</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={ "cursor-pointer " + ((this.state.tabActive === 4) ? "fs-16 bg-white text-dark" : null)}
                                                    onClick={() => this.setState({tabActive: 4}) }
                                                >
                                                    <i className="ri-user-search-fill d-inline-block d-md-none"></i> <span
                                                    className="d-none d-md-inline-block">Referenti</span>
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                        {(user.role === "ADMIN") ? <div className="flex-shrink-0">
                                            <Link to={"/client/edit/" + client.id}>
                                                <button
                                                    type="button"
                                                    className="btn btn-success edit-btn">
                                                    <i className="ri-edit-2-fill align-bottom me-1"></i> MODIFICA
                                                </button>
                                            </Link>
                                        </div> : null}
                                    </div>

                                    <TabContent activeTab={String(this.state.tabActive)} className="pt-4">
                                        <TabPane tabId="1">
                                            <TabAnag client={client} />
                                        </TabPane>
                                        <TabPane tabId="2">
                                            <TabPosition client={client} />
                                        </TabPane>
                                        <TabPane tabId="3">
                                            <TabContract client={client} />
                                        </TabPane>
                                        <TabPane tabId="4">
                                            <TabReferent client={client} onResolve={this.getDetailClient.bind(this)}/>
                                        </TabPane>
                                    </TabContent>

                                    {(user.role === "ADMIN") ? <div className="text-center" style={{margin: 10}}>
                                        <button
                                            type="button"
                                            className="btn btn-danger"
                                            onClick={() => this.deleteClient()}>
                                            <i className="ri-delete-bin-2-fill align-bottom"></i> Elimina cliente
                                        </button>
                                    </div> : null}
                                </div>
                            </Col>
                        </Row>

                    </Container>
                </div>}
            </React.Fragment>
        );
    }
}

export default withRouter(ClientGest);
