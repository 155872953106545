import React, {Component} from 'react';
import {
    Alert,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Input,
    Label,
    Row,
    NavItem,
    NavLink,
    Nav,
    TabContent,
    TabPane,
} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import {withRouter, Link} from "react-router-dom";

import {Utils, GeoForm, Loading} from "custom";
import {AppointmentAPI, CampaignCodeAPI, ClientAPI, GeoAPI, ReportAPI, ContractAPI} from "api";

import SelectMonitor from "./components/SelectMonitor";
import FormClient from "./components/FormClient";
import FormReferent from "./components/FormReferent";
import FormContract from "./components/FormContract";

class ContractBuildFinal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            placeId: null,
            campaignId: null,
            gift: null,
            monitorsSelected: [],
            monitorSelectable: 1,
            minSlot: 1,
            markers: [],
            client: null,
            referent: null,
            campaignCode: null,
            error: false,
            mode: "map",
            coords: {lat: 0, lon: 0},
            formContract: null,
            type: null
        }
        document.title = "Nuovo Contratto | Pubbli Seller";
    }

    async componentDidMount() {

        let search = window.location.search;
        let params = new URLSearchParams(search);
        let campaignId = Number(params.get("campaignId"));
        let gift = Number(params.get("gift"));
        let type = params.get("type");
        let id = Number(params.get("id"));
        let placeId = params.get("placeId");
        let client = {
            id: null,
            business_name: "",
            name: "",
            vat_id: "",
            phone: "",
            email: "",
            pec: "",
            bank_iban: "",
            fiscal_code: "",
            unique_code: "",
            category: ""
        };

        let referent = {
            id: null,
            name: "",
            email: "",
            phone: "",
            cell: "",
            role: "",
        };

        let place = {lat: 0, lon: 0};

        let monitorSelectable = 0
        let minSlot = 0

        try {
            if (isNaN(gift)) {
                console.error("invalid gift")
                this.setState({error: true});
                return;
            }

            let campaignCode = null;
            let campaignCodes = await CampaignCodeAPI.list();

            let check = false;
            for (let i = 0; i < campaignCodes.length; i++) {
                if (campaignCodes[i].id === campaignId) {
                    campaignCode = campaignCodes[i];
                    check = true;
                    monitorSelectable = campaignCode.n_monitors + gift;
                    minSlot = 16 / campaignCode.ads_frequency;
                }
            }

            if (!check) {
                console.error("invalid campaignCode")
                this.setState({error: true});
                return;
            }

            if (type === "client") {
                let clientDetail = await ClientAPI.detail(id);
                place = {lat: clientDetail.Marker.lat, lon: clientDetail.Marker.lon};
                client = {
                    id: clientDetail.id,
                    business_name: clientDetail.business_name,
                    name: clientDetail.name,
                    vat_id: clientDetail.vat_id,
                    phone: clientDetail.phone,
                    email: clientDetail.email,
                    pec: clientDetail.pec,
                    bank_iban: clientDetail.bank_iban,
                    fiscal_code: clientDetail.fiscal_code,
                    unique_code: clientDetail.unique_code,
                    category: clientDetail.category
                };

                if (clientDetail.Referents.length > 0)
                    referent = {
                        id: clientDetail.Referents[0].id,
                        name: clientDetail.Referents[0].name,
                        email: clientDetail.Referents[0].email,
                        phone: clientDetail.Referents[0].phone,
                        cell: clientDetail.Referents[0].cell,
                        role: clientDetail.Referents[0].role
                    };

            } else if (type === "report") {
                let reports = await ReportAPI.list();
                let check = false;
                for (let i = 0; i < reports.length; i++) {
                    if (reports[i].id === id) {
                        check = true;
                        client.name = reports[i].company;
                        referent.name = reports[i].name;
                        referent.cell = reports[i].phone;
                    }
                }
                if (!check) {
                    this.setState({error: true});
                    return;
                }

                place = await GeoAPI.detail(placeId);
                place = {lat: place.lat, lon: place.lon};

            } else if (type === "appointment") {
                let appointments = await AppointmentAPI.list();
                let check = false;
                for (let i = 0; i < appointments.length; i++) {
                    if (appointments[i].id === id) {
                        check = true;
                        client.name = appointments[i].name;
                        client.phone = appointments[i].phone;
                        client.email = appointments[i].email;
                    }
                }
                if (!check) {
                    this.setState({error: true});
                    return;
                }

                place = await GeoAPI.detail(placeId);
                place = {lat: place.lat, lon: place.lon};

            } else if (type === null) {
                place = await GeoAPI.detail(placeId);
                place = {lat: place.lat, lon: place.lon};
            }

            this.setState({
                loading: false,
                coords: {lat: place.lat, lon: place.lon},
                type: type,
                id: id,
                placeId: placeId,
                gift: gift,
                client: client,
                referent: referent,
                monitorSelectable: monitorSelectable,
                minSlot: minSlot,
                campaignCode: campaignCode
            });
        } catch (e) {
            console.error(e);
            this.setState({error: true});
        }
    }

    onSelectMonitor(id) {
        let monitorsSelected = this.state.monitorsSelected;
        let add = !(monitorsSelected.includes(id));
        if (add) {
            monitorsSelected.push(id);
        } else {
            for (let i = 0; i < monitorsSelected.length; i++) {
                if (monitorsSelected[i] === id)
                    monitorsSelected.splice(i, 1);
            }
        }

        this.setState({monitorsSelected: monitorsSelected});
    }

    renderElement() {
        let mode = this.state.mode;
        if (mode === "map") {
            return <SelectMonitor
                coords={this.state.coords}
                minSlot={this.state.minSlot}
                monitorsSelected={this.state.monitorsSelected}
                monitorSelectable={this.state.monitorSelectable}
                onSelectMonitor={this.onSelectMonitor.bind(this)}
                onNext={this.onNext.bind(this)}
            />;
        } else if (mode === "anag") {
            return <FormClient client={this.state.client} onNext={this.onNext.bind(this)}/>;
        } else if (mode === "referent") {
            return <FormReferent referent={this.state.referent} onNext={this.onNext.bind(this)}/>;
        } if (mode === "contract") {
            return <FormContract refClient={this.state.type} campaignCode={this.state.campaignCode} onNext={this.onNext.bind(this)}/>;
        }

        return null;
    }

    onNext(object) {
        let mode = this.state.mode;

        if (mode === "map") {
            this.setState({mode: "anag"});
        } else if (mode === "anag") {
            this.setState({mode: "referent", client: object});
        } else if (mode === "referent") {
            this.setState({mode: "contract", referent: object});
        } else if (mode === "contract") {
            this.setState({formContract: object}, () => this.sendDraft());
        }
    }

    onBack() {
        let mode = this.state.mode;

        if (mode === "map") {
            this.props.history.goBack();
        } else if (mode === "anag") {
            this.setState({mode: "map"});
        } else if (mode === "referent") {
            this.setState({mode: "anag"});
        } else if (mode === "contract") {
            this.setState({mode: "referent"});
        }
    }

    sendDraft() {
        this.setState({loading: true});
        let formDraft = {
            referral: this.state.type,
            client: {
                id: (typeof this.state.client.id === "undefined") ? null : this.state.client.id,
                business_name: this.state.client.business_name,
                name: this.state.client.name,
                vat_id: this.state.client.vat_id,
                phone: this.state.client.phone,
                email: this.state.client.email,
                pec: (this.state.client.pec === null) ? "" : this.state.client.pec,
                bank_iban: (this.state.client.bank_iban === null) ? "" : this.state.client.bank_iban,
                fiscal_code: this.state.client.fiscal_code,
                unique_code: this.state.client.unique_code,
                category: this.state.client.category
            },
            referent: {
                id: (typeof this.state.client.id === "undefined") ? null : this.state.referent.id,
                name: this.state.referent.name,
                email: (this.state.referent.email === null) ? "" : this.state.referent.email,
                phone: (this.state.referent.phone === null) ? "" : this.state.referent.phone,
                cell: this.state.referent.cell,
                role: this.state.referent.role
            },
            placeId: this.state.placeId,
            campaignCodeId: this.state.campaignCode.id,
            appointmentId: (this.state.type === "appointment") ? Number(this.state.id) : null,
            reportId: (this.state.type === "report") ? Number(this.state.id) : null,
            monitorSelected: this.state.monitorsSelected,
            giftMonitor: this.state.gift,
            campaignNote: this.state.formContract.campaignNote,
            note: this.state.formContract.note,
            deposit_cash: this.state.formContract.payment_cash,
            deposit_cheque: this.state.formContract.payment_cheque,
            deposit_bank: this.state.formContract.payment_bank,
            deposit_other: this.state.formContract.payment_other,
            showRate: this.state.formContract.showRate
        };
        ContractAPI.generateDraft(formDraft).then((result) => {
            global.draftTemp = undefined;
            window.location.replace("/draft/" + result.id_draft);
        }).catch((err) => {

            let message = "";

            switch(err.status) {
                case "INVALID_FORM":
                    message = "Non è stato possibile validare alcuni campi: " + err.message;
                    break;
                case "INVALID_CLIENT":
                    message = "Il cliente selezionato non è stato trovato";
                    break;
                case "INVALID_REFERENT":
                    message = "Il referente selezionato non è stato trovato";
                    break;
                case "INVALID_REPORT":
                    message = "La segnalazione selezionata non è stata trovata";
                    break;
                case "CLIENT_ALREADY_EXIST":
                    message = "Il cliente selezionato è già esistente";
                    break;
                case "INVALID_PLACE":
                    message = "Si è verificato un errore durante la verifica dell'indirizzo";
                    break;
                case "INVALID_APPOINTMENT":
                    message = "L'appuntamento selezionato non è esiste";
                    break;
                case "INVALID_CAMPAIGN_CODE":
                    message = "Il codice campagna non è disponibile per la vendita";
                    break;
                case "INVALID_MONITORS":
                    message = "I monitor selezionati non esistono";
                    break;
                case "INVALID_MONITORS_SELECTED":
                    message = "I monitor selezionati non sono disponibili";
                    break;
                case "DEPOSIT_TOO_LOW":
                    message = "L'acconto dichiarato non è sufficiente";
                    break;
                case "DEPOSIT_TOO_HIGH":
                    message = "L'acconto dichiarato supera il costo della campagna";
                    break;
                default:
                    message = "";
                    break;
            }

            global.SweetAlert.fire({
                icon: 'error',
                title: 'Si è verificato un errore',
                text: message,
                customClass: {
                    confirmButton: 'btn btn-success'
                }
            });
            this.setState({loading: false});
            console.error(err);
        })
    }

    render() {

        if (this.state.error) {
            return <React.Fragment>
                <div className="page-content">
                    <Alert color="danger">Si è verificato un errore durante il recupero dei dati.</Alert>
                </div>
            </React.Fragment>
        }

        return (
            <React.Fragment>
                {(this.state.loading) ? <Loading/> : <div className="page-content">
                    <Container fluid>
                        <Row>
                            <Col lg={12}>
                                {this.renderElement()}
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center flex-row">
                                    <span className="text-primary cursor-pointer" onClick={() => this.onBack()}>
                                        <a>Indietro</a>
                                    </span>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>}
            </React.Fragment>
        );
    }
}

export default withRouter(ContractBuildFinal);
