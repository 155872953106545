import React, { Component } from 'react';
import {Alert, Badge, Card, CardHeader, CardBody, Col, Container, Row, Nav, NavItem, NavLink, TabPane, TabContent, Table} from 'reactstrap';
import {withRouter, Link} from "react-router-dom";

import {ListReferent} from "custom";

class TabReferent extends Component {

    onResolve() {
        this.props.onResolve();
        global.onSetModal({isOpen: false, loading: false, content: ""})
    }

    render() {

        const client = this.props.client;

        return (
            <React.Fragment>
                <Row>
                    <Col xxl={12}>
                        <Card>
                            <CardHeader>
                                <h5 className="card-title mb-5 d-md-none">Referenti</h5>
                            </CardHeader>
                            <CardBody>
                                <ListReferent id_client_referent={client.id} onResolve={this.onResolve.bind(this)}/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default withRouter(TabReferent);
