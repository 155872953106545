let list = function(filter) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        let route = "";

        if (typeof filter !== "undefined") {
            route = "?";

            if (typeof filter.search !== "undefined")
                route = route + "search=" + filter.search

        }

        fetch(global.route.monitor + route, {
            headers: {'x-access-token': hash},
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('monitor list', responseJson);
                if (responseJson.status === "LIST_MONITORS_OK")
                    resolve(responseJson.list);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let detail = function(id) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');
        fetch(global.route.monitor + "/" + id, {
            headers: {'x-access-token': hash},
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('monitor detail', responseJson);
                if (responseJson.status === "MONITOR_DETAIL_OK")
                    resolve(responseJson.monitor);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let add = function(monitor) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.monitor, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'PUT',
            body: JSON.stringify({
                name: monitor.name,
                medium_lobby: monitor.medium_lobby,
                referent: monitor.referent,
                phone: monitor.phone,
                marker: {
                    full_address: monitor.marker.full_address,
                    address: monitor.marker.address,
                    lat: monitor.marker.lat,
                    lon: monitor.marker.lon,
                    city: monitor.marker.city,
                    region: monitor.marker.region,
                    province: monitor.marker.province,
                    cap: monitor.marker.cap
                }
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('monitor add', responseJson);
                if (responseJson.status === "MONITOR_CREATED")
                    resolve(responseJson.monitor);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};


let addUser = function(user) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.monitorUser, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'PUT',
            body: JSON.stringify({
                id_monitor: user.id_monitor,
                firstname: user.firstname,
                lastname: user.lastname,
                email: user.email,
                cell: user.cell,
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('monitor user add', responseJson);
                if (responseJson.status === "USER_CREATED")
                    resolve(responseJson.user);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let removeUser = function(id_monitor) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.monitorUser, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'DELETE',
            body: JSON.stringify({
                id_monitor: id_monitor
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('monitor user delete', responseJson);
                if (responseJson.status === "USER_MONITOR_DELETED")
                    resolve(responseJson.monitor);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let addLink = function(form) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.monitorLink, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'PUT',
            body: JSON.stringify({
                id_link_monitor: form.id_link_monitor,
                name: form.name,
                path: form.path,
                show: form.show
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('monitor link add', responseJson);
                if (responseJson.status === "LINK_MONITOR_CREATED")
                    resolve(responseJson.link);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let editLink = function(form) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.monitorLink, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                id: form.id,
                name: form.name,
                path: form.path,
                show: form.show
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('monitor link edit', responseJson);
                if (responseJson.status === "LINK_MONITOR_EDITED")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let removeLink = function(id_link) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.monitorLink, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'DELETE',
            body: JSON.stringify({
                id: id_link
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('monitor link delete', responseJson);
                if (responseJson.status === "LINK_MONITOR_DELETED")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};


let edit = function(monitor) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.monitor, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                id: monitor.id,
                name: monitor.name,
                medium_lobby: monitor.medium_lobby,
                referent: monitor.referent,
                phone: monitor.phone,
                marker: {
                    full_address: monitor.marker.full_address,
                    address: monitor.marker.address,
                    lat: monitor.marker.lat,
                    lon: monitor.marker.lon,
                    city: monitor.marker.city,
                    region: monitor.marker.region,
                    province: monitor.marker.province,
                    cap: monitor.marker.cap
                }
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                 global.checkAuth(responseJson.status);
                console.log('monitor edit', responseJson);
                if (responseJson.status === "MONITOR_EDITED")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let remove = function(id) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.monitor, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'DELETE',
            body: JSON.stringify({
                id: id
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('monitor delete', responseJson);
                if (responseJson.status === "MONITOR_DELETED")
                    resolve(responseJson.monitor);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let getAdvertisers = function(token) {
    return new Promise(function(resolve, reject) {

        fetch(global.route.monitorADS + "?token=" + token, {
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('advertisers list', responseJson);
                if (responseJson.status === "LIST_ADVERTISERS_OK")
                    resolve(responseJson.advertisers);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

const MonitorAPI = {
    list: list,
    detail: detail,
    add: add,
    addUser: addUser,
    removeUser: removeUser,
    addLink: addLink,
    editLink: editLink,
    removeLink: removeLink,
    edit: edit,
    remove: remove,
    getAdvertisers: getAdvertisers
};

module.exports = MonitorAPI;
