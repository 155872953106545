import React, { Component } from 'react';
import {Alert, Badge, Col, Container, Row} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import {withRouter, Link} from "react-router-dom";
import { Grid, _ } from 'gridjs-react';

import {GestMonitor, Loading, Utils} from "custom";

import { MonitorAPI } from "api";


class MonitorTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            monitors: [],
            loading: true,
            error: false
        };
    }

    componentDidMount() {
        this.getMonitors();
    }

    getMonitors() {
        MonitorAPI.list().then((monitors) => {
            this.setState({loading: false, error: false, monitors: monitors});
        }).catch(() => {
            this.setState({error: true});
        })
    }

    render() {

        const self = this;

        if (this.state.error) {
            return <React.Fragment>
                <Alert color="danger" >Si è verificato un errore durante il recupero della lista monitor.</Alert>
            </React.Fragment>
        }

        return (
            <React.Fragment>
                <div className="flex-shrink-0" style={{margin: 10}}>
                    {(Utils.getUserData().role === "ADMIN") ? <GestMonitor onResolve={this.getMonitors.bind(this)}/> : null}
                </div>
                {(this.state.loading) ? <Loading /> : <Grid
                    data={this.state.monitors}

                    columns={[
                        {
                            id: 'id',
                            name: '#',
                            formatter: (cell, row) => _(
                                <span className="text-nowrap">{cell}</span>
                            ),
                            width: "50px"
                        },
                        {
                            id: 'name',
                            formatter: (cell, row) => _(
                                <span className="text-nowrap"><a href="javascript:void(0)" onClick={() => self.props.history.push("/monitor/" + row.cells[0].data)}><b>{cell}</b></a></span>
                            ),
                            name: "Nome"
                        },
                        {
                            id: 'city',
                            name: "Città",
                            formatter: (cell, row) => _(<span className="text-nowrap">
                                <p>{cell}</p>
                            </span>)
                        },
                        {
                            id: 'address',
                            name: "Indirizzo",
                            formatter: (cell, row) => _(<span className="text-nowrap">
                                <a href={"https://www.google.it/maps/place/" + cell + " " + row.cells[2].data} target="_blank">{cell}</a>
                            </span>)
                        },
                        {
                            id: 'user',
                            name: "Contatto",
                            formatter: (cell, row) => _(<span className="flex-column d-flex text-left">
                                {(cell !== null) ? <>
                                    <small>{cell.firstname}</small>
                                    <small><a href={"tel:" + cell.cell} target="_blank">{cell.cell}</a></small>
                                </> : null}
                            </span>)
                        },
                        {
                            id: 'medium_lobby',
                            name: "Ingressi medi"
                        },
                        {
                            id: 'available_slots',
                            name: "Disponibilità",
                            formatter: (cell, row) => _(<span className="text-nowrap">
                                <span className={(cell < 10) ? "text-danger" : undefined}>{cell}</span> / 32
                            </span>)
                        },
                        {
                            id: 'status',
                            name: 'Stato',
                            sort: false,
                            formatter: (cell, row) => _(<span className="text-nowrap">
                                {(cell === "FULL") ? <Badge color="danger" >Pieno</Badge> : ((cell === "LOW") ? <Badge color="warning" >In esaurimento</Badge> : <Badge color="success" >Libero</Badge>)}
                            </span>)
                        }
                    ]}
                    // style={{table: {'white-space': 'nowrap'}}}
                    // resizable={true}
                    search={true}
                    sort={true}
                    pagination={{ enabled: true, limit: 10}}
                    language={{
                        'search': {
                            'placeholder': '🔍 Cerca...'
                        },
                        'pagination': {
                            'previous': 'Indietro',
                            'next': 'Avanti',
                            'showing': 'Stai visualizzando da',
                            'results': 'Punti di diffusione',
                            'to': 'a',
                            'of': 'di',
                        },
                        'noRecordsFound': 'Nessun punto di diffusione trovato',
                        'error': 'Si è verificato un errore non previsto',
                    }}
                />}
            </React.Fragment>
        );
    }
}

export default withRouter(MonitorTable);
