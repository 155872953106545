import React, {Fragment} from 'react';
import {Row, Col, Label, FormFeedback, Input} from 'reactstrap';

import {Utils} from "custom";
import { MonitorAPI } from "api";

class GestUserMonitor extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            id_monitor: this.props.id_monitor,
            form: {
                email: "",
                firstname: "",
                lastname: "",
                cell: "",
            },
            loading: false,
            errors: false
        };
    }

    changeForm(key, text) {
        const form = this.state.form;
        form[key] = text;
        this.setState({form: form}, () => this.showModal());
    }

    resetForm() {
        this.setState({
            form: {
                email: "",
                firstname: "",
                lastname: "",
                cell: "",
            }
        });
        global.onSetModal({isOpen: false, content: ""});
    }

    showModal() {
        let form = this.state.form;
        let errors = this.state.errors;

        global.onSetModal({
            title: "CREA UTENTE MONITOR",
            content: <div>
                <Row>
                    <Col md="6">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Nome*
                            </Label>
                            <Input type="text" defaultValue={form.firstname}
                                   invalid={Boolean(errors.firstname)}
                                   onChange={(e) => this.changeForm('firstname', e.target.value)}/>
                            <FormFeedback>{errors.firstname}</FormFeedback>
                        </div>
                    </Col>
                    <Col md="6">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Cognome*
                            </Label>
                            <Input type="text" defaultValue={form.lastname}
                                   invalid={Boolean(errors.lastname)}
                                   onChange={(e) => this.changeForm('lastname', e.target.value)}/>
                            <FormFeedback>{errors.lastname}</FormFeedback>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md="6">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Email*
                            </Label>
                            <Input type="text" defaultValue={form.email}
                                   invalid={Boolean(errors.email)}
                                   onChange={(e) => this.changeForm('email', e.target.value)}/>
                            <FormFeedback>{errors.email}</FormFeedback>
                        </div>
                    </Col>
                    <Col md="6">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Cellulare*
                            </Label>
                            <Input type="text" defaultValue={form.cell}
                                   invalid={Boolean(errors.cell)}
                                   onChange={(e) => this.changeForm('cell', e.target.value)}/>
                            <FormFeedback>{errors.cell}</FormFeedback>
                        </div>
                    </Col>
                </Row>
            </div>,
            isOpen: true,
            toggle: false,
            size: 'lg',
            type: 'info',
            buttons: [
                {label: 'SALVA', color: 'success', onClick: () => this.saveUser()},
                {
                    label: 'ANNULLA',
                    color: 'danger',
                    onClick: () => this.setState({errors: false}, () => this.resetForm())
                },
            ]
        })
    }


    saveUser() {
        const self = this;
        let form = this.state.form;
        let error = {};

        if (form.firstname === '')
            error.firstname = "Il campo nome è obbligatorio";

        if (form.email === '')
            error.email = "Il campo email è obbligatorio";

        if (form.lastname === '')
            error.lastname = "Il campo cognome è obbligatorio";

        if (form.cell === '')
            error.cell = "Il campo telefono è obbligatorio";

        if (form.email !== '' && !Utils.isValidEmail(form.email))
            error.email = "La mail inserita non è corretta";

        if (form.cell !== '' && !Utils.isValidPhone(form.cell))
            error.cell = "Il cellulare inserito non è corretto";

        this.setState({errors: error}, () => this.showModal());

        if (Object.keys(error).length === 0) {
            form["id_monitor"] = this.state.id_monitor;
            global.onSetModal({loading: true})
            MonitorAPI.addUser(form).then((res) => {
                global.onSetModal({loading: false})
                global.SweetAlert.fire({
                    title: 'Utente monitor creato',
                    input: 'text',
                    inputLabel: 'Copia il link referral:',
                    inputValue: process.env.REACT_APP_FRONTEND_URL + "/new-voucher?m=" + this.state.id_monitor,
                    icon: 'success',
                    allowOutsideClick: false,
                    showCancelButton: true,
                    showConfirmButton: false,
                    customClass: {
                        cancelButton: 'btn btn-primary'
                    },
                    cancelButtonText: 'Chiudi',
                    buttonsStyling: false
                }).then(function (result) {
                    self.resetForm()
                    self.props.onReload();
                })
            }).catch((err) => {
                global.onSetModal({loading: false})
                if(err.status === "USER_EXIST") {
                    error.email = "La mail risulta già registrata";
                    this.setState({errors: error}, () => this.showModal())
                    return;
                }

                global.SweetAlert.fire({
                    title: 'Errore',
                    text: 'Si è verificato un errore durante la creazione dell\'utenza monitor',
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    }
                });
            })
        }
    }


    render() {
        return (
            <React.Fragment>
                <button
                    type="button"
                    className="btn btn-primary add-btn"
                    onClick={() => this.showModal()}>
                    <i className="ri-user-add-fill align-bottom me-1"></i> CREA UTENZA MONITOR
                </button>
            </React.Fragment>
        )
    }
}

export default GestUserMonitor;
