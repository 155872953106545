import React, { Component } from 'react';
import {Card, CardBody, CardHeader, Col, Container, Row} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import {withRouter, Link} from "react-router-dom";

import { ListReport } from "custom";

class ReportList extends Component {

    constructor(props) {
        super(props);
        document.title ="Gestione Segnalazioni | Pubbli Seller";
    }

    render() {

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <BreadCrumb title="Segnalazioni" pageTitle="Pubbli Seller"/>
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <ListReport />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(ReportList);
