import _Loading from './Loading';
import _Utils from './Utils';
import _CustomModal from './CustomModal';
import _GeoForm from './GeoForm';
import _GoogleMap from './GoogleMap';

import _GestAdmin from "./Components/AdminAction/GestAdmin";
import _DeleteAdmin from "./Components/AdminAction/DeleteAdmin";

import _GestAgent from "./Components/AgentAction/GestAgent";
import _DeleteAgent from "./Components/AgentAction/DeleteAgent";

import _GestMonitor from "./Components/MonitorAction/GestMonitor";
import _GestUserMonitor from "./Components/MonitorAction/GestUserMonitor";
import _DeleteUserMonitor from "./Components/MonitorAction/DeleteUserMonitor";

import _ListLinkMonitor from "./Components/MonitorAction/ListLinkMonitor";
import _GestLinkMonitor from "./Components/MonitorAction/GestLinkMonitor";
import _DeleteLinkMonitor from "./Components/MonitorAction/DeleteLinkMonitor";

import _ListSlot from "./Components/SlotAction/ListSlot";
import _GestSlot from "./Components/SlotAction/GestSlot";

import _ListReferent from "./Components/ReferentAction/ListReferent";
import _GestReferent from "./Components/ReferentAction/GestReferent";
import _DeleteReferent from "./Components/ReferentAction/DeleteReferent";

import _ListContract from "./Components/ContractAction/ListContract";
import _GestContract from "./Components/ContractAction/GestContract";
import _DeleteContract from "./Components/ContractAction/DeleteContract";

import _ListCampaign from "./Components/CampaignAction/ListCampaign";
import _DeleteCampaign from "./Components/CampaignAction/DeleteCampaign";

import _ListCampaignCode from "./Components/CampaignCodeAction/ListCampaignCode";
import _GestCampaignCode from "./Components/CampaignCodeAction/GestCampaignCode";
import _DeleteCampaignCode from "./Components/CampaignCodeAction/DeleteCampaignCode";

import _ListReport from "./Components/ReportAction/ListReport";
import _GestReport from "./Components/ReportAction/GestReport";
import _DeleteReport from "./Components/ReportAction/DeleteReport";

import _GestAppointment from "./Components/AppointmentAction/GestAppointment";
import _DeleteAppointment from "./Components/AppointmentAction/DeleteAppointment";

import _ListDraft from "./Components/DraftAction/ListDraft";

export const Loading = _Loading;
export const CustomModal = _CustomModal;
export const Utils = _Utils;
export const GeoForm = _GeoForm;
export const GoogleMap = _GoogleMap;

export const GestAdmin = _GestAdmin;
export const DeleteAdmin = _DeleteAdmin;

export const GestAgent = _GestAgent;
export const DeleteAgent = _DeleteAgent;

export const GestMonitor = _GestMonitor;
export const GestUserMonitor = _GestUserMonitor;
export const DeleteUserMonitor = _DeleteUserMonitor;

export const ListLinkMonitor = _ListLinkMonitor;
export const GestLinkMonitor = _GestLinkMonitor;
export const DeleteLinkMonitor = _DeleteLinkMonitor;

export const ListSlot = _ListSlot;
export const GestSlot = _GestSlot;

export const ListReferent = _ListReferent;
export const GestReferent = _GestReferent;
export const DeleteReferent = _DeleteReferent;

export const ListContract = _ListContract;
export const GestContract = _GestContract;
export const DeleteContract = _DeleteContract;

export const ListCampaign = _ListCampaign;
export const DeleteCampaign = _DeleteCampaign;

export const ListCampaignCode = _ListCampaignCode;
export const GestCampaignCode = _GestCampaignCode;
export const DeleteCampaignCode = _DeleteCampaignCode;

export const ListReport = _ListReport;
export const GestReport = _GestReport;
export const DeleteReport = _DeleteReport;

export const GestAppointment = _GestAppointment;
export const DeleteAppointment = _DeleteAppointment;

export const ListDraft = _ListDraft;
