let listMonitor = function() {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.listReportMonitor, {
            headers: {'x-access-token': hash},
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('monitor list report', responseJson);
                if (responseJson.status === "LIST_REPORTS_OK") {
                    resolve(responseJson.list);
                } else {
                    reject(responseJson);
                }
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let list = function() {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.report, {
            headers: {'x-access-token': hash},
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('report list', responseJson);
                if (responseJson.status === "LIST_REPORTS_OK") {
                    resolve(responseJson.list);
                } else {
                    reject(responseJson);
                }
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};


let add = function(report) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.report, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'PUT',
            body: JSON.stringify({
                id_agent: report.id_agent,
                name: report.name,
                phone: report.phone,
                company: report.company
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('report add', responseJson);
                if (responseJson.status === "REPORT_CREATED")
                    resolve(responseJson.report);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};


let addMonitor = function(report, token) {
    return new Promise(function(resolve, reject) {
        const hash = (token === null) ? localStorage.getItem('hash') : token;

        fetch(global.route.createReportMonitor, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'PUT',
            body: JSON.stringify({
                name: report.name,
                phone: report.phone,
                company: report.company
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('report add', responseJson);
                if (responseJson.status === "REPORT_CREATED")
                    resolve(responseJson.report);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let addGuest = function(report, idMonitor) {
    return new Promise(function(resolve, reject) {

        fetch(global.route.createReportMonitor + "/" + idMonitor, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'PUT',
            body: JSON.stringify({
                name: report.name,
                phone: report.phone,
                company: report.company
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                console.log('report add', responseJson);
                if (responseJson.status === "REPORT_CREATED")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let edit = function(report) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.report, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                id: report.id,
                id_agent: report.id_agent,
                name: report.name,
                phone: report.phone,
                company: report.company
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                 global.checkAuth(responseJson.status);
                console.log('report edit', responseJson);
                if (responseJson.status === "REPORT_EDITED")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let remove = function(id) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.report, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'DELETE',
            body: JSON.stringify({
                id: id
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('report delete', responseJson);
                if (responseJson.status === "REPORT_DELETED")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

const ReportAPI = {
    addMonitor: addMonitor,
    addGuest: addGuest,
    listMonitor: listMonitor,
    list: list,
    add: add,
    edit: edit,
    remove: remove
};

module.exports = ReportAPI;
