import React, {Component} from 'react';
import {
    Card,
    CardBody,
    Col,
    Container,
    Input,
    Table,
    Row,
    Button,
    Form,
    FormFeedback,
    Alert,
    FormText, Spinner
} from 'reactstrap';
import ParticlesAuth from "../Authentication/ParticlesAuth";

import {withRouter, Link, Redirect} from "react-router-dom";

import logoLight from "../../assets/images/logo-light.png";

import { ReportAPI } from "api";

import {Utils, Loading} from "custom";


class ListVoucher extends Component {

    constructor(props) {
        super(props);
        this.state = {
            reports: [],
            loading: true,
            error: false
        };
    }

    componentDidMount() {
        ReportAPI.listMonitor().then((reports) => {
            this.setState({reports: reports, loading: false})
        }).catch(() => {
            this.setState({error: true, loading: false})
        })
    }


    render() {
        return <React.Fragment>
            {(this.state.loading) ? <Loading/> : (<ParticlesAuth>
                <div className="auth-page-content">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-5 mb-4 text-white-50">
                                    <div>
                                        <img src={logoLight} alt="" height="180"/>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4">
                                    <div className="text-center mt-2">
                                        <h4 className="text-primary">I MIEI VOUCHER <i
                                            className="ri-ticket-2-fill"></i></h4>
                                        <h5 className="text-primary p-1">In questa sezione puoi visualizzare la lista dei tuoi voucher ed il loro stato</h5>
                                    </div>
                                    <CardBody className="p-4">
                                        {(this.state.error)}
                                        <Table striped>
                                            <thead>
                                                {(this.state.reports.length > 0) ? <tr>
                                                    <th>Nome</th>
                                                    <th className="text-center">Stato</th>
                                                </tr> : <tr>
                                                    <th>Nessun voucher trovato</th>
                                                </tr>}
                                            </thead>
                                            <tbody>
                                            {this.state.reports.map((report, i) => {
                                                return <tr key={i}>
                                                    <td>{report.name}</td>
                                                    <td className="text-center">{(report.converted) ? <i className="ri-check-fill text-success"></i> : <i className="ri-close-fill text-danger"></i>}</td>
                                                </tr>
                                            })}
                                            </tbody>
                                        </Table>
                                        <div className="p-2 mt-4">
                                            <div className="mt-4">
                                                <Link to="/homepage"><Button color="secondary" className="btn btn-secondary w-100">Indietro</Button></Link>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>)}
        </React.Fragment>
    }
}

export default withRouter(ListVoucher);
