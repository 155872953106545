import React, {Fragment} from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert} from 'reactstrap';
import {MonitorAPI} from "api";

class DeleteLinkMonitor extends React.Component {
    constructor(props) {
        super(props);
    }

    showModal() {
        const self = this;
        global.SweetAlert.fire({
            title: "Elimina link",
            text: 'Attenzione, stai eliminando un link. Vuoi procedere?',
            icon: 'danger',
            showCancelButton: true,
            confirmButtonText: 'Si, elimina',
            cancelButtonText: 'Annulla',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ms-1'
            },
            buttonsStyling: false,
            showLoaderOnConfirm: true,
            preConfirm: function() {
                return new Promise(function(resolve, reject) {
                    MonitorAPI.removeLink(self.props.id).then(() => {
                        self.props.onResolve()
                        resolve();
                    }).catch(() => {
                        global.SweetAlert.fire({
                            icon: 'error',
                            title: 'Si è verificato un errore :(',
                            text: 'Purtroppo qualcosa è andato storto e non è stato possibile eliminare il link. Riprova più tardi.',
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        });
                        reject()
                    })
                });
            },
        }).then(function (result) {
            if (result.value) {
                global.SweetAlert.fire({
                    icon: 'success',
                    title: 'Link eliminato!',
                    text: 'Link eliminato correttamente.',
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                });
                self.props.onResolve();
            }
        })
    }


    render() {
        return (
            <React.Fragment>
                <li className="list-inline-item cursor-pointer"
                    onClick={() => this.showModal()}>
                    <i className="ri-delete-bin-5-fill fs-20 text-danger"></i>
                </li>
            </React.Fragment>
        )
    }
}

export default DeleteLinkMonitor;
