import React, {Fragment} from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Alert,
    Row,
    Col,
    Label,
    FormFeedback,
    Input,
    Badge, Card, CardBody
} from 'reactstrap';

import {Utils, Loading, GestSlot} from "custom";
import { SlotAPI } from "api";
const moment = require("moment");


class ListSlot extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            slots: [],
            errors: false,
            loading: true
        };
    }

    componentDidMount() {
        this.getSlots();
    }

    getSlots() {
        SlotAPI.list(this.props.id_monitor_slot).then((slots) => {
            this.setState({error: false, slots: slots, loading: false});
        }).catch(() => {
            this.setState({error: true});
        })
    }

    getDetail(item) {
        if (!item.reserved || item.campaign === null) {
            return <h6 className="fs-14 text-truncate"><span className="opacity-50">N/D</span></h6>
        }

        let advise = null;

        if (moment(item.campaign.date_end, "YYYY-MM-DD").diff(moment(), "day") < 15) {
            advise = <div className={'badge badge-soft-warning fs-10'}><small>IN SCADENZA</small></div>;
        }

        if (item.campaign.status !== "ACTIVE") {
            advise = <div className={'badge badge-soft-danger fs-10'}><small>CAMPAGNA NON ATTIVA</small></div>;
        }

        return <h6 className="fs-14 text-truncate"><span>{item.client.name}<br />{advise}</span></h6>
    }

    render() {
        const self = this;
        if (this.state.error) {
            return <React.Fragment>
                <Alert color="danger">Si è verificato un errore durante il recupero del palinsesto.</Alert>
            </React.Fragment>
        }

        let selected = self.props.selected || [];

        return <React.Fragment>
            <Row>
                <Col className="text-center p-10"><h5>Disponibilità<br /><small>{this.state.slots.reduce((n, x) => n + (!x.reserved), 0)}/32</small></h5></Col>
            </Row>
            <Row>
            {(this.state.loading) ? <Loading /> : this.state.slots.map((item, key) => (
                <Col xxl={3} lg={4} md={6} sm={12} key={key}>
                    <Card className={'profile-project-card shadow-none profile-project-red m-1 cursor-pointer ' + ((selected.includes(item.id)) ? 'border-primary' : '' )} onClick={() => this.props.onResolve(item)} style={{backgroundColor: (item.reserved && item.campaign !== null) ? "#ffdcdc" : undefined}}>
                        <CardBody className="p-1">
                            <div className="d-flex">
                                <div className="flex-grow-1 text-muted overflow-hidden">
                                    {this.getDetail(item)}
                                    <p className="text-muted text-truncate mb-0"><small>Ultimo aggiornamento:</small><br /><span className="text-dark">{moment(item.updatedAt).format("DD/MM/YYYY HH:MM")}</span></p>
                                </div>
                                <div className="flex-shrink-0 ms-2">
                                    {(!item.reserved) ? <div className={'badge badge-soft-success fs-10'}>LIBERO</div> : ((item.campaign !== null) ? <div className={'badge badge-soft-danger fs-10'}>OCCUPATO</div> : <div className={'badge badge-soft-dark fs-10'}>RISERVATO</div>)}
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            ))}
            </Row>
        </React.Fragment>
    }
}

export default ListSlot;
