import React, {Fragment} from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Alert,
    Row,
    Col,
    Label,
    FormFeedback,
    Input,
    FormText
} from 'reactstrap';

import { Utils, GeoForm } from "custom";
import {AppointmentAPI} from "api";
import Flatpickr from "react-flatpickr";

const moment = require("moment");

class GestAppointment extends React.Component {
    constructor(props) {
        super(props);
        let preset = this.props.preset;

        console.log("preset", preset)

        this.state = {
            form: {
                id: preset?.id || null,
                name: preset?.name || '',
                phone: preset?.phone || '',
                email: preset?.email || '',
                date_time: (typeof preset !== "undefined") ? moment(preset.date_time).format("YYYY-MM-DD HH:mm:ss") : null,
                marker: null,
                deal: (typeof preset !== "undefined") ? (preset.status === "DEAL") : undefined
            },
            errors: false
        };
    }

    changeForm(key, text) {
        const form = this.state.form;

        if (key === "phone" && isNaN(text))
            return;

        form[key] = text;

        this.setState({form: form}, () => this.showModal());
    }

    showModal() {
        const self = this;
        let form = this.state.form;
        let errors = this.state.errors;

        global.onSetModal({
            title: (this.props.preset) ? "MODIFICA APPUNTAMENTO" : "CREA APPUNTAMENTO",
            content: <div>
                <Row>
                    <Col md="12">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Data / ora appuntamento
                            </Label>
                            <Flatpickr
                                className="form-control"
                                onChange={(date) => this.changeForm('date_time', moment(date[0]).format("YYYY-MM-DD HH:mm:ss"))}
                                value={form.date_time}
                                options={{
                                    enableTime: true,
                                    dateFormat: "Y-m-d H:i",
                                    time_24hr: true
                                }}
                            />
                            {(errors.date_time) ? <span className="text-danger">{errors.date_time}</span> : null}
                        </div>
                    </Col>
                </Row>
                <br />
                <br />
                <Row>
                    <Col md="4">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Nome
                            </Label>
                            <Input type="text" value={form.name}
                                   invalid={Boolean(errors.name)}
                                   onChange={(e) => this.changeForm('name', e.target.value)}/>
                            <FormFeedback>{errors.name}</FormFeedback>
                        </div>
                    </Col>
                    <Col md="4">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Telefono
                            </Label>
                            <Input type="tel" value={form.phone}
                                   invalid={Boolean(errors.phone)}
                                   onChange={(e) => this.changeForm('phone', e.target.value)}/>
                            <FormFeedback>{errors.phone}</FormFeedback>
                        </div>
                    </Col>
                    <Col md="4">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Email
                            </Label>
                            <Input type="email" value={form.email}
                                   invalid={Boolean(errors.email)}
                                   onChange={(e) => this.changeForm('email', e.target.value)}/>
                            <FormFeedback>{errors.email}</FormFeedback>
                        </div>
                    </Col>
                </Row>
                {(form.id === null) ? <Row>
                    <Col md="12">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Luogo
                            </Label><br/>
                            {(errors.marker) ? <span className="text-danger">{errors.marker}</span> : null}
                            <GeoForm onResolve={(marker) => this.changeForm('marker', marker)} errors={errors}/>
                        </div>
                    </Col>
                </Row> : <Row>
                    <Col md="12">
                        <div className='mb-2'>
                            <Input className="form-check-input" style={{width: "1.5em", height: "1.5em"}} size="md" type="checkbox"  checked={form.deal}
                                   onChange={(e) => this.changeForm('deal', e.target.checked)}/>
                            &nbsp;&nbsp;
                            <Label className='form-label' style={{fontSize: "20px"}}>
                                In trattavia
                            </Label>
                        </div>
                    </Col>
                </Row>}
            </div>,
            isOpen: true,
            toggle: false,
            loading: false,
            size: 'lg',
            type: 'info',
            buttons: [
                {label: 'SALVA', color: 'success', onClick: () => this.saveAppointment()},
                {
                    label: 'CHIUDI',
                    color: 'danger',
                    onClick: () => this.resetForm()
                }
            ]
        })
    }

    resetForm() {
        let preset = this.props.preset;
        this.setState({
            form: {
                id: preset?.id || null,
                name: preset?.name || '',
                phone: preset?.phone || '',
                email: preset?.email || '',
                date_time: (typeof preset !== "undefined") ? moment(preset.date_time).format("YYYY-MM-DD HH:mm:ss") : null,
                marker: null,
                deal: (typeof preset !== "undefined") ? (preset.status === "DEAL") : undefined
            },
            errors: false
        });
        global.onSetModal({isOpen: false, loading: false, content: ""})
    }

    saveAppointment() {
        const self = this;
        let form = this.state.form;

        let error = {};

        if (form.date_time === null)
            error.date_time = "Il campo data/ora è obbligatorio";

        if (form.name === '')
            error.name = "Il campo nome è obbligatorio";

        if (form.phone === '')
            error.phone = "Il campo telefono è obbligatorio";

        if (form.marker === null && form.id === null)
            error.marker = "Il luogo dell'appuntamento è obbligatorio";

        if (form.phone !== '' && form.phone.length < 9)
            error.phone = "Il telefono non è valido";

        this.setState({errors: error}, () => this.showModal());

        if (Object.keys(error).length === 0) {
            global.onSetModal({loading: true});

            let add = (form.id === null);
            let api = (add) ? AppointmentAPI.add : AppointmentAPI.edit;

            if (form.phone.indexOf("+39") === -1)
                form.phone = "+39" + form.phone;

            console.log(form);

            api(form).then(() => {
                global.onSetModal({loading: false});
                global.SweetAlert.fire({
                    title: (add) ? 'Appuntamento creato' : 'Appuntamento modificato',
                    text: (add) ? 'L\'appuntamento è stato creato correttamente.' : '\'L\'appuntamento è stato modificato correttamente.',
                    icon: 'success',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    },
                    confirmButtonText: 'Chiudi',
                    buttonsStyling: false
                }).then(function (result) {
                    if (result.value) {
                        self.props.onResolve();
                        setTimeout(() => {
                            self.resetForm();
                        }, 1000)
                    }
                })
            }).catch(() => {
                global.onSetModal({loading: false});
                global.SweetAlert.fire({
                    title: 'Errore',
                    text: (add) ? 'Si è verificato un errore durante la creazione dell\'appuntamento' : 'Si è verificato un errore durante la modifica dell\'appuntamento',
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    }
                });
            })
        }
    }

    render() {
        return (
            <React.Fragment>
                {(this.props.preset) ? <button
                    type="button"
                    className="btn btn-info btn-sm"
                    onClick={() => this.showModal()}>
                    <span style={{display: "flex", flexDirection: "row"}}><i className="mdi mdi-pencil"/>&nbsp;<span className="d-none d-md-block d-lg-block">MODIFICA</span></span>
                </button> : <button
                    type="button"
                    className="btn btn-success add-btn"
                    id="create-btn"
                    onClick={() => this.showModal()}>
                    <i className="ri-add-line align-bottom me-1"></i> CREA APPUNTAMENTO
                </button>}
            </React.Fragment>
        )
    }
}

export default GestAppointment;
