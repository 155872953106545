import React, {Component} from 'react';
import {Card, CardBody, CardHeader, Col, Container, Row, Table, Button, Alert} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import {withRouter, Link} from "react-router-dom";

import {AuthAPI, ContractAPI} from "api";

import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import "react-pdf/dist/esm/Page/AnnotationLayer.css"

import {Loading} from "custom"

const moment = require("moment");

class DraftPreview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loadingPDF: true,
            mode: "waiting",
            referentCell: null,
            url_draft: null,
            error: false
        };
        document.title = "Anteprima contratto | Pubbli Seller";
    }

    componentDidMount() {
        if (typeof this.props.match.params.id_draft === "undefined")
            this.props.history.goBack();

        ContractAPI.checkDraft(this.props.match.params.id_draft).then((result) => {
            this.setState({loading: false});
            if (result.status === "WAITING") {
                this.setState({url_draft: result.url_draft, mode: "preview"});
                global.SweetAlert.fire({
                    icon: 'warning',
                    title: 'Contratto non firmato',
                    text: '',
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                });
            } else if (result.status === "SIGNED_OK") {
                this.setState({url_draft: result.url_draft, mode: "check"});
                global.SweetAlert.fire({
                    icon: 'success',
                    title: 'Contratto firmato correttamente!',
                    text: '',
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                });
            }
        }).catch((err) => {
            this.props.history.goBack();
            global.SweetAlert.fire({
                icon: 'error',
                title: 'Si è verificato un errore',
                text: '',
                customClass: {
                    confirmButton: 'btn btn-success'
                }
            });
        });
    }

    signPDF() {
        this.setState({loading: true});
        ContractAPI.requestSignDraft(this.props.match.params.id_draft).then((result) => {
            this.setState({loading: false, referentCell: result.cell_referent, mode: "waiting"});
            global.SweetAlert.fire({
                icon: 'success',
                title: 'SMS inviato correttamente!',
                text: 'È stato inviato un SMS al numero ' + result.cell_referent + " per la firma del contratto",
                customClass: {
                    confirmButton: 'btn btn-success'
                }
            });
        }).catch((err) => {
            this.setState({loading: false});
            console.error(err);
            global.SweetAlert.fire({
                icon: 'error',
                title: 'Si è verificato un errore',
                text: 'Non è stato possibile inviare l\'SMS per la firma.',
                customClass: {
                    confirmButton: 'btn btn-success'
                }
            });
        })
    }

    checkDraft() {
        ContractAPI.checkDraft(this.props.match.params.id_draft).then((result) => {
            this.setState({loading: false});
            if (result.status === "WAITING") {
                global.SweetAlert.fire({
                    icon: 'warning',
                    title: 'Contratto non firmato',
                    text: '',
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                });
            } else if (result.status === "SIGNED_OK") {
                this.setState({url_draft: result.url_draft, mode: "check"});
                global.SweetAlert.fire({
                    icon: 'success',
                    title: 'Contratto firmato correttamente!',
                    text: '',
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                });
            }
        }).catch((err) => {
            console.error(err);
            global.SweetAlert.fire({
                icon: 'error',
                title: 'Si è verificato un errore',
                text: '',
                customClass: {
                    confirmButton: 'btn btn-success'
                }
            });
        });
    }

    generateContract() {
        this.setState({loading: true});
        ContractAPI.generateContract(this.props.match.params.id_draft).then((contractGenerated) => {
            global.SweetAlert.fire({
                title: 'Contratto convalidato correttamente!',
                text: '',
                icon: 'success',
                allowOutsideClick: false,
                customClass: {
                    confirmButton: 'btn btn-primary'
                },
                confirmButtonText: 'Dettaglio cliente',
                buttonsStyling: false
            }).then(function (result) {
                if (result.value) {
                    AuthAPI.permission().then(() => {
                        window.location.replace('/client/' + contractGenerated.client.id);
                    });
                }
            });
        }).catch((err) => {
            global.SweetAlert.fire({
                icon: 'error',
                title: 'Si è verificato un errore',
                text: err,
                customClass: {
                    confirmButton: 'btn btn-success'
                }
            });
            this.setState({loading: false});
            console.error(err);
        })
    }

    render() {

        const self = this;

        let url_draft = this.state.url_draft;
        console.log(url_draft)

        return (
            <React.Fragment>
                {(this.state.loading) ? <Loading /> : <div className="page-content">
                    <Container fluid>
                        {(this.state.mode === "preview") ? <div>
                            <Row>
                                <Col lg={12}>
                                    <h2>Anteprima Contratto</h2>
                                    <Document file={url_draft} loading={<Loading />} onLoadSuccess={() => this.setState({loadingPDF: false})} error={<Alert color="danger">Si è verificato un errore durante il rendering del contratto</Alert>}>
                                        <Page pageNumber={1} scale={1.5} renderTextLayer={false}/>
                                        <Page pageNumber={2} scale={1.5} renderTextLayer={false}/>
                                    </Document>
                                </Col>
                            </Row>
                            <br/>
                            <Row hidden={this.state.loadingPDF}>
                                <Col lg={12}>
                                    <div className="text-center flex-row">
                                        <button
                                            onClick={() => this.signPDF()}
                                            type="button"
                                            className="btn bg-success text-white m-1">
                                            INVIA SMS PER FIRMA
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </div> : ((this.state.mode === "waiting") ? <div>
                            <Row>
                                <Col lg={12}>
                                    <div className="text-center flex-row">
                                        <h2>Contratto in attesa di verifica</h2>
                                        <p>È stato inviato un SMS al numero <strong>{this.state.referentCell}</strong> per la firma del contratto</p>
                                        <br/>
                                        <i className="mdi mdi-file-document-edit fs-48 text-primary"></i>
                                    </div>
                                </Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col lg={12}>
                                    <div className="text-center flex-row">
                                        <button
                                            onClick={() => this.checkDraft()}
                                            type="button"
                                            className="btn bg-success text-white m-1">
                                            CONTROLLA
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </div> : <div>
                            <Row>
                                <Col lg={12}>
                                    <h2>Contratto Firmato</h2>
                                    <Document file={url_draft} loading={<Loading />} error={<Alert color="danger">Si è verificato un errore durante il rendering del contratto</Alert>}>
                                        <Page pageNumber={1} scale={1.5} renderTextLayer={false}/>
                                        <Page pageNumber={2} scale={1.5} renderTextLayer={false}/>
                                    </Document>
                                </Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col lg={12}>
                                    <div className="text-center flex-row">
                                        <button
                                            onClick={() => this.generateContract()}
                                            type="button"
                                            className="btn bg-success text-white m-1">
                                            CONFERMA CONTRATTO
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </div>)}
                        <br/>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center flex-row cursor-pointer">
                                    <a onClick={() => this.props.history.goBack()}>Annulla</a>
                                </div>
                            </Col>
                        </Row>
                        <br/>
                    </Container>
                </div>}
            </React.Fragment>
        );
    }
}

export default withRouter(DraftPreview);
