let list = function(filter) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');
        let route = "";

        if (typeof filter !== "undefined") {
            route = "?";

            if (typeof filter.search !== "undefined")
                route = route + "search=" + filter.search
        }

        fetch(global.route.client + route, {
            headers: {'x-access-token': hash},
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                // global.checkAuth(responseJson.status);
                console.log('client list', responseJson);
                if (responseJson.status === "LIST_CLIENTS_OK")
                    resolve(responseJson.list);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let detail = function(id) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');
        fetch(global.route.client + "/" + id, {
            headers: {'x-access-token': hash},
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('client detail', responseJson);
                if (responseJson.status === "CLIENT_DETAIL_OK")
                    resolve(responseJson.client);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let add = function(client) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.client, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'PUT',
            body: JSON.stringify({
                business_name: client.business_name,
                name: client.name,
                vat_id: client.vat_id,
                phone: client.phone,
                email: client.email,
                pec: client.pec,
                bank_iban: client.bank_iban,
                fiscal_code: client.fiscal_code,
                unique_code: client.unique_code,
                category: client.category,
                marker: client.marker,
                referents: client.referents
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : console.log(response))
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('client add', responseJson);
                if (responseJson.status === "CLIENT_CREATED")
                    resolve(responseJson.client);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let edit = function(client) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.client, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                id: client.id,
                business_name: client.business_name,
                name: client.name,
                vat_id: client.vat_id,
                phone: client.phone,
                cell: client.cell,
                email: (client.email === null) ? "" : client.email,
                pec: (client.pec === null) ? "" : client.pec,
                bank_iban: (client.bank_iban === null) ? "" : client.bank_iban,
                fiscal_code: client.fiscal_code,
                unique_code: client.unique_code,
                category: client.category,
                marker: {
                    full_address: client.marker.full_address,
                    address: client.marker.address,
                    lat: client.marker.lat,
                    lon: client.marker.lon,
                    city: client.marker.city,
                    region: client.marker.region,
                    province: client.marker.province,
                    cap: client.marker.cap
                }
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                 global.checkAuth(responseJson.status);
                console.log('client edit', responseJson);
                if (responseJson.status === "CLIENT_EDITED")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let remove = function(id) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.client, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'DELETE',
            body: JSON.stringify({
                id: id
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('client delete', responseJson);
                if (responseJson.status === "CLIENT_DELETED")
                    resolve(responseJson.client);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

const ClientAPI = {
    list: list,
    detail: detail,
    add: add,
    edit: edit,
    remove: remove
};

module.exports = ClientAPI;
