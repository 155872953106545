
let isValidEmail = function (text) {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return reg.test(text);
};

let isValidPassword = function (text) {
    const reg = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})");
    return reg.test(text);
};

let isValidPhone = function (text) {
    let reg = /^\+(?:[0-9]●?){8,12}[0-9]$/im;
    // let reg = /^[0-9]{5,10}$/;
    return reg.test(text);
};

let isValidCell = function (text) {
    let reg = /^\+[1-9]{1}[0-9]{3,14}$/im;
    return reg.test(text);
};

let isValidFiscalCode = function (text) {
    let reg = /^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/i;
    return reg.test(text);
};

let isValidIban = function (text) {
    let reg = /^(it|IT)[0-9]{2}[A-Za-z][0-9]{10}[0-9A-Za-z]{12}$/gm;
    return reg.test(text);
};


let isPiva = function (text) {
    let reg = /^[0-9]{11}$/;
    return reg.test(text);
};

let isValidSwiftCode = function (text) {
    let reg = /^([a-zA-Z]){4}([a-zA-Z]){2}([0-9a-zA-Z]){2}([0-9a-zA-Z]{3})?$/;
    return reg.test(text);
};

let isPercentage = function (number) {
    if (isNaN(number))
        return false;

    return (number >= 0 && number <= 100);
};


let getValueMoneyLabel = function (number) {
    if (number === null) {
        return '0,00 €';
    }

    if (typeof number !== 'number') {
        return '0,00 €';
    }
    return String(number.toFixed(2)).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') +  ' €';
};

let isValidUrl = function (text) {
    let reg = new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?");
    return reg.test(text);
};

let isUserLoggedIn = function () {
    return (localStorage.getItem('hash') !== null);
}

let getUserData = function () {
    return (JSON.parse(sessionStorage.getItem('userData')));
}

let checkConnection = function () {
    return new Promise(function (resolve, reject) {
        let condition = navigator.onLine ? 'online' : 'offline';
        if (condition === 'online') {
            fetch(global.route.test, {
                method: 'GET'
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson.status === 'TEST_OK')
                        resolve();
                    else
                        reject();
                })
                .catch((e) => {
                    reject();
                });

        } else {
            reject();
        }
    });
}


let getRoleLabel = function (role) {
    switch(role) {
        case "ADMIN":
            return "AMMINISTRATORE";
        case "AGENT":
            return "AGENTE";
        default:
            return "--";
    }
}

let getReferentForm = function (clientId, row) {
    return {
        id_client_referent: (typeof clientId !== "undefined") ? clientId : null,
        id: (typeof row !== "undefined") ? row.cells[0].data : null,
        firstname: (typeof row !== "undefined") ? row.cells[1].data : "",
        lastname: (typeof row !== "undefined") ? row.cells[2].data : "",
        role: (typeof row !== "undefined") ? row.cells[3].data : "",
        email: (typeof row !== "undefined") ? row.cells[4].data : "",
        phone: (typeof row !== "undefined") ? row.cells[5].data : "",
        cell: (typeof row !== "undefined") ? row.cells[6].data : ""
    }
}


let getAlloweedPermissions = function () {
    return [
        {key: "ADMIN", label: "AMMINISTRATORI"},
        {key: "AGENT", label: "AGENTI"},
        {key: "MONITOR", label: "PUNTI DI DIFFUSIONE"},
        {key: "CLIENT", label: "CLIENTI"},
        {key: "CAMPAIGN", label: "CAMPAGNE"},
        {key: "APPOINTMENT", label: "APPUNTAMENTI"},
        {key: "CONTRACT", label: "CONTRATTI"},
        {key: "REPORT", label: "SEGNALAZIONI"},
        {key: "TICKET", label: "TICKETS"}
    ]
}

let getCategoriesClient = function () {
    return [
        {value: "ABBIGLIAMENTO", label: "ABBIGLIAMENTO"},
        {value: "ALIMENTARI", label: "ALIMENTARI"},
        {value: "ANIMALI E AGRICOLTURA", label: "ANIMALI E AGRICOLTURA"},
        {value: "ARREDAMENTO", label: "ARREDAMENTO"},
        {value: "ASSICURAZIONI E BANCHE", label: "ASSICURAZIONI E BANCHE"},
        {value: "ASSOCIAZIONE", label: "ASSOCIAZIONE"},
        {value: "AUTOMOTIVE", label: "AUTOMOTIVE"},
        {value: "BELLEZZA E BENESSERE", label: "BELLEZZA E BENESSERE"},
        {value: "COMMERCIO AL DETTAGLIO", label: "COMMERCIO AL DETTAGLIO"},
        {value: "COMMERCIO ALL'INGROSSO", label: "COMMERCIO ALL'INGROSSO"},
        {value: "COMUNICAZIONE GRAFICA STAMPA", label: "COMUNICAZIONE GRAFICA STAMPA"},
        {value: "DISTRIBUZIONE", label: "DISTRIBUZIONE"},
        {value: "EDILIZIA ED IMPIANTISTICA", label: "EDILIZIA ED IMPIANTISTICA"},
        {value: "FOOD", label: "FOOD"},
        {value: "FORMAZIONE", label: "FORMAZIONE"},
        {value: "IMMOBILIARE", label: "IMMOBILIARE"},
        {value: "INFORMATICA ED ELETTRONICA", label: "INFORMATICA ED ELETTRONICA"},
        {value: "INTRATTENIMENTO", label: "INTRATTENIMENTO"},
        {value: "LOGISTICA TRASPORTI VIAGGI", label: "LOGISTICA TRASPORTI VIAGGI"},
        {value: "MEDICINA E SALUTE", label: "MEDICINA E SALUTE"},
        {value: "ONORANZE FUNEBRI", label: "ONORANZE FUNEBRI"},
        {value: "PRODUZIONE", label: "PRODUZIONE"},
        {value: "RISTORAZIONE", label: "RISTORAZIONE"},
        {value: "SERVIZI", label: "SERVIZI"},
        {value: "SPORT", label: "SPORT"}
    ]
}


const Utils = {
    isValidEmail: isValidEmail,
    isValidPassword: isValidPassword,
    isValidPhone: isValidPhone,
    isValidCell: isValidCell,
    isValidFiscalCode: isValidFiscalCode,
    isValidIban: isValidIban,
    isValidSwiftCode: isValidSwiftCode,
    isPiva: isPiva,
    isValidUrl: isValidUrl,
    isPercentage: isPercentage,
    getValueMoneyLabel: getValueMoneyLabel,
    isUserLoggedIn: isUserLoggedIn,
    checkConnection: checkConnection,
    getRoleLabel: getRoleLabel,
    getReferentForm: getReferentForm,
    getUserData: getUserData,
    getAlloweedPermissions: getAlloweedPermissions,
    getCategoriesClient: getCategoriesClient
};

module.exports = Utils;
