import _GeoAPI from './GeoAPI';
import _AuthAPI from './AuthAPI';
import _UserAPI from './UserAPI';
import _AdminAPI from './AdminAPI';
import _AgentAPI from './AgentAPI';
import _MonitorAPI from './MonitorAPI';
import _ClientAPI from './ClientAPI';
import _ReferentAPI from './ReferentAPI';
import _ContractAPI from './ContractAPI';
import _CampaignAPI from './CampaignAPI';
import _CampaignCodeAPI from './CampaignCodeAPI';
import _SlotAPI from './SlotAPI';
import _MarkerAPI from './MarkerAPI';
import _ReportAPI from './ReportAPI';
import _AppointmentAPI from './AppointmentAPI';

export const GeoAPI = _GeoAPI;
export const AuthAPI = _AuthAPI;
export const UserAPI = _UserAPI;
export const AdminAPI = _AdminAPI;
export const AgentAPI = _AgentAPI;
export const MonitorAPI = _MonitorAPI;
export const ClientAPI = _ClientAPI;
export const ReferentAPI = _ReferentAPI;
export const ContractAPI = _ContractAPI;
export const CampaignAPI = _CampaignAPI;
export const CampaignCodeAPI = _CampaignCodeAPI;
export const SlotAPI = _SlotAPI;
export const MarkerAPI = _MarkerAPI;
export const ReportAPI = _ReportAPI;
export const AppointmentAPI = _AppointmentAPI;

