import React, {Component} from 'react';
import {
    Alert,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    FormFeedback,
    FormText,
    Input,
    Label,
    Row,
    Table
} from 'reactstrap';
import {withRouter, Link} from "react-router-dom";

import {Utils, GeoForm, Loading} from "custom";
import {CampaignCodeAPI, ClientAPI, ReportAPI} from "api";

import Select from "react-select";

class FormContract extends Component {

    constructor(props) {
        super(props);
        this.state = {
            contract: {
                campaignNote: "",
                note: "",
                payment_cash: '',
                payment_cheque: '',
                payment_bank: '',
                payment_other: '',
                showRate: false
            },
            price_graphic: Number(process.env.REACT_APP_PRICE_GRAPHIC),
            campaignCode: this.props.campaignCode,
            errors: false
        };
        document.title = "Nuovo Contratto | Pubbli Seller";
    }

    calcCampaign () {
        let campaignCode = this.state.campaignCode;
        let price_graphic = (this.props.refClient !== "client") ? this.state.price_graphic : 0;
        let vat_rate = process.env.REACT_APP_IVA_VALUE;

        let deposit = campaignCode.deposit;
        let depositVat = (campaignCode.deposit + price_graphic) * vat_rate / 100;
        let depositTotal = deposit + price_graphic + depositVat;

        let rate = campaignCode.price_month * campaignCode.duration;
        let rateVat = rate * vat_rate / 100;
        let rateTotal = rate + rateVat;

        let monthTotal = campaignCode.price_month + (campaignCode.price_month * vat_rate / 100);

        return {
            campaignCode: campaignCode,
            price_graphic: price_graphic,
            vat_rate: vat_rate,
            deposit: deposit,
            depositVat: depositVat,
            depositTotal: depositTotal,
            rate: rate,
            rateVat: rateVat,
            rateTotal: rateTotal,
            monthTotal: monthTotal
        }
    }

    changeForm(key, text) {
        const contract = this.state.contract;

        if (key === 'payment_cash' || key === 'payment_cheque' || key === 'payment_bank' || key === 'payment_other')
            if (isNaN(text))
                return;

        contract[key] = text;
        this.setState({contract: contract});
    }

    next() {
        const contract = this.state.contract;
        let costCampaign = this.calcCampaign();

        let error = {};

        contract.payment_cash = (contract.payment_cash === "") ? 0 : Number(contract.payment_cash);
        contract.payment_bank = (contract.payment_bank === "") ? 0 : Number(contract.payment_bank);
        contract.payment_cheque = (contract.payment_cheque === "") ? 0 : Number(contract.payment_cheque);
        contract.payment_other = (contract.payment_other === "") ? 0 : Number(contract.payment_other);

        let totalDeposit = contract.payment_cash + contract.payment_bank + contract.payment_cheque + contract.payment_other;

        if (costCampaign.depositTotal > totalDeposit)
            error.deposit = "Non è stato raggiunto il valore minimo di acconto di " + Utils.getValueMoneyLabel(costCampaign.depositTotal);

        if ((costCampaign.depositTotal + costCampaign.rateTotal) < totalDeposit)
            error.deposit = "È stato superato il costo totale della campagna";

        this.setState({errors: error});

        if (Object.keys(error).length === 0) {
            this.props.onNext(contract)
        }
    }

    renderPaymentTable() {

        let costCampaign = this.calcCampaign();

        return <Row>
            <Col sm={12} md={6}>
                <Table>
                    <tbody>
                    <tr style={{backgroundColor: '#ffe6f9'}}>
                        <td>Imponibile anticipo</td>
                        <td className="text-end text-nowrap"><small>{Utils.getValueMoneyLabel(costCampaign.deposit)}</small></td>
                    </tr>
                    <tr style={{backgroundColor: '#ffe6f9'}}>
                        <td>Impianto grafico</td>
                        <td className="text-end text-nowrap"><small>{Utils.getValueMoneyLabel(costCampaign.price_graphic)}</small></td>
                    </tr>
                    <tr style={{backgroundColor: '#ffe6f9'}}>
                        <td>IVA ({costCampaign.vat_rate} %)</td>
                        <td className="text-end text-nowrap"><small>{Utils.getValueMoneyLabel(costCampaign.depositVat)}</small></td>
                    </tr>
                    <tr style={{backgroundColor: '#ffe6f9'}}>
                        <th>Totale anticipo all'ordine</th>
                        <th className="text-end text-nowrap">{Utils.getValueMoneyLabel(costCampaign.depositTotal)}</th>
                    </tr>
                    </tbody>
                </Table>
            </Col>
            <Col sm={12} md={6}>
                <Table>
                    <tbody>
                    {(this.state.contract.showRate) ? <>
                        <tr style={{backgroundColor: '#fff7d2'}}>
                            <td>Imponibile da dilazionare</td>
                            <td className="text-end text-nowrap"><small>{Utils.getValueMoneyLabel(costCampaign.rate)}</small></td>
                        </tr>
                        <tr style={{backgroundColor: '#fff7d2'}}>
                            <td>IVA ({costCampaign.vat_rate} %)</td>
                            <td className="text-end text-nowrap"><small>{Utils.getValueMoneyLabel(costCampaign.rateVat)}</small></td>
                        </tr>
                        <tr style={{backgroundColor: '#fff7d2'}}>
                            <th>Totale da dilazionare</th>
                            <th className="text-end text-nowrap">{Utils.getValueMoneyLabel(costCampaign.rateTotal)}</th>
                        </tr>
                        <tr>
                            <button type="button" className="btn btn-sm bg-warning text-white m-1" onClick={() => this.changeForm("showRate", false)}>
                                Nascondi dilazionamento
                            </button>
                        </tr>
                    </> : <div>
                        <button type="button" className="btn btn-sm bg-warning text-white m-1" onClick={() => this.changeForm("showRate", true)}>
                            Mostra dilazionamento
                        </button>
                    </div>}
                    <tr><th></th><th></th></tr>
                    <tr style={{backgroundColor: '#fff7d2'}}>
                        <th>Numero rate</th>
                        <th className="text-end">{costCampaign.campaignCode.duration}</th>
                    </tr>
                    <tr style={{backgroundColor: '#fff7d2'}}>
                        <th>Rata mensile ivata</th>
                        <th className="text-end text-nowrap">{Utils.getValueMoneyLabel(costCampaign.monthTotal)}</th>
                    </tr>

                    </tbody>
                </Table>
            </Col>
        </Row>;

    }

    getTotalDeposit() {
        let payment_cash = (this.state.contract.payment_cash === '') ? 0 : Number(this.state.contract.payment_cash);
        let payment_bank = (this.state.contract.payment_bank === '') ? 0 : Number(this.state.contract.payment_bank);
        let payment_cheque = (this.state.contract.payment_cheque === '') ? 0 : Number(this.state.contract.payment_cheque);
        let payment_other = (this.state.contract.payment_other === '') ? 0 : Number(this.state.contract.payment_other);

        return (payment_cash + payment_bank + payment_cheque + payment_other);
    }

    render() {
        let errors = this.state.errors;
        let form = this.state.contract;

        return (
            <React.Fragment>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                <h2>Contratto</h2>
                                {this.renderPaymentTable()}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                <h2>Pagamento Acconto</h2>
                                <Row>
                                    <Col md="6">
                                        <div className='mb-2'>
                                            <Label className='form-label'>
                                                Contanti
                                            </Label>
                                            <Input type="text" value={form.payment_cash}
                                                   invalid={Boolean(errors.payment_cash)}
                                                   onChange={(e) => this.changeForm("payment_cash", e.target.value)}/>
                                            <FormFeedback>{errors.payment_cash}</FormFeedback>
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className='mb-2'>
                                            <Label className='form-label'>
                                                Assegno
                                            </Label>
                                            <Input type="text" value={form.payment_cheque}
                                                   invalid={Boolean(errors.payment_cheque)}
                                                   onChange={(e) => this.changeForm("payment_cheque", e.target.value)}/>
                                            <FormFeedback>{errors.payment_cheque}</FormFeedback>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6">
                                        <div className='mb-2'>
                                            <Label className='form-label'>
                                                Bonifico bancario
                                            </Label>
                                            <Input type="text" value={form.payment_bank}
                                                   invalid={Boolean(errors.payment_bank)}
                                                   onChange={(e) => this.changeForm("payment_bank", e.target.value)}/>
                                            <FormFeedback>{errors.payment_bank}</FormFeedback>
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className='mb-2'>
                                            <Label className='form-label'>
                                                Altro
                                            </Label>
                                            <Input type="text" value={form.payment_other}
                                                   invalid={Boolean(errors.payment_other)}
                                                   onChange={(e) => this.changeForm("payment_other", e.target.value)}/>
                                            <FormFeedback>{errors.payment_other}</FormFeedback>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <h5>Totale Acconto: {Utils.getValueMoneyLabel(this.getTotalDeposit())}</h5>
                                        {(errors.deposit) ? <Alert color={"danger"} >{errors.deposit}</Alert> : null}
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                <h2>Note</h2>
                                <Row>
                                    <Col md="6">
                                        <div className='mb-2'>
                                            <Label className='form-label'>
                                                Note comparto grafico
                                            </Label>
                                            <Input type="textarea" value={form.campaignNote}
                                                   onChange={(e) => this.changeForm("campaignNote", e.target.value)}/>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6">
                                        <div className='mb-2'>
                                            <Label className='form-label'>
                                                Note contratto
                                            </Label>
                                            <Input type="textarea" value={form.note}
                                                   onChange={(e) => this.changeForm("note", e.target.value)}/>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <div className="text-center flex-row">
                            <button type="button" className="btn bg-primary text-white m-1" onClick={() => this.next()}>
                                PROSEGUI
                            </button>
                        </div>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default withRouter(FormContract);
