
let server = process.env.REACT_APP_API_URL;

let frontendRoute = process.env.REACT_APP_FRONTEND_URL;

let productionMode = (process.env.NODE_ENV === "production"); // valorizzare in TRUE per produzione
let versionApp = process.env.REACT_APP_VERSION; //Valorizzare per cambiare versione applicazione
let emailAssistance = process.env.REACT_APP_API_EMAIL_ASSISTANCE; //email assistenza
let route = {
  test: '/test',

  // geo service
  autocompleteGeo: '/geo/autocomplete',
  detailGeo: '/geo/detail',
  geoGps: '/geo/gps',

  // auth
  checkToken: '/token/check',
  permission: '/auth/permission',
  login: '/auth/signin',
  credentialEdit: '/auth/changepsw',
  resetPassword: '/auth/forgotpsw',
  completeResetPassword: '/auth/forgotpsw/reset',
  verifyOTP: '/auth/verifyotp',
  resendOTP: '/auth/verifyotp',

  // admin
  admin: '/admin',

  // agent
  agent: '/agent',

  // user
  user: "/user",
  resendSignup: "/user/resendtoken",
  signup: "/user/signup",

  // monitor
  monitor: "/monitor",
  monitorUser: "/monitor/user",
  monitorLink: "/monitor/link",

  monitorADS: "/monitor/list/ads",

  // client
  client: "/client",

  // referent
  referent: "/referent",

  // contract
  contract: "/contract",
  contractDraftCheck: "/contract/draft",
  contractDraft: "/contract/draft/generate",
  contractRequestSignDraft: "/contract/draft/sign/request",
  contractSendSignDraft : "/contract/draft/sign/send",
  contractGenerate: "/contract/generate",
  contractExport: "/contract/export",

  removeDraft: "/draft/delete",

  // campaign
  campaign: "/campaign",
  campaignsCodes: "/campaigncode",

  // slot
  slot: "/slot",

  // marker
  marker: "/marker",
  markerMonitor: "/marker/monitor",

  // report
  report: "/report",
  listReportMonitor: "/monitor/list/report",
  createReportMonitor: "/monitor/create/report",

  // appointment
  appointment: "/appointment"

};



for (var i in route) {
  route[i] = server + route[i];
}
global.server = server;
global.frontend = frontendRoute;
global.productionMode = productionMode;
global.versionApp = versionApp;
global.emailAssistance = emailAssistance;
global.route = route;
module.export = route;
