import React, { Component } from 'react';
import {Alert, Badge, Card, CardHeader, CardBody, Col, Container, Row, Nav, NavItem, NavLink, TabPane, TabContent, Table, Button} from 'reactstrap';
import {withRouter, Redirect, Link} from "react-router-dom";

import {AgentAPI} from "api";

import {Loading, GoogleMap, GestMonitor} from "custom";

import profileBg from '../../assets/images/agent-bg.png';
import avatarAgent from '../../assets/images/avatar/agent.png';

// import TabPosition from "./TabsGest/TabPosition";
// import TabSlot from "./TabsGest/TabSlot";
// import TabTicket from "./TabsGest/TabTicket";

const moment = require("moment");

class AgentGest extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: false,
            agent: null,
            tabActive: 1
        };
        document.title ="Dettagli Agente | Pubbli Seller";
    }

    componentDidMount() {
        this.getDetailAgent();
    }

    getDetailAgent() {
        AgentAPI.detail(this.props.match.params.id).then((agent) => {
            this.setState({agent: agent, loading: false})
        }).catch((err) => {
            console.error(err);
            this.setState({error: true})
        })
    }

    deleteAgent() {
        const self = this;
        global.SweetAlert.fire({
            title: "Elimina utente",
            text: 'Attenzione, eliminando l\'agente annullerai le associazioni ad eventuali contratti, inoltre saranno eliminati appuntamenti e trattative ad esso assegnato. Vuoi procedere?',
            icon: 'danger',
            showCancelButton: true,
            confirmButtonText: 'Si, elimina',
            cancelButtonText: 'Annulla',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ms-1'
            },
            buttonsStyling: false,
            showLoaderOnConfirm: true,
            preConfirm: function() {
                return new Promise(function(resolve, reject) {
                    AgentAPI.remove(self.state.agent.id).then(() => {
                        resolve();
                    }).catch(() => {
                        global.SweetAlert.fire({
                            icon: 'error',
                            title: 'Si è verificato un errore :(',
                            text: 'Purtroppo qualcosa è andato storto e non è stato possibile eliminare l\'agente. Riprova più tardi.',
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        });
                        reject()
                    })
                });
            },
        }).then(function (result) {
            if (result.value) {
                global.SweetAlert.fire({
                    icon: 'success',
                    title: 'Agente eliminato!',
                    text: 'Agente eliminato correttamente.',
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                });
                window.location.replace("/agent");
            }
        })
    }


    render() {

        if (this.state.error) {
            return <React.Fragment>
                <div className="page-content">
                    <Alert color="danger" >Si è verificato un errore durante il recupero dei dettagli dell'agente.</Alert>
                </div>
            </React.Fragment>
        }

        const agent = this.state.agent;

        return (
            <React.Fragment>
                {(this.state.loading) ? <Loading /> : <div className="page-content">
                    <Container fluid>
                        <div className="profile-foreground position-relative mx-n4 mt-n4">
                            <div className="profile-wid-bg">
                                <img src={profileBg} alt="" className="profile-wid-img" />
                            </div>
                        </div>
                        <div className="pt-4 mb-4 mb-lg-3 pb-lg-4">
                            <Row className="g-4">
                                <div className="col-auto">
                                    <div className="avatar-lg">
                                        <img src={avatarAgent} alt="user-img"
                                             className="img-thumbnail rounded-circle" />
                                    </div>
                                </div>
                                <Col>
                                    <Button onClick={() => this.props.history.goBack()} className="btn btn-sm btn-info" ><i className="ri-arrow-left-circle-line fs-5 text-white-75 align-middle" /> Torna alla lista</Button>
                                    <div className="p-2">
                                        <h3 className="text-white mb-1">{agent.name}</h3>
                                        <p className="text-white-75">Agente</p>
                                        <div className="hstack text-white-50 gap-1">
                                            <div className="me-2">
                                                <i className="ri-calendar-2-fill me-1 text-white-75 fs-16 align-middle"></i>Attivo da: {moment(agent.createdAt).format("DD/MM/YYYY")}
                                            </div>
                                        </div>
                                        <div className="hstack text-white-50 gap-1">
                                            <div className="me-2">
                                                {/*<i className="ri-bookmark-2-fill me-1 text-white-75 fs-16 align-middle"></i>Disponibilità: <span className={(monitor.available_slots < 10) ? "text-danger" : undefined}>{monitor.available_slots}</span> / 32*/}
                                            </div>
                                        </div>
                                    </div>
                                </Col>

                                <Col xs={12} className="col-lg-auto order-last order-lg-0">
                                    <Row className="text text-white-50 text-center">
                                        <Col lg={4} xs={3}>
                                            <div className="p-2">
                                                <h5 className="text-white mb-1">Stato</h5>
                                                {/*{(monitor.status === "FULL") ? <Badge color="danger" pill>Pieno</Badge> : ((monitor.status === "LOW") ? <Badge color="warning" pill>In esaurimento</Badge> : <Badge color="success" pill>Libero</Badge>)}*/}
                                            </div>
                                        </Col>
                                        <Col lg={4} xs={3}>
                                            <div className="p-2">
                                                <h5 className="text-white mb-1">Lobby</h5>
                                                {/*<p className="fs-16 mb-0">{monitor.medium_lobby}</p>*/}
                                            </div>
                                        </Col>
                                        <Col lg={4} xs={3}>
                                            <div className="p-2">
                                                <h5 className="text-white mb-1">ID</h5>
                                                {/*<p className="fs-16 mb-0">{monitor.id}</p>*/}
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>

                        <Row>
                            <Col lg={12}>
                                <div>
                                    <div className="d-flex">
                                        <Nav pills className="animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1"
                                             role="tablist">
                                            <NavItem>
                                                <NavLink
                                                    className={ "cursor-pointer " + ((this.state.tabActive === 1) ? "fs-16 bg-white text-dark" : null)}
                                                    onClick={() => this.setState({tabActive: 1}) }
                                                >
                                                    <i className="ri-map-2-fill d-inline-block d-md-none"></i> <span
                                                    className="d-none d-md-inline-block">Dettagli</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={ "cursor-pointer " + ((this.state.tabActive === 2) ? "fs-16 bg-white text-dark" : null)}
                                                    onClick={() => this.setState({tabActive: 2}) }
                                                >
                                                    <i className="ri-list-unordered d-inline-block d-md-none"></i> <span
                                                    className="d-none d-md-inline-block">Clienti</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={ "cursor-pointer " + ((this.state.tabActive === 2) ? "fs-16 bg-white text-dark" : null)}
                                                    onClick={() => this.setState({tabActive: 2}) }
                                                >
                                                    <i className="ri-list-unordered d-inline-block d-md-none"></i> <span
                                                    className="d-none d-md-inline-block">Contratti</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={ "cursor-pointer " + ((this.state.tabActive === 2) ? "fs-16 bg-white text-dark" : null)}
                                                    onClick={() => this.setState({tabActive: 2}) }
                                                >
                                                    <i className="ri-list-unordered d-inline-block d-md-none"></i> <span
                                                    className="d-none d-md-inline-block">Calendario</span>
                                                </NavLink>
                                            </NavItem>
                                            {/*<NavItem>*/}
                                            {/*    <NavLink*/}
                                            {/*        className={ "cursor-pointer " + ((this.state.tabActive === 3) ? "fs-16 bg-white text-dark" : null)}*/}
                                            {/*        onClick={() => this.setState({tabActive: 3}) }*/}
                                            {/*    >*/}
                                            {/*        <i className="ri-settings-2-fill d-inline-block d-md-none"></i> <span*/}
                                            {/*        className="d-none d-md-inline-block">Link</span>*/}
                                            {/*    </NavLink>*/}
                                            {/*</NavItem>*/}
                                            {/*<NavItem>*/}
                                            {/*    <NavLink*/}
                                            {/*        className={ "cursor-pointer " + ((this.state.tabActive === 4) ? "fs-16 bg-white text-dark" : null)}*/}
                                            {/*        onClick={() => this.setState({tabActive: 4}) }*/}
                                            {/*    >*/}
                                            {/*        <i className="ri-settings-2-fill d-inline-block d-md-none"></i> <span*/}
                                            {/*        className="d-none d-md-inline-block">Foto</span>*/}
                                            {/*    </NavLink>*/}
                                            {/*</NavItem>*/}
                                            {/*<NavItem>*/}
                                            {/*    <NavLink*/}
                                            {/*        className={ "cursor-pointer " + ((this.state.tabActive === 5) ? "fs-16 bg-white text-dark" : null)}*/}
                                            {/*        onClick={() => this.setState({tabActive: 5}) }*/}
                                            {/*    >*/}
                                            {/*        <i className="ri-settings-2-fill d-inline-block d-md-none"></i> <span*/}
                                            {/*        className="d-none d-md-inline-block">Ticket</span>*/}
                                            {/*    </NavLink>*/}
                                            {/*</NavItem>*/}
                                        </Nav>
                                        <div className="flex-shrink-0">
                                            {/*<GestAgent preset={agent} onResolve={this.getDetailMonitor.bind(this)}/>*/}
                                        </div>
                                    </div>

                                    <TabContent activeTab={String(this.state.tabActive)} className="pt-4">
                                        <TabPane tabId="1">
                                            {/*<TabPosition monitor={monitor} />*/}
                                        </TabPane>
                                        <TabPane tabId="2">
                                            {/*<TabSlot monitor={monitor} onReload={this.getDetailMonitor.bind(this)}/>*/}
                                        </TabPane>
                                        <TabPane tabId="3">
                                            {/*<TabTicket monitor={monitor} />*/}
                                        </TabPane>
                                    </TabContent>

                                    <div className="text-center" style={{margin: 10}}>
                                        <button
                                            type="button"
                                            className="btn btn-danger"
                                            onClick={() => this.deleteAgent()}>
                                            <i className="ri-delete-bin-2-fill align-bottom"></i> Elimina agente
                                        </button>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                    </Container>
                </div>}
            </React.Fragment>
        );
    }
}

export default withRouter(AgentGest);
