import React, {Fragment} from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Alert,
    Row,
    Col,
    Label,
    FormFeedback,
    Input, Container, Card, CardHeader, CardBody, Table, Badge
} from 'reactstrap';

import {GeoForm, GestReferent, Loading, Utils} from "custom";
import {CampaignAPI, SlotAPI} from "api";
import {Link} from "react-router-dom";
import Select from "react-select";
import {debounce} from "lodash";
const moment = require("moment");

class GestSlot extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            campaigns: [],
            loading: false,
            errors: false
        };
    }

    getStatusCampaign(status) {
        switch(status) {
            case "ACTIVE":
                return <Input className="bg-success text-white" disabled type="text" value={"ATTIVO"}/>
            case "WAITING":
                return <Input className="bg-warning text-white" disabled type="text" value={"IN ATTESA"}/>
            case "SUSPENDED":
                return <Input className="bg-warning text-white" disabled type="text" value={"SOSPESA"}/>
            case "CANCELED":
                return <Input className="bg-danger text-white" disabled type="text" value={"CANCELLATA"}/>
        }
    }

    async editSlot(form) {

        console.log(form);
        console.log(this.props.selected);

        let selected = this.props.selected;

        global.onSetModal({loading: true});

        try {
            for (let i = 0; i < selected.length; i++) {
                form.id = selected[i];
                await SlotAPI.edit(form);
            }

            global.onSetModal({loading: false, content: "", isOpen: false});
            this.props.onResolve()
            global.SweetAlert.fire({
                title: 'Palinsesto modificato',
                text: 'Il palinsesto è stato modificato correttamente.',
                icon: 'success',
                allowOutsideClick: false,
                customClass: {
                    confirmButton: 'btn btn-primary'
                },
                confirmButtonText: 'Chiudi',
                buttonsStyling: false
            });
        } catch (e) {
            console.error(e)
            global.onSetModal({loading: false});
            global.SweetAlert.fire({
                title: 'Errore',
                text: 'Si è verificato un errore durante la modifica dello slot',
                icon: 'error',
                customClass: {
                    confirmButton: 'btn btn-primary'
                }
            });
        }
    }

    render() {
        const self = this;

        const onSearchCampaign = debounce((search) => {
            if (search === "")
                return;
            this.setState({loading: true});
            CampaignAPI.list({search: search}).then((list) => {
                let options = [];
                for (let i = 0; i < list.length; i++) {
                    options.push({value: list[i].id, label: list[i].name + " - " + list[i].client.name})
                }
                self.setState({loading: false, campaigns: options});
            }).catch(() => {
                self.setState({loading: false});
            });
        }, 500);

        return (<React.Fragment>
            <div>
                <Card className="text-center">
                    <CardHeader>
                        <h5><i className="ri-rocket-fill align-bottom me-1" style={{margin: 10}}></i> CAMPAGNA</h5>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col className="m-3">
                                <Select
                                    placeholder={"Cerca campagna..."}
                                    onChange={(selectedOption, triggeredAction) => {
                                        self.editSlot({
                                            reserved: true,
                                            id_campaign_slot: selectedOption.value
                                        });
                                    }}
                                    onInputChange={(search) => onSearchCampaign(search)}
                                    options={this.state.campaigns}
                                    isLoading={this.state.loading}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <button
                                    type="button"
                                    className="btn btn-success m-1" onClick={() => this.editSlot({
                                    reserved: false,
                                    id_campaign_slot: null
                                })}>
                                    LIBERA SLOT
                                </button>
                            </Col>
                            <Col>
                                <button
                                    type="button"
                                    className="btn bg-dark text-white m-1" onClick={() => this.editSlot({
                                    reserved: true,
                                    id_campaign_slot: null
                                })}>
                                    RISERVA SLOT
                                </button>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>







                {/*{(slot.reserved && slot.campaign !== null) ? <Card className="text-center">*/}
                {/*    <CardHeader>*/}
                {/*        <h5><i className="ri-rocket-fill align-bottom me-1" style={{margin: 10}}></i> CAMPAGNA</h5>*/}
                {/*    </CardHeader>*/}
                {/*    <CardBody>*/}
                {/*        <Row>*/}
                {/*            <Col md="6">*/}
                {/*                <div className='mb-2'>*/}
                {/*                    <Label className='form-label'>*/}
                {/*                        Data inizio*/}
                {/*                    </Label>*/}
                {/*                    <Input disabled type="text" value={slot.campaign.date_start}/>*/}
                {/*                </div>*/}
                {/*            </Col>*/}
                {/*            <Col md="6">*/}
                {/*                <div className='mb-2'>*/}
                {/*                    <Label className='form-label'>*/}
                {/*                        Data fine*/}
                {/*                    </Label>*/}
                {/*                    <Input disabled type="text" value={slot.campaign.date_end}/>*/}
                {/*                </div>*/}
                {/*            </Col>*/}
                {/*        </Row>*/}
                {/*        <Row>*/}
                {/*            <Col md="6">*/}
                {/*                <div className='mb-2'>*/}
                {/*                    <Label className='form-label'>*/}
                {/*                        Nome*/}
                {/*                    </Label>*/}
                {/*                    <Link to={"campaign/" + slot.campaign.id}><Input className="cursor-pointer" disabled type="text" value={slot.campaign.name}/></Link>*/}
                {/*                </div>*/}
                {/*            </Col>*/}
                {/*            <Col md="6">*/}
                {/*                <div className='mb-2'>*/}
                {/*                    <Label className='form-label'>*/}
                {/*                        Stato*/}
                {/*                    </Label>*/}
                {/*                    {this.getStatusCampaign(slot.campaign.status)}*/}
                {/*                </div>*/}
                {/*            </Col>*/}
                {/*        </Row>*/}
                {/*        <Row>*/}
                {/*            <Col>*/}
                {/*                <Col md="12">*/}
                {/*                    <div className='mb-2'>*/}
                {/*                        <Label className='form-label'>*/}
                {/*                            Cliente*/}
                {/*                        </Label>*/}
                {/*                        <Link to={"/client/" + slot.client.id} onClick={() => global.onSetModal({isOpen: false, content: ""})}><Input className="cursor-pointer" disabled type="text" value={slot.client.name}/></Link>*/}
                {/*                    </div>*/}
                {/*                </Col>*/}
                {/*            </Col>*/}
                {/*        </Row>*/}
                {/*        <Row>*/}
                {/*            <Col>*/}
                {/*                <button*/}
                {/*                    type="button"*/}
                {/*                    className="btn btn-success m-1" onClick={() => this.editSlot({*/}
                {/*                    id: slot.id,*/}
                {/*                    reserved: false,*/}
                {/*                    id_campaign_slot: null*/}
                {/*                })}>*/}
                {/*                    LIBERA SLOT*/}
                {/*                </button>*/}
                {/*            </Col>*/}
                {/*            <Col>*/}
                {/*                <button*/}
                {/*                    type="button"*/}
                {/*                    className="btn bg-grey m-1" onClick={() => this.editSlot({*/}
                {/*                    id: slot.id,*/}
                {/*                    reserved: true,*/}
                {/*                    id_campaign_slot: null*/}
                {/*                })}>*/}
                {/*                    RISERVA SLOT*/}
                {/*                </button>*/}
                {/*            </Col>*/}
                {/*        </Row>*/}
                {/*    </CardBody>*/}
                {/*</Card> : <Card className="text-center">*/}
                {/*    <CardBody>*/}
                {/*        <Row>*/}
                {/*            <Col className="m-3">*/}
                {/*                <Select*/}
                {/*                    placeholder={"Cerca campagna..."}*/}
                {/*                    onChange={(selectedOption, triggeredAction) => {*/}
                {/*                        self.editSlot({*/}
                {/*                            id: slot.id,*/}
                {/*                            reserved: true,*/}
                {/*                            id_campaign_slot: selectedOption.value*/}
                {/*                        });*/}
                {/*                    }}*/}
                {/*                    onInputChange={(search) => onSearchCampaign(search)}*/}
                {/*                    options={this.state.campaigns}*/}
                {/*                    isLoading={this.state.loading}*/}
                {/*                />*/}
                {/*            </Col>*/}
                {/*        </Row>*/}
                {/*        <Row>*/}
                {/*            <Col hidden={(!slot.reserved)}>*/}
                {/*                <button*/}
                {/*                    type="button"*/}
                {/*                    className="btn btn-success m-1" onClick={() => this.editSlot({*/}
                {/*                    id: slot.id,*/}
                {/*                    reserved: false,*/}
                {/*                    id_campaign_slot: null*/}
                {/*                })}>*/}
                {/*                    LIBERA SLOT*/}
                {/*                </button>*/}
                {/*            </Col>*/}
                {/*            <Col hidden={(slot.reserved && slot.campaign === null)}>*/}
                {/*                <button*/}
                {/*                    type="button"*/}
                {/*                    className="btn btn-dark m-1" onClick={() => this.editSlot({*/}
                {/*                    id: slot.id,*/}
                {/*                    reserved: true,*/}
                {/*                    id_campaign_slot: null*/}
                {/*                })}>*/}
                {/*                    RISERVA SLOT*/}
                {/*                </button>*/}
                {/*            </Col>*/}
                {/*        </Row>*/}
                {/*    </CardBody>*/}
                {/*</Card>}*/}
            </div>
            </React.Fragment>
        )
    }
}

export default GestSlot;
