import React, { Component } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert, Spinner } from 'reactstrap';

import { Link } from "react-router-dom";

import { AuthAPI } from "api";

import {Utils, Loading} from "custom";

const md5 = require('md5');

class LoginForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formEmail: '',
            formPassword: '',
            hidePsw: true,
            errors: false,
            loading: false
        };
    }

    onLoginPress() {
        let email = this.state.formEmail;
        let password = this.state.formPassword;

        let errors = {};

        if (password === "") {
            errors.password = "La password non può essere vuota";
        }

        if (email !== "" && !Utils.isValidEmail(email)) {
            errors.email = 'L\'email inserita non è valida';
        }
        if (email === "") {
            errors.email = 'Inserisci la tua email per continuare';
        }

        this.setState({errors: (Object.keys(errors).length > 0) ? errors : false});

        if (Object.keys(errors).length === 0) {
            this.setState({loading: true});
            AuthAPI.login({email: email, password: md5(password)}).then((user) => {
                global.userData = user;
                window.location.replace((user.role === "MONITOR") ? '/homepage' : '/map');
            }).catch((error) => {
                console.error(error);
                this.setState({loading: false, formEmail: email, formPassword: ''});
                let message = "";
                switch(error.status) {
                    case "SERVER_ERROR":
                        message = 'Si è verificato un errore di connessione';
                        break;
                    case "APP_ERROR":
                        message = 'Si è verificato un errore di connessione';
                        break;
                    case "USER_INACTIVE":
                        message = 'Utente non abilitato';
                        break;
                    default:
                        message = "Accesso Negato: email o password errata"
                }
                this.setState({
                    errors: {general: message}
                });
            })
        }
    }


    render() {
        document.title = "Autenticazione | Pubbli Seller";

        return <React.Fragment>
                    <div className="text-center mt-2">
                        <h5 className="text-primary">Benvenuto !</h5>
                        <p className="text-muted">Accedi con le tue credenziali.</p>
                    </div>
                    <div className="p-2 mt-4">
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault();
                                this.onLoginPress()
                            }}
                            action="#">

                            <div className="mb-3">
                                <Label htmlFor="email" className="form-label">Email</Label>
                                <Input
                                    name="email"
                                    className="form-control"
                                    placeholder="Inserisci email"
                                    type="email"
                                    tabIndex={1}
                                    value={this.state.formEmail}
                                    invalid={this.state.errors.email}
                                    onChange={(text) => this.setState({formEmail: text.target.value})}
                                />
                                {(this.state.errors.email) ? <FormFeedback type="invalid">{this.state.errors.email}</FormFeedback> : null}
                            </div>

                            <div className="mb-3">
                                <div className="float-end" >
                                    <Link onClick={() => this.props.changeModeLogin()} className="text-muted">Password dimenticata?</Link>
                                </div>
                                <Label className="form-label" htmlFor="password-input">Password</Label>
                                <div className="position-relative auth-pass-inputgroup mb-3">
                                    <Input
                                        name="password"
                                        type={(this.state.hidePsw) ? "password" : "text"}
                                        tabIndex={2}
                                        className="form-control pe-5"
                                        placeholder="Inserisci Password"
                                        value={this.state.formPassword}
                                        invalid={this.state.errors.password}
                                        onChange={(text) => this.setState({formPassword: text.target.value})}
                                    />
                                    {(this.state.errors.password) ? <FormFeedback type="invalid">{this.state.errors.password}</FormFeedback> : null}
                                    <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" onClick={() => this.setState({hidePsw: !(this.state.hidePsw)})}><i className="ri-eye-fill align-middle"></i></button>
                                </div>
                            </div>
                            {(typeof this.state.errors.general !== "undefined") ? <Alert color="danger">{this.state.errors.general}</Alert> : null}
                            <div className="mt-4">
                                <Button tabIndex={3} color="success" className="btn btn-success w-100" type="submit">Accedi {(this.state.loading ? <Spinner size='sm' type='grow' /> : null)}</Button>
                            </div>
                        </Form>
                    </div>

        </React.Fragment>
    }
}

export default LoginForm;
