import React from 'react';
import { Col, Container, Row } from 'reactstrap';

const Footer = () => {
    return (
        <React.Fragment>
            <footer className="footer">
                <Container fluid>
                    <Row>
                        <Col xs={6}>
                            Build {global.versionApp} - {new Date().getFullYear()} © PubbliArtist.
                        </Col>
                        <Col xs={6}>
                            <div className="text-sm-end d-none d-sm-block">
                                Software sviluppato da <a href={"https://www.fbwebstudio.it"} target={"_blank"}>FB Web Studio</a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </React.Fragment>
    );
};

export default Footer;
