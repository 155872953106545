import React, { Component } from 'react';
import {Alert, Badge, Button, Col, Container, FormFeedback, Input, Label, NavLink, Row} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import {withRouter, Link} from "react-router-dom";
import { Grid, _ } from 'gridjs-react';

import {Loading, ListReferent, Utils} from "custom";
import {ClientAPI} from "api";


class ClientTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            clients: [],
            loading: true,
            error: false
        };
    }

    componentDidMount() {
        this.getClients();
    }

    getClients() {
        ClientAPI.list().then((clients) => {
            this.setState({loading: false, error: false, clients: clients});
        }).catch(() => {
            this.setState({error: true});
        })
    }


    modalReferent(id_client_referent) {
        global.onSetModal({
            title: "LISTA REFERENTI",
            content: <div>
                <ListReferent id_client_referent={id_client_referent} onResolve={() => {this.getClients(); this.modalReferent(id_client_referent);}}/>
            </div>,
            isOpen: true,
            toggle: true,
            size: 'xl',
            type: 'info',
            buttons: [
                {
                    label: 'CHIUDI',
                    color: 'danger',
                    onClick: () => global.onSetModal({isOpen: false})
                },
            ]
        })
    }

    render() {

        const self = this;

        if (this.state.error) {
            return <React.Fragment>
                <Alert color="danger" >Si è verificato un errore durante il recupero della lista clienti.</Alert>
            </React.Fragment>
        }

        return (
            <React.Fragment>
                {(this.state.loading) ? <Loading /> : <Grid
                    data={this.state.clients}
                    columns={[
                        {
                            id: 'id',
                            name: '#',
                            formatter: (cell, row) => _(
                                <span className="text-nowrap">{cell}</span>
                            ),
                            width: "50px"
                        },
                        {
                            id: 'name',
                            formatter: (cell, row) => _(
                                <span className="text-nowrap"><a href="#" onClick={() => self.props.history.push("/client/" + row.cells[0].data)}><b>{cell}</b></a></span>
                            ),
                            name: "Nome"
                        },
                        {
                            id: 'business_name',
                            hidden: true
                        },
                        {
                            id: 'vat_id',
                            name: "Partita IVA",
                            formatter: (cell, row) => _(<div className="d-flex flex-column text-center text-nowrap">
                                {cell}
                                <small>{row.cells[2].data}</small>
                            </div>),
                        },
                        (Utils.getUserData().role !== "AGENT") ? {
                            id: 'Contracts',
                            name: 'Contratto',
                            sort: false,
                            formatter: (cell, row) => _(<div className="text-nowrap text-center">
                                {(cell.length > 0) ? <Badge color="success" >Attivo</Badge> : <Badge color="dark">Non Attivo</Badge>}
                            </div>)
                        } : {
                            id: 'agent',
                            name: 'Agente',
                            sort: false,
                            formatter: (cell, row) => _(<div className="text-nowrap text-center">
                                <Badge color="primary" >{cell}</Badge>
                            </div>)
                        },
                        {
                            id: 'phone',
                            name: "Telefono",
                            formatter: (cell, row) => _(<div className="text-nowrap">
                                <a href={"tel:" + cell} target="_blank">{cell}</a>
                            </div>)
                        },
                        {
                            id: 'email',
                            name: "Email",
                            formatter: (cell, row) => _(<div className="text-nowrap">
                                <a href={"mailto:" + cell} target="_blank">{cell}</a>
                            </div>)
                        },
                        {
                            id: 'Marker',
                            name: "Indirizzo",
                            formatter: (cell, row) => _(<div className="text-nowrap">
                                <a href={"https://www.google.it/maps/place/" + cell.full_address} target="_blank">{cell.full_address}</a>
                            </div>),
                            search: {
                                selector: (cell) => cell.full_address
                            },
                            sort: {
                                compare: (a, b) => {
                                    if (a.full_address > b.full_address) {
                                        return 1;
                                    } else if (b.full_address > a.full_address) {
                                        return -1;
                                    } else {
                                        return 0;
                                    }
                                }
                            }
                        },
                        {
                            id: 'Referents',
                            sort: false,
                            name: "Referenti",
                            formatter: (cell, row) => _(<div className="text-nowrap">
                                <span className="cursor-pointer" onClick={() => this.modalReferent(row.cells[0].data)}>{cell.slice(0, 2).map((referent, i) => <><small key={i}>{referent.name}</small><br /></>)} <small>...</small></span>
                            </div>),
                            search: {
                                selector: (cell) => cell.lastname
                            }
                        }
                    ]}
                    style={{table: {'white-space': 'nowrap'}}}
                    resizable={true}
                    search={{
                        selector: (cell, rowIndex, cellIndex) => (cellIndex === 8 ? (JSON.stringify(cell)) : ((cellIndex === 7) ? cell.full_address : cell))
                    }}
                    sort={true}
                    pagination={{ enabled: true, limit: 10}}
                    language={{
                        'search': {
                            'placeholder': '🔍 Cerca...'
                        },
                        'pagination': {
                            'previous': 'Indietro',
                            'next': 'Avanti',
                            'showing': 'Stai visualizzando da',
                            'results': 'Clienti',
                            'to': 'a',
                            'of': 'di',
                        },
                        'noRecordsFound': 'Nessun cliente trovato',
                        'error': 'Si è verificato un errore non previsto',
                    }}
                />}
            </React.Fragment>
        );
    }
}

export default withRouter(ClientTable);
