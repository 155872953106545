import React, { Component } from 'react';
import {Alert, Badge, Button, Col, Container, FormFeedback, Input, Label, NavLink, Row} from 'reactstrap';
import {withRouter, Link} from "react-router-dom";
import { Grid, _ } from 'gridjs-react';

import { GestCampaignCode, DeleteCampaignCode, Loading, Utils } from "custom";
import { CampaignCodeAPI } from "api";
const moment = require("moment");


class ListCampaignCode extends Component {

    constructor(props) {
        super(props);
        this.state = {
            campaignsCodes: [],
            loading: true,
            error: false
        };
    }

    componentDidMount() {
        this.getCampaignCode();
    }

    getCampaignCode() {
        CampaignCodeAPI.list().then((campaignsCodes) => {
            this.setState({loading: false, error: false, campaignsCodes: campaignsCodes});
        }).catch(() => {
            this.setState({error: true});
        })
    }


    render() {

        const self = this;

        if (this.state.error) {
            return <React.Fragment>
                <Alert color="danger" >Si è verificato un errore durante il recupero della lista dei codici campagna.</Alert>
            </React.Fragment>
        }

        return (
            <React.Fragment>
                {(this.state.loading) ? <Loading /> : <div>
                    <div style={{margin: 10}}>
                        <GestCampaignCode onResolve={this.getCampaignCode.bind(this)}/>
                    </div>
                    <div>
                        <Grid
                            data={this.state.campaignsCodes}
                            columns={[
                                {
                                    id: 'id',
                                    name: '#',
                                    formatter: (cell, row) => _(
                                        <span className="text-nowrap">{cell}</span>
                                    ),
                                    width: "50px"
                                },
                                {
                                    id: 'code',
                                    name: "Codice",
                                    formatter: (cell, row) => _(
                                        <div className="text-nowrap text-center"><b>{cell}</b></div>
                                    )
                                },
                                {
                                    id: 'duration',
                                    name: 'Durata',
                                    formatter: (cell, row) => _(<div className="text-nowrap text-center">
                                        {cell} mesi
                                    </div>)
                                },
                                {
                                    id: 'n_monitors',
                                    name: "Numero monitors",
                                    formatter: (cell, row) => _(<div className="text-nowrap text-center">
                                        {cell}
                                    </div>)
                                },
                                {
                                    id: 'ads_frequency',
                                    name: 'Passaggio spot',
                                    formatter: (cell, row) => _(<div className="text-nowrap">
                                        ogni {cell} minuti
                                    </div>)
                                },
                                {
                                    id: 'deposit',
                                    name: 'Anticipo',
                                    formatter: (cell, row) => _(<div className="text-nowrap text-center">
                                        {Utils.getValueMoneyLabel(cell)}
                                    </div>)
                                },
                                {
                                    id: 'price_month',
                                    name: 'Prezzo mensile',
                                    formatter: (cell, row) => _(<div className="text-nowrap text-center">
                                        {Utils.getValueMoneyLabel(cell)}
                                    </div>)
                                },
                                {
                                    id: 'updatedAt',
                                    name: "Ultima modifica",
                                    sort: false,
                                    formatter: (cell, row) => _(
                                        <div className="text-nowrap"><small>{moment(cell).format("DD/MM/YYYY")}</small></div>
                                    ),
                                },
                                {
                                    id: 'id',
                                    name: '',
                                    sort: false,
                                    formatter: (cell, row, i) => _(<ul
                                        className="list-inline hstack gap-2 mb-0">
                                        <GestCampaignCode
                                            preset={{
                                                id: row.cells[0].data,
                                                code: row.cells[1].data,
                                                duration: row.cells[2].data,
                                                n_monitors: row.cells[3].data,
                                                ads_frequency: row.cells[4].data,
                                                deposit: row.cells[5].data,
                                                price_month: row.cells[6].data
                                            }}
                                            onResolve={this.getCampaignCode.bind(this)}
                                        />
                                        <DeleteCampaignCode campaign={{id: row.cells[0].data}} onResolve={this.getCampaignCode.bind(this)} />
                                    </ul>)
                                }
                            ]}
                            style={{table: {'white-space': 'nowrap'}}}
                            resizable={true}
                            search={true}
                            sort={true}
                            pagination={{ enabled: true, limit: 10}}
                            language={{
                                'search': {
                                    'placeholder': '🔍 Cerca...'
                                },
                                'pagination': {
                                    'previous': 'Indietro',
                                    'next': 'Avanti',
                                    'showing': 'Stai visualizzando da',
                                    'results': 'Codici Campagne',
                                    'to': 'a',
                                    'of': 'di',
                                },
                                'noRecordsFound': 'Nessun codice campagna trovato',
                                'error': 'Si è verificato un errore non previsto',
                            }}
                        />
                    </div>
                </div>}
            </React.Fragment>
        );
    }
}

export default withRouter(ListCampaignCode);
