import React, {Fragment} from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Alert,
    Row,
    Col,
    Label,
    FormFeedback,
    Input,
    Badge
} from 'reactstrap';

import {Utils, Loading, GestReferent, DeleteReferent} from "custom";
import {ReferentAPI} from "api";

import {Grid, _} from 'gridjs-react';

class ListReferent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            referents: [],
            error: false
        };
    }

    componentDidMount() {
        this.getReferents();
    }

    getReferents() {
        ReferentAPI.list(this.props.id_client_referent).then((referents) => {
            this.setState({error: false, referents: referents});
        }).catch(() => {
            this.setState({error: true});
        })
    }

    render() {
        if (this.state.error) {
            return <React.Fragment>
                <Alert color="danger">Si è verificato un errore durante il recupero della lista referenti.</Alert>
            </React.Fragment>
        }


        return <React.Fragment>
            <div style={{margin: 10}}>
                <GestReferent id_client_referent={this.props.id_client_referent} onResolve={() => {
                    this.getReferents();
                    this.props.onResolve()
                }}/>
            </div>
            <Grid
                data={this.state.referents}
                columns={[
                    {
                        id: 'id',
                        name: '#',
                        hidden: true
                    },
                    {
                        id: 'name',
                        name: "Nome"
                    },
                    {
                        id: 'role',
                        name: "Ruolo"
                    },
                    {
                        id: 'email',
                        name: "Email",
                        formatter: (cell, row) => _(
                            <div className="text-nowrap"><a href={"mailto:" + cell}>{cell}</a></div>
                        ),
                    },
                    {
                        id: 'phone',
                        name: "Telefono",
                        formatter: (cell, row) => _(
                            <div className="text-nowrap"><a href={"tel:" + cell}>{cell}</a></div>
                        ),
                    },
                    {
                        id: 'cell',
                        name: "Cellulare",
                        formatter: (cell, row) => _(
                            <div className="text-nowrap"><a href={"tel:" + cell}>{cell}</a></div>
                        ),
                    },
                    {
                        id: 'id',
                        name: '',
                        sort: false,
                        formatter: (cell, row, i) => _(<ul
                            className="list-inline hstack gap-2 mb-0">
                            <GestReferent id={row.cells[0].data} preset={{
                                name: row.cells[1].data,
                                role: row.cells[2].data,
                                email: row.cells[3].data,
                                phone: row.cells[4].data,
                                cell: row.cells[5].data
                            }} onResolve={() => {
                                this.getReferents();
                                this.props.onResolve()
                            }}/>
                            <DeleteReferent referent={{id: row.cells[0].data}} onResolve={() => {
                                this.getReferents();
                                this.props.onResolve()
                            }}/>
                        </ul>)
                    }
                ]}
                resizable={true}
                search={true}
                sort={true}
                pagination={{enabled: true, limit: 10}}
                language={{
                    'search': {
                        'placeholder': '🔍 Cerca...'
                    },
                    'pagination': {
                        'previous': 'Indietro',
                        'next': 'Avanti',
                        'showing': 'Stai visualizzando da',
                        'results': 'Referenti',
                        'to': 'a',
                        'of': 'di',
                    },
                    'noRecordsFound': 'Nessun referente trovato',
                    'error': 'Si è verificato un errore non previsto',
                }}
            />
        </React.Fragment>
    }
}

export default ListReferent;
