import React from "react";
import {Link, Redirect} from "react-router-dom";

import Unauthorized from "../pages/Errors/Unauthorized";
import Offlinepage from "../pages/Errors/Offlinepage";
import Error500 from "../pages/Errors/Error500";

import RedirectPage from "../pages/Authentication/RedirectPage";

import Login from "../pages/Authentication/Login";
import Dashboard from "../pages/Dashboard/Dashboard";

import { Utils } from "custom";

import AdministratorList from "../pages/Administrator/AdministratorList";
import AgentList from "../pages/Agent/AgentList";

import MonitorList from "../pages/Monitor/MonitorList";
import MonitorGest from "../pages/Monitor/MonitorGest";

import ClientList from "../pages/Client/ClientList";
import ClientGest from "../pages/Client/ClientGest";
import ClientDetail from "../pages/Client/ClientDetail";

import CampaignList from "../pages/Campaign/CampaignList";
import CampaignGest from "../pages/Campaign/CampaignGest";

import CampaignCodeList from "../pages/CampaignCodes/CampaignCodeList";

import ContractList from "../pages/Contract/ContractList";
import ContractGest from "../pages/Contract/ContractGest";

import ContractBuildPreselect from "../pages/ContractBuild/ContractBuildPreselect";
import ContractBuildCampaign from "../pages/ContractBuild/ContractBuildCampaign";
import ContractBuildMonitor from "../pages/ContractBuild/ContractBuildFinal";

import DraftPreview from "../pages/ContractBuild/DraftPreview";

import AgentGest from "../pages/Agent/AgentGest";

import Map from "../pages/Map/Map";

import Gallery from "../pages/Gallery/Gallery";

import ReportList from "../pages/Report/ReportList";

import MonitorHomepage from "../pages/MonitorView/Homepage";
import NewVoucher from "../pages/MonitorView/NewVoucher";
import ListVoucher from "../pages/MonitorView/ListVoucher";

import AppointmentList from "../pages/Appointment/AppointmentList";


const authProtectedRoutes = [
  // { path: "/dashboard", auth: ["ADMIN", "AGENT"], component: Dashboard },
  { path: "/admin", auth: ["ADMIN"], component: AdministratorList },

  { path: "/agent", auth: ["ADMIN"], component: AgentList },

  { path: "/agent/:id", auth: ["ADMIN"], component: AgentGest },

  { path: "/monitor", auth: ["ADMIN", "AGENT"], component: MonitorList },
  { path: "/monitor/:id", auth: ["ADMIN", "AGENT"], component: MonitorGest },

  { path: "/client", auth: ["ADMIN", "AGENT"], component: ClientList },
  { path: "/client/add", auth: ["ADMIN", "AGENT"], component: ClientGest },
  { path: "/client/edit/:id", auth: ["ADMIN", "AGENT"], component: ClientGest },
  { path: "/client/:id", auth: ["ADMIN", "AGENT"], component: ClientDetail },

  { path: "/contract", auth: ["ADMIN", "AGENT"], component: ContractList },
  { path: "/contract/add", auth: ["ADMIN"], component: ContractGest },
  { path: "/contract/build", auth: ["AGENT"], component: ContractBuildPreselect },
  { path: "/contract/build/campaign", auth: ["AGENT"], component: ContractBuildCampaign },
  { path: "/contract/build/campaign/monitor", auth: ["AGENT"], component: ContractBuildMonitor },

  { path: "/draft/:id_draft", auth: ["AGENT"], component: DraftPreview },

  { path: "/contract/:id", auth: ["ADMIN"], component: ContractGest },

  { path: "/campaign", auth: ["ADMIN"], component: CampaignList },
  { path: "/campaign/add/:id_contract", auth: ["ADMIN"], component: CampaignGest },
  { path: "/campaign/:id", auth: ["ADMIN"], component: CampaignGest },

  { path: "/campaigncode", auth: ["ADMIN"], component: CampaignCodeList },

  { path: "/report", auth: ["ADMIN", "AGENT"], component: ReportList },

  { path: "/map", auth: ["ADMIN", "AGENT"], component: Map },

  { path: "/appointment", auth: ["ADMIN", "AGENT"], component: AppointmentList },

  {
    path: "/",
    auth: ["ADMIN", "AGENT", "MONITOR"],
    exact: true,
    component: () => <Redirect to={(Utils.getUserData().role !== "MONITOR") ? "/map" : "/homepage"} />,
  }
];

const publicRoutes = [
  { path: "/homepage", auth: ["MONITOR"], component: MonitorHomepage },
  { path: "/new-voucher", component: NewVoucher },
  { path: "/list-voucher", auth: ["MONITOR"], component: ListVoucher },
  { path: "/redirect", component: RedirectPage },
  { path: "/login", component: Login },
  { path: "/gallery", component: Gallery },
  { path: "/offline", component: Offlinepage },
  { path: "/unauthorized", component: Unauthorized },
  { path: "/server/error", component: Error500 }

];

export { authProtectedRoutes, publicRoutes };
