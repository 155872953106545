import React, {Fragment} from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert, Row, Col, Label, FormFeedback, Input} from 'reactstrap';

import {Utils} from "custom";
import {AdminAPI, ReferentAPI} from "api";

class GestReferent extends React.Component {
    constructor(props) {
        super(props);
        let preset = this.props.preset;

        this.state = {
            id_client_referent: (typeof this.props.id_client_referent !== "undefined") ? this.props.id_client_referent : null,
            id: (typeof this.props.id !== "undefined") ? this.props.id : null,
            form: {
                email: (preset) ? preset.email : "",
                name: (preset) ? preset.name : "",
                phone: (preset) ? preset.phone : "",
                cell: (preset) ? preset.cell : "",
                role: (preset) ? preset.role : "",
            },
            loading: false,
            errors: false
        };
    }


    resetForm() {
        let preset = this.props.preset;
        this.setState({form: {
            email: (preset) ? preset.email : "",
            name: (preset) ? preset.name : "",
            phone: (preset) ? preset.phone : "",
            cell: (preset) ? preset.cell : "",
            role: (preset) ? preset.role : "",
        }});
    }

    changeForm(key, text) {
        const form = this.state.form;

        if (key === 'phone' || key === 'cell') {
            if (isNaN(text) && text[0] !== "+")
                return;

            if (text.length >= 3 && text.indexOf("+") === -1)
                text = "+39" + text;
        }

        form[key] = text;
        this.setState({form: form}, () => this.showModal());
    }

    showModal() {
        let form = this.state.form;
        let errors = this.state.errors;

        global.onSetModal({
            title: (this.state.id !== null) ? "MODIFICA REFERENTE" : "CREA REFERENTE",
            content: <div>
                <Row>
                    <Col md="6">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Nome*
                            </Label>
                            <Input type="text" defaultValue={form.name}
                                   invalid={Boolean(errors.name)}
                                   onChange={(e) => this.changeForm('name', e.target.value)}/>
                            <FormFeedback>{errors.name}</FormFeedback>
                        </div>
                    </Col>
                    <Col md="6">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Ruolo
                            </Label>
                            <Input type="text" defaultValue={form.role}
                                   invalid={Boolean(errors.role)}
                                   onChange={(e) => this.changeForm('role', e.target.value)}/>
                            <FormFeedback>{errors.role}</FormFeedback>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md="4">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Email
                            </Label>
                            <Input type="text" defaultValue={form.email}
                                   invalid={Boolean(errors.email)}
                                   onChange={(e) => this.changeForm('email', e.target.value)}/>
                            <FormFeedback>{errors.email}</FormFeedback>
                        </div>
                    </Col>
                    <Col md="4">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Telefono
                            </Label>
                            <Input type="text" defaultValue={form.phone}
                                   invalid={Boolean(errors.phone)}
                                   onChange={(e) => this.changeForm('phone', e.target.value)}/>
                            <FormFeedback>{errors.phone}</FormFeedback>
                        </div>
                    </Col>
                    <Col md="4">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Cellulare
                            </Label>
                            <Input type="text" defaultValue={form.cell}
                                   invalid={Boolean(errors.cell)}
                                   onChange={(e) => this.changeForm('cell', e.target.value)}/>
                            <FormFeedback>{errors.cell}</FormFeedback>
                        </div>
                    </Col>
                </Row>
            </div>,
            isOpen: true,
            toggle: false,
            size: 'xl',
            type: 'info',
            buttons: [
                {label: 'SALVA', color: 'success', onClick: () => this.saveReferent()},
                {
                    label: 'ANNULLA',
                    color: 'danger',
                    onClick: () => this.setState({errors: false}, () => {this.props.onResolve(); this.resetForm()})
                },
            ]
        })
    }


    saveReferent() {
        const self = this;
        let form = this.state.form;
        let error = {};

        if (form.name === '')
            error.name = "Il campo nome è obbligatorio";

        if (form.email !== '' && !Utils.isValidEmail(form.email))
            error.email = "La mail inserita non è corretta";

        this.setState({errors: error}, () => this.showModal());

        if (Object.keys(error).length === 0) {

            if (this.props.giveVariable) {
                self.props.onResolve(form);
                this.resetForm();
                return
            }

            global.onSetModal({loading: true});

            let add = (this.state.id === null);
            let api = (add) ? ReferentAPI.add : ReferentAPI.edit;

            form["id"] = this.state.id;
            form["id_client_referent"] = this.state.id_client_referent;

            api(form).then(() => {
                global.onSetModal({loading: false});
                global.SweetAlert.fire({
                    title: (add) ? 'Referente creato' : 'Referente modificato',
                    text: (add) ? 'Il referente è stato creato correttamente.' : 'Il referente è stato modificato correttamente.',
                    icon: 'success',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    },
                    confirmButtonText: 'Chiudi',
                    buttonsStyling: false
                }).then(function (result) {
                    if (result.value) {
                        self.resetForm()
                        self.props.onResolve();
                    }
                })
            }).catch(() => {
                global.onSetModal({loading: false});
                global.SweetAlert.fire({
                    title: 'Errore',
                    text: (add) ? 'Si è verificato un errore durante la creazione del referente' : 'Si è verificato un errore durante la modifica del referente',
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    }
                });
            })
        }
    }


    render() {
        return (
            <React.Fragment>
                {(this.state.id !== null) ? <li className="list-inline-item cursor-pointer"
                    onClick={() => this.showModal()}>
                    <i className="ri-pencil-fill fs-20 text-info"></i>
                </li> : <button
                    type="button"
                    className="btn btn-success add-btn"
                    id="create-btn"
                    onClick={() => this.showModal()}>
                    <i className="ri-add-line align-bottom me-1"></i> CREA REFERENTE
                </button>}
            </React.Fragment>
        )
    }
}

export default GestReferent;
